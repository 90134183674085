import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useReducer,
} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import Async from 'react-select/async';
import Select from 'react-select';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { SourcesTable } from '../../components/SourcesTable/SourcesTable';
import { CheckboxField } from '../../components/Checkbox/CheckboxField';
import styles from './CreateGroupPage.module.scss';
import Switch from '../../components/Switch';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Search } from '../../components/Search/Search';
import { Filter } from '../SourcesPage/Filter/Filter';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import tableStyles from '../../components/SourcesTable/SourcesTable.module.scss';

const TEMP_GROUP_ID = 'TEMP_GROUP_ID';

const sortingFieldNames = {
  ID: 'id',
  AUDIENCE: 'audience',
  ACTOR: 'name',
  PLATFORM: 'source_type',
  STATUS: 'status',
  URL: 'url',
  COUNTRY: 'country',
  RELEVANCE: 'relevance',
};

const sortingFieldNamesBack = {
  id: 'ID',
  audience: 'AUDIENCE',
  name: 'ACTOR',
  source_type: 'PLATFORM',
  status: 'STATUS',
  url: 'URL',
  country: 'COUNTRY',
  relevance: 'RELEVANCE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'APPLY_FILTER': {
      return Object.assign({}, state, {
        platforms: action.payload.platforms,
        countries: action.payload.countries,
        audience_lte: action.payload.audience_lte,
        audience_gte: action.payload.audience_gte,
        status: action.payload.status,
        affiliationCountries: action.payload.affiliationCountries,
        IoCs: action.payload.IoCs,
        showOnlyBots: action.payload.showOnlyBots,
        sourceTags: action.payload.sourceTags,
        affiliationCountriesExclude: action.payload.affiliationCountriesExclude,
        countriesExclude: action.payload.countriesExclude,
        audienceExclude: action.payload.audienceExclude,
        page: action.payload.page,
      });
    }

    case 'UPDATE_QUERY': {
      return Object.assign({}, state, {
        searchQuery: action.payload,
      });
    }

    case 'SORT_BY': {
      return Object.assign({}, state, {
        sorting: { ...state.sorting, fieldName: action.fieldName },
      });
    }

    case 'IS_ASCENDING': {
      return Object.assign({}, state, {
        sorting: { ...state.sorting, isAscending: action.isAscending },
      });
    }

    case 'CHANGE_PAGE':
      return Object.assign({}, state, {
        page: action.page,
      });
    default:
      return state;
  }
};

export const CreateGroupPage = () => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [privacySettings, setPrivacySettings] = useState('PRIVATE');
  const [description, setDescription] = useState('');
  const inputNameRef = useRef(null);
  const formRef = useRef(null);

  const [workspaces, setWorkspaces] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [sources, setSources] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');
  const [dropdownMenu, setDropdownMenu] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    sorting: {
      isAscending: false,
      fieldName: sortingFieldNamesBack['relevance'],
    },
    searchQuery: searchParams.get('q') || '',
    platforms: [],
    countries: [],
    audience_lte: null,
    audience_gte: null,
    status: ['ACTIVE'],
    affiliationCountries: [],
    IoCs: [],
    showOnlyBots: false,
    sourceTags: [],
    affiliationCountriesExclude: false,
    countriesExclude: false,
    audienceExclude: false,
    page: searchParams.get('page') || 1,
  });

  const sortingQuery = state.sorting.fieldName
    ? `${state.sorting.isAscending ? '' : '-'}${
        sortingFieldNames[state.sorting.fieldName]
      }`
    : null;

  const privacySettingsOptions = [
    {value: 'PRIVATE', label: t('private_female', {context: 'female'})},
    {value: 'PUBLIC', label: t('public_female', {context: 'female'})},
    {value: 'SHARABLE', label: t('sharable_female', {context: 'female'})},
  ];

  const fetchAllWorkspaces = useCallback(
    () => {
      if (!currentUser?.is_super_admin) {
        return;
      }

      const urlParams = new URLSearchParams();

      urlParams.set('size', '20');
      urlParams.set('sorting', '-id');

      API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
        (data) => {
          let workspaces = data.objects
            .map((workspace) => {
              return { value: workspace.id, label: workspace.name };
            });
          setWorkspaces(workspaces);
        },
      );
    },
    [],
  );

  useEffect(fetchAllWorkspaces, [fetchAllWorkspaces]);

  const fetchWorkspaces = useMemo(
    () =>
      debounce((inputValue, callback) => {
        if (!currentUser?.is_super_admin) {
          return;
        }

        const urlParams = new URLSearchParams();

        if (inputValue) {
          urlParams.set('q', inputValue);
        }

        API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
          (data) => {
            callback(
              data.objects
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((workspace) => {
                  return { value: workspace.id, label: workspace.name };
                }),
            );
          },
        );
      }, 1000),
    [],
  );

  const workspacesPromiseOptions = (inputValue) => {
    if (!inputValue) {
      return [];
    }
    return new Promise((resolve) => {
      fetchWorkspaces(inputValue, resolve);
    });
  };

  const fetchSources = useCallback(
    (
      searchQuery,
      platforms,
      countries,
      audience_lte,
      audience_gte,
      status,
      affiliationCountries,
      IoCs,
      showOnlyBots,
      sourceTags,
      affiliationCountriesExclude,
      countriesExclude,
      audienceExclude,
      sorting,
      page = 1,
    ) => {
      setSources(null);
      const urlParams = new URLSearchParams();
      urlParams.set('size', '20');
      urlParams.set('page', page);

      if (searchQuery) {
        urlParams.set('q', searchQuery);
      }

      if (platforms?.length) {
        platforms.forEach((platform) => {
          urlParams.append('source_types', platform);
        });
      }

      if (countries?.length) {
        countries.forEach((country) => {
          urlParams.append('origin_country_ids', country);
        });
      }

      if (audience_lte !== null && audience_lte?.length > 0) {
        urlParams.set('audience_lte', audience_lte);
      }

      if (audience_gte !== null && audience_gte?.length > 0) {
        urlParams.set('audience_gte', audience_gte);
      }

      if (status?.length) {
        status.forEach((status_) => {
          urlParams.append('statuses', status_);
        });
      }

      if (affiliationCountries?.length) {
        affiliationCountries.forEach((country) => {
          urlParams.append('country_ids', country);
        });
      }

      if (IoCs?.length) {
        IoCs.forEach((IoC) => {
          urlParams.append('entity_types', IoC);
        });
      }

      if (showOnlyBots) {
        urlParams.set('is_bot', showOnlyBots);
      }

      if (sourceTags?.length) {
        sourceTags.forEach((tag) => {
          urlParams.append('tags', tag);
        });
      }
      let excludedFilters = [];
      if (countriesExclude === true) {
        excludedFilters.push('origin_country_ids');
      }

      if (affiliationCountriesExclude === true) {
        excludedFilters.push('country_ids');
      }

      if (audienceExclude === true) {
        excludedFilters.push('audience_lte', 'audience_gte');
      }

      if (excludedFilters.length > 0) {
        excludedFilters.forEach((filter) => {
          urlParams.append('exclusive_filters', filter);
        });
      }

      if (sorting) {
        if (Array.isArray(sorting?.fieldName)) {
          sorting.fieldName.forEach((element) => {
            urlParams.append(
              'sorting',
              `${sorting?.isAscending ? '' : '-'}${element}`,
            );
          });
        } else {
          urlParams.set('sorting', sortingQuery);
        }
      }

      API.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(
        setSources,
      );
    },
    [page, searchQuery, state.sorting],
  );

  useEffect(() => {
    fetchSources(
      state.searchQuery,
      state.platforms,
      state.countries,
      state.audience_lte,
      state.audience_gte,
      state.status,
      state.affiliationCountries,
      state.IoCs,
      state.showOnlyBots,
      state.sourceTags,
      state.affiliationCountriesExclude,
      state.countriesExclude,
      state.audienceExclude,
      state.sorting,
      page,
    );
  }, [state, page, state.sorting, fetchSources]);

  const [selectedSourceIds, setSelectedSourceIds] = useState(new Set());

  const impureSources = useMemo(() => {
    if (!sources) {
      return null;
    }

    return {
      ...sources,
      objects: sources.objects.map((source) => ({
        ...source,
        groups: selectedSourceIds.has(source.id)
          ? [...source.groups, TEMP_GROUP_ID]
          : source.groups,
      })),
    };
  }, [sources, selectedSourceIds]);

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'ID',
    },
    {
      name: t('Audience'),
      value: 'AUDIENCE',
    },
    {
      name: t('Platform'),
      value: 'PLATFORM',
    },
    {
      name: t('Name'),
      value: 'ACTOR',
    },
    {
      name: t('Link'),
      value: 'URL',
    },
    {
      name: t('Country'),
      value: 'COUNTRY',
    },
    {
      name: t('Status'),
      value: 'STATUS',
    },
    {
      name: t('Relevance'),
      value: 'RELEVANCE',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>
              <Link to="/groups">{t('Groups')}</Link>
            </span>
            <span>{t('New')}</span>
          </div>
        </div>
      </div>

      <div className="form-wrapper">
        <form
          ref={formRef}
          onSubmit={async (e) => {
            e.preventDefault();

            const { id } = await API.fetch('POST', `/API/v1/groups`, null, {
              name,
              description: description,
              visibility: privacySettings,
              workspace_ids: selectedWorkspaces?.map((workspace) => workspace.value),
            });

            await API.fetch('POST', `/API/v1/groups/${id}/sources`, null, {
              source_ids: [...selectedSourceIds],
            }).then(() => {
              navigate('/groups');
            });
          }}
        >
          <div className="form-element">
            <label className={styles.label} htmlFor="name">
              {t('Name')}
            </label>
            <input
              ref={inputNameRef}
              type="text"
              id="name"
              name="name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              autoFocus={true}
            />
          </div>

          <div className="form-element">
            <label className={styles.label} htmlFor="description">
              {t('Description')}
            </label>
            <textarea
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {currentUser?.is_super_admin && (
            <div className="form-element">
              <label className={styles.label} htmlFor="privacy-settings">
                {t('Privacy settings')}
              </label>
              <Select
                id="privacy-settings"
                name="privacy-settings"
                options={privacySettingsOptions}
                value={privacySettingsOptions.find(
                  (option) => option.value === privacySettings,
                )}
                onChange={(option) => setPrivacySettings(option.value)}
              />
            </div>
          )}

          {currentUser?.is_super_admin && privacySettings === 'SHARABLE' && (
            <div className="form-element">
              <label className={styles.label} htmlFor="workspaces">
                {t('Workspaces')}
              </label>
              <Async
                cacheOptions
                defaultOptions={workspaces}
                isMulti={true}
                isClearable={true}
                loadOptions={workspacesPromiseOptions}
                value={selectedWorkspaces}
                placeholder={t('Please enter at least 1 symbol to search')}
                noOptionsMessage={() => t('Please enter at least 1 symbol')}
                loadingMessage={() => t('Loading...')}
                id='workspaces'
                onChange={(options) => {
                  setSelectedWorkspaces(options);
                }}
                required={currentUser?.is_super_admin && privacySettings === 'SHARABLE'}
              />
            </div>
          )}

          <label className={styles.label} htmlFor="sources">
            {t('Actors')}
          </label>
          <div className={styles.searchWrapper}>
            <Search
              className={styles.searchInput}
              onChange={(value) => {
                dispatch({ type: 'UPDATE_QUERY', payload: value });
                dispatch({ type: 'CHANGE_PAGE', page: 1 });
                setSearchParams({ q: value });
              }}
            />

            <DropdownMenu
              isOpen={dropdownMenu}
              header={t('Sort by')}
              onClick={() => setDropdownMenu(!dropdownMenu)}
              buttonName={t('Sort')}
              icon={<SortIcon />}
            >
              <Radiobutton
                itemsList={sortingMenu}
                current={state.sorting.fieldName}
                onChange={(value) =>
                  dispatch({ type: 'SORT_BY', fieldName: value })
                }
              />

              <SortingOrder
                itemsList={sortingOrder}
                onClick={(value) =>
                  dispatch({ type: 'IS_ASCENDING', isAscending: value })
                }
                current={state.sorting.isAscending}
              />
            </DropdownMenu>

            <Filter
              state={state}
              onChange={(
                platforms,
                countries,
                audience,
                status,
                affiliationCountries,
                IoCs,
                showOnlyBots,
                sourceTags,
                affiliationCountriesExclude,
                countriesExclude,
                audienceExclude,
              ) => {
                dispatch({
                  type: 'APPLY_FILTER',
                  payload: {
                    platforms: platforms,
                    countries: countries,
                    audience_lte: audience[1],
                    audience_gte: audience[0],
                    affiliationCountries: affiliationCountries,
                    IoCs: IoCs,
                    showOnlyBots: showOnlyBots,
                    sourceTags: sourceTags,
                    affiliationCountriesExclude: affiliationCountriesExclude,
                    countriesExclude: countriesExclude,
                    audienceExclude: audienceExclude,
                    status: status,
                    page: 1,
                  },
                });
                if (state.searchQuery) {
                  setSearchParams({ q: state.searchQuery, page: 1 });
                } else {
                  setSearchParams({ page: 1 });
                }
              }}
            />
          </div>
          {impureSources ? (
            <SourcesTable
              rowClassName={styles.tableRow}
              sources={impureSources}
              searchQuery={searchQuery}
              checkIsSelected={(source) => selectedSourceIds.has(source.id)}
              renderPrefixRow={(source) => (
                <td className={tableStyles.tableCheckbox}>
                  <CheckboxField
                    className={styles.tableRow}
                    checked={selectedSourceIds.has(source.id)}
                    onChange={() => {
                      setSelectedSourceIds((prev) => {
                        if (prev.has(source.id)) {
                          prev.delete(source.id);
                        } else {
                          prev.add(source.id);
                        }

                        return new Set(prev);
                      });
                    }}
                  />
                </td>
              )}
            />
          ) : (
            <Loader />
          )}

          <div className="form-element">
            <button
              type="submit"
              className="new-button"
              onClick={() => {
                const formValid = formRef.current.reportValidity();

                if (!formValid) {
                  requestAnimationFrame(() => {
                    window.scroll({
                      top: 0,
                      behavior: 'smooth',
                    });
                  });
                }
              }}
            >
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
