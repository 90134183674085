import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Dashboard: 'Dashboard',
      Reports: 'Reports',
      Narratives: 'Narratives',
      Messages: 'Messages',
      Sources: 'Sources',
      Groups: 'Groups',
      'Search here': 'Search here',
      'Days of war': 'Days of war',
      Moderator: 'Moderator',
      Administrator: 'Administrator',
      Login: 'Login',
      Logout: 'Logout',
      Email: 'Email',
      Password: 'Password',
      Date: 'Date',
      Message: 'Message',
      Group: 'Group',
      Source: 'Source',
      URL: 'URL',
      'Source Type': 'Source Type',
      'Source type': 'Source type',
      'Source types': 'Source types',
      'Number of subscribers': 'Number of subscribers',
      'Create narrative': 'Create narrative',
      Narrative: 'Narrative',
      'Create group': 'Create group',
      'Number of Messages': 'Number of Messages',
      'Messages (new)': 'Messages (new)',
      'Are you sure you want to delete a narrative?':
        'Are you sure you want to delete a narrative?',
      Delete: 'Delete',
      Cancel: 'Cancel',
      New: 'New',
      Name: 'Name',
      Active: 'Active',
      Inactive: 'Inactive',
      Description: 'Description',
      Keywords: 'Keywords',
      Examples: 'Examples',
      'Add another example': 'Add another example',
      Select: 'Select',
      Add: 'Add',
      Edit: 'Edit',
      Save: 'Save',
      'Edit narrative': 'Edit narrative',
      'Copy': 'Copy',
      'Are you sure you want to delete these messages from a narrative?':
        'Are you sure you want to delete these messages from a narrative?',
      'Are you sure you want to delete this message from a narrative?':
        'Are you sure you want to delete this message from a narrative?',
      'Create report': 'Create report',
      'Are you sure you want to delete a report?':
        'Are you sure you want to delete a report?',
      'Are you sure you want to delete this group?':
        'Are you sure you want to delete this group?',
      'Start date': 'Start date',
      'End date': 'End date',
      Create: 'Create',
      'Edit report': 'Edit report',
      Day: 'Day',
      Week: 'Week',
      Month: 'Month',
      Overview: 'Overview',
      Publications: 'Publications',
      Views: 'Views',
      Reactions: 'Reactions',
      Percentage: 'Percentage',
      'Number of reactions': 'Number of reactions',
      'Number of publications': 'Number of publications',
      'Select All': 'Select All',
      'Deselect All': 'Deselect All',
      'Mark as read': 'Mark as read',
      'Mark as unread': 'Mark as unread',
      Approve: 'Approve',
      'Refresh whole case history': 'Refresh whole case history',
      'Enhance search results based on user feedback':
        'Enhance search results based on user feedback',
      'Fix the keywords query': 'Fix the keywords query',
      'All brackets in the query must be closed':
        'All brackets in the query must be closed',
      'Should not contain backslashes': 'Should not include backslashes "\\"',
      'e.g. Zelensky, president AND terrorism AND NOT Biden, Putin':
        'e.g. Zelensky, president AND terrorism AND NOT Biden, Putin',
      Author: 'Author',
      'Show selected': 'Show selected',
      'Export report': 'Export report',
      'Export to CSV': 'Export to CSV',
      'We automatically build all possible word forms for each search term  (e.g. black cat → black cats, чорний кіт → чорного кота, чорних котів, …).': 'We automatically build all possible word forms for each search term  (e.g. black cat → black cats, чорний кіт → чорного кота, чорних котів, …).',
      'To limit results to an exact search term, use the ': 'To limit results to an exact search term, use the ',
      ' exact match operator': ' exact match operator',
      'e.g “cat”': 'e.g “cat”',
      'Search supports': 'Search supports',
      'logical operators': 'logical operators',
      Use: 'Use',
      parenthesis: ' parenthesis',
      'to group keywords. E.g.: (black cat OR white dog) AND NOT grey elephant': 'to group keywords. E.g.: (black cat OR white dog) AND NOT grey elephant',
      'to match any number of characters at the end of a word, e.g. philosoph* → philosophy, philosopher': 'to match any number of characters at the end of a word, e.g. philosoph* → philosophy, philosopher',
      ' [{number}]': ' [{number}]',
      'notation to match a fixed number of characters at the end of a word, e.g. ap[3] → apple, apply': 'notation to match a fixed number of characters at the end of a word, e.g. ap[3] → apple, apply',
      'Are you sure you want to approve this message?': 'Are you sure you want to approve this message?',
      'Are you sure you want to approve these messages?': 'Are you sure you want to approve these messages?',
      'Save narrative': 'Save narrative',
      'Select date': 'Select date',
      'Filters': 'Filters',
      'Title': 'Title',
      'Search': 'Search',
      'We are not able to show results, add your request': 'We are not able to show results, add your request',
      'No results were found for this query, refine your search': 'No results were found for this query, refine your search',
      'Confirm': 'Confirm',
      'Dismiss': 'Dismiss',
      'Filter by': 'Filter by',
      'Show all': 'Show all',
      'Minimize all': 'Minimize all',
      'Choose platform': 'Choose platform',
      'Choose source': 'Choose source',
      'Apply': 'Apply',
      'Reset all': 'Reset all',
      'Operators': 'Operators',
      'Select all': 'Select All',
      'Deselect all': 'Deselect All',
      'Save as narrative': 'Save as narrative',
      'Engagement': 'Engagement',
      "View mode": 'View mode',
      'Actors analysis': 'Actors analysis',
      'Distribution': 'Distribution',
      'Total': 'Total',
      'Verified': 'Verified',
      'Verified by platform (Twitter blue, Telegram verified)': 'Verified by platform (Twitter blue, Telegram verified)',
      'Influential': 'Influential',
      '100k+ followers': '100k+ followers',
      'State-affiliated': 'State-affiliated',
      'Official gov accounts or accounts known to be linked to governments': 'Official gov accounts or accounts known to be linked to governments',
      'Immature accounts': 'Immature accounts',
      'Recently created accounts or accounts with few followers': 'Recently created accounts or accounts with few followers',
      '< 30 days since sign up': '< 30 days since sign up',
      'Manipulation': 'Manipulation',
      'There is no data to display in this narrative': 'There is no data to display in this narrative',
      'There is no data to display': 'There is no data to display',
      'Manipulation index': 'Manipulation index',
      'From': 'From',
      'To': 'To',
      'State agency': 'State agency',
      'Government official': 'Government official',
      'State owned media': 'State owned media',
      'Reportedly state affiliated': 'Reportedly state affiliated',
      'Country': 'Country',
      'Affiliation': 'Affiliation',
      'Country of affiliation': 'Country of affiliation',
      'Type of affiliation': 'Type of affiliation',
      'Proof of affiliation': 'Proof of affiliation',
      'Followers count': 'Followers count',
      'Content': 'Content',
      'All': 'All',
      'Compromised accounts': 'Compromised accounts',
      'Bots': 'Bots',
      'Hackers / Hacktivists': 'Hackers / Hacktivists',
      'Suspended by platform': 'Suspended by platform',
      'Influence operations': 'Influence operations',
      'Inauthentic behavior': 'Inauthentic behavior',
      'Account has been involved in influence operations': 'Account has been involved in influence operations',
      'Account has been hacked or stolen': 'Account has been hacked or stolen',
      'Engaged or claimed to engage in cyber attacks or similar activity': 'Engaged or claimed to engage in cyber attacks or similar activity',
      'Account has been suspended by a platform': 'Account has been suspended by a platform',
      'Account was engaged in an inauthentic behavior': 'Account was engaged in an inauthentic behavior',
      'Accounts that was suspected to be bots': 'Accounts that was suspected to be bots',
      'Fake news': 'Fake news',
      'Document leak': 'Document leak',
      'Protests coordination': 'Protests coordination',
      'Coordinated activity': 'Coordinated activity',
      'Positive narrative': 'Positive narrative',
      'Government communication': 'Government communication',
      'Fake/Disinformation': 'Fake/Disinformation',
      'Manipulation/Misinformation': 'Manipulation/Misinformation',
      'Disproof/Debunk': 'Disproof/Debunk',
      'Strategic narrative/message (reporting)': 'Strategic narrative/message (reporting)',
      'Unclassified threat': 'Unclassified threat',
      'Other_': 'Other',
      'Time period': 'Time period',
      'High': 'High',
      'Medium': 'Medium',
      'Low': 'Low',
      'Index': 'Index',
      'Type': 'Type',
      'Ukrainian': 'Ukrainian',
      'Russian': 'Russian',
      'English': 'English',
      'French': 'French',
      'Polish': 'Polish',
      'German': 'German',
      'Spanish': 'Spanish',
      'Portuguese': 'Portuguese',
      'Hungarian': 'Hungarian',
      'Arabic': 'Arabic',
      'Persian': 'Persian',
      'Norwegian': 'Norwegian',
      'Swedish': 'Swedish',
      'Romanian': 'Romanian',
      'Finnish': 'Finnish',
      'Estonian': 'Estonian',
      'Latvian': 'Latvian',
      'Lithuanian': 'Lithuanian',
      'Bulgarian': 'Bulgarian',
      'Czech': 'Czech',
      'Greek': 'Greek',
      'Dutch': 'Dutch',
      'Armenian': 'Armenian',
      'Indonesian': 'Indonesian',
      'Kazakh': 'Kazakh',
      'Languages': 'Languages',
      'Language': 'Language',
      'Please enter at least 1 symbol to search': 'Please enter at least 1 symbol to search',
      'Please enter at least 1 symbol': 'Please enter at least 1 symbol',
      'Origin country': 'Origin country',
      'Actors origin countries': 'Actors origin countries',
      'Loading...': 'Loading...',
      'Loading...1': 'Loading...',
      'Something went wrong...': 'Something went wrong...',
      'Stories': 'Stories',
      'Story': 'Story',
      'Count': 'Count',
      'Platform': 'Platform',
      'Total messages': 'Total messages',
      'Approved': 'Approved',
      'Deleted': 'Deleted',
      'Sort by': 'Sort by',
      'Sort': 'Sort',
      'A-Z': 'A-Z',
      'Z-A': 'Z-A',
      'Are you sure you want to mark this message as unread?': 'Are you sure you want to mark this message as unread?',
      'Are you sure you want to mark these messages as unread?': 'Are you sure you want to mark these messages as unread?',
      'Are you sure you want to delete these messages?': 'Are you sure you want to delete these messages?',
      'Are you sure you want to delete this message?': 'Are you sure you want to delete this message?',
      'Are you sure you want to restore these messages?': 'Are you sure you want to restore these messages?',
      'Are you sure you want to restore this message?': 'Are you sure you want to restore this message?',
      'N/A': 'N/A',
      'Text': 'Text',
      'selected': 'selected',
      'Export': 'Export',
      'Audience': 'Audience',
      'Messages status': 'Messages status',
      'Reset': 'Reset',
      'Cases': 'Cases',
      'Case': 'Case',
      'Save as case': 'Save as case',
      'Create case': 'Create case',
      'Unread': 'Unread',
      'Status': 'Status',
      'Expand all': 'Expand all',
      'Source group': 'Source group',
      'Enter your query above. For help with search syntax, see our ': 'Enter your query above. For help with search syntax, see our',
      'search guide':'search guide',
      'See our ': 'See our',
      'For advanced AI search': 'For advanced AI search',
      'create a case': 'create a case',
      'Topic discovery': 'Topic discovery',
      'Actor groups': 'Actor groups',
      'Actor group': 'Actor group',
      'Actors': 'Actors',
      'Actor': 'Actor',
      'Are you sure you want to copy these messages from a narrative?':  'Are you sure you want to copy these messages from a narrative?',
      'Positive': 'Positive',
      'Neutral': 'Neutral',
      'Negative': 'Negative',
      'Sentiment': 'Sentiment',
      'Sentiment score': 'Sentiment score',
      'You must select a native language for multilanguage search': 'You must select a native language for multilanguage search',
      'Select native language': 'Select native language',
      'Multilanguage search': 'Multilanguage search',
      'Content types': 'Content types',
      'Post': 'Post',
      'Article': 'Article',
      'Comment': 'Comment',
      'Video': 'Video',
      'Repost': 'Repost',
      'Ad': 'Ad',
      'Social media': 'Social media',
      'Source followers count': 'Source followers count',
      'Average post reach': 'Average post reach',
      'Engagement rate': 'Engagement rate',
      'WEB': 'WEB',
      'Actor platform': 'Actor platform',
      'Actor origin country': 'Actor origin country',
      'Actor main language': 'Actor main language',
      'Political compass': "Політичний компас",
      'The political compass is based on expert analysis of the actor content': 'The political compass is based on expert analysis of the actor content',
      'Ownership / Holding': 'Ownership / Holding',
      'Actor state affiliation': 'Actor state affiliation',
      'Proof': 'Proof',
      'Actor involvement in disinformation campaigns': 'Actor involvement in disinformation campaigns',
      'Identified narratives': 'Identified narratives',
      'Linked actors: inbound': 'Linked actors: inbound',
      'Linked actors: outbound': 'Linked actors: outbound',
      'Link': 'Link',
      'Location': 'Location',
      'Followers': 'Followers',
      'Number of links': 'Number of links',
      'Similarity': 'Similarity',
      'Similarity1': 'Similarity',
      'Similarity threshold': 'Similarity threshold',
      'You can\'t set similarity threshold and do not provide any examples': 'You can\'t set similarity threshold and do not provide any examples',
      'Monthly audience': 'Monthly audience',
      'Average video reach': 'Average video reach',
      'Links': 'Links',
      'Engagement / views': 'Engagement / views',
      'Engagement / followers': 'Engagement / followers',
      'Average post engagement': 'Average post engagement',
      'Cases where the content from the current actor is tracked': 'Cases where the content from the current actor is tracked',
      'Threats where the content from the current actor is tracked': 'Threats where the content from the current actor is tracked',
      'Actors that generated the most reposts, mentions and comments to the current actor': 'Actors that generated the most reposts, mentions and comments to the current actor',
      'Actors which were the most reposted, mentioned and commented by the current actor': 'Actors which were the most reposted, mentioned and commented by the current actor',
      'Platforms': 'Platforms',
      'entitiesNoData': 'No {{val}} detected in this case',
      'organizations': 'organizations',
      'locations': 'locations',
      'persons': 'persons',
      'Organizations': 'Organizations',
      'Locations': 'Locations',
      'Persons': 'Persons',
      'Most mentioned entities': 'Most mentioned entities',
      'Sentiments': 'Sentiments',
      'Evolution': 'Evolution',
      'Matched keywords': 'Matched keywords',
      'Publication date': 'Publication date',
      'Highlights': 'Highlights',
      'Identifier': 'Identifier',
      'Page not found...': 'Page not found...',
      'Authentication error': 'Authentication error',
      'Load More': 'Load More',
      'Inbox': 'Inbox',
      'Reported': 'Reported',
      'Tag': 'Tag',
      'Tags': 'Tags',
      'Data leak': 'Data leak',
      'Coordinated online attack': 'Coordinated online attack',
      'Coordinated offline activity': 'Coordinated offline activity',
      'Malign narrative': 'Malign narrative',
      'Pending': 'Pending',
      'Accepted': 'Accepted',
      'Declined': 'Declined',
      'Threat': 'Threat',
      'Threats': 'Threats',
      'Share as a threat': 'Share as a threat',
      'Share as an instance': 'Share as an instance',
      "You are about to share your case as a threat. Once submitted, it will become visible to other users of our system and you won’t be able to edit it anymore. Please, double-check that the content of the case and its settings are accurate.":
        "You are about to share your case as a threat. Once submitted, it will become visible to other users of our system and you won’t be able to edit it anymore. Please, double-check that the content of the case and its settings are accurate.",
      'The case does not meet requirements for sharing': 'The case does not meet requirements for sharing',
      "This case can’t be shared as a threat because it doesn't meet the necessary criteria. Please update the case according to the following requirements:":
        "This case can’t be shared as a threat because it doesn't meet the necessary criteria. Please update the case according to the following requirements:",
      'Description Required: ': 'Description Required: ',
      'Please provide a description for this case.': 'Please provide a description for this case.',
      'Minimum 3 Confirmed Messages: ': 'Minimum 3 Confirmed Messages: ',
      'To share a case as a threat, it should contain at least 3 confirmed messages.':
        'To share a case as a threat, it should contain at least 3 confirmed messages.',
      "Once these requirements are met, you can click the 'Share as a threat' button again, and your case will be shared successfully.": "Once these requirements are met, you can click the 'Share as a threat' button again, and your case will be shared successfully.",
      "Once these requirements are met, you can click the 'Share as an instance' button again, and your case will be shared successfully.": "Once these requirements are met, you can click the 'Share as an instance' button again, and your case will be shared successfully.",
      'All Messages Confirmation: ': 'All Messages Confirmation: ',
      'Ensure that all messages in the case are either confirmed or deleted.': 'Ensure that all messages in the case are either confirmed or deleted.',
      'Save as a case': 'Save as a case',
      'Are you sure you want to save the threat as a case?': 'Are you sure you want to save the threat as a case?',
      'Delete the threat': 'Delete the threat',
      'Are you sure you want to delete the threat?': 'Are you sure you want to delete the threat?',
      'Edit the threat': 'Edit the threat',
      'Edit the instance': 'Edit the instance',
      'Politics': 'Politics',
      'Economy and finance': 'Economy and finance',
      'Public companies': 'Public companies',
      'Elections': 'Elections',
      'Defence': 'Defence',
      'Cyber': 'Cyber',
      'European Integration': 'European Integration',
      'Ukraine <> NATO': 'Ukraine <> NATO',
      'Healthcare': 'Healthcare',
      'Social/cultural': 'Social/cultural',
      'Decolonization': 'Decolonization',
      'Energy Independence': 'Energy Independence',
      'Anti-corruption': 'Anti-corruption',
      'Ecology': 'Ecology',
      'Reconsruction of Ukraine': 'Reconsruction of Ukraine',
      'Business & investments': 'Business & investments',
      'Public services': 'Public services',
      'Volunteers': 'Volunteers',
      'National minorities': 'National minorities',
      'Collaborants': 'Collaborants',
      'Local Governance': 'Local Governance',
      'Weapon supply': 'Weapon supply',
      'Ukraine <> Partners': 'Ukraine <> Partners',
      'Reforms': 'Reforms',
      'Region-specific': 'Region-specific',
      'Russian narratives': 'Russian narratives',
      'Mobilization': 'Mobilization',
      'Constitutional State': 'Constitutional State',
      'Defense and Security': 'Defense and Security',
      'Close': 'Close',
      'Favorite messages': 'Favorite messages',
      'Show only favorite': 'Show only favorite',
      'Moderation': 'Moderation',
      'Delete from favorites': 'Delete from favorites',
      'Add to favorites': 'Add to favorites',
      'Search score': 'Search score',
      'Threat types': 'Threat types',
      'Threat subjects': 'Threat subjects',
      'Save messages to a case': 'Save messages to a case',
      'Case name': 'Case name',
      'Select a case': 'Select a case',
      'Create a new case': 'Create a new case',
      'Add to case': 'Add to case',
      'Summary': 'Summary',
      'Original source': 'Original source',
      'Message distribution': 'Message distribution',
      "Start typing a case name to add messages to an existing one or create a new case by typing its name and pressing the 'Create New Case' button.": "Start typing a case name to add messages to an existing one or create a new case by typing its name and pressing the 'Create New Case' button.",
      'Start typing an existing case name or create a new one': 'Start typing an existing case name or create a new one',
      'Suggested case type': 'Suggested case type',
      "What's going on here": "What's going on here",
      'A case that automatically collects all data for a certain topic based on your search query. Can contain both boolean and AI search. Is updated every 30 minutes.': 'A case that automatically collects all data for a certain topic based on your search query. Can contain both boolean and AI search. Is updated every 30 minutes.',
      'An empty case to which you can manually add messages from search and other cases. Changing the type to “Manual” case type will reset your search settings.': 'An empty case to which you can manually add messages from search and other cases. Changing the type to “Manual” case type will reset your search settings.',
      'An empty case to which you can manually add messages from search and other cases.': 'An empty case to which you can manually add messages from search and other cases.',
      'Manual': 'Manual',
      'Something went wrong. Please, try again...': 'Something went wrong. Please, try again...',
      'Disable filters': 'Disable filters',
      'Read/Unread': 'Read/Unread',
      'Subjects': 'Subjects',
      'Topic': 'Topic',
      'Show only...': 'Show only...',
      'Show only read': 'Show only read',
      'Show only unread': 'Show only unread',
      'At least one filter must be present: keywords, examples, sources or source groups': 'At least one filter must be present: keywords, examples, actors or actor groups',
      'To create a Narrative, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.': 'To create a Narrative, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.',
      'To create an instance, you must provide at least one example': 'To create an instance, you must provide at least one example',
      'To save the changes, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.': 'To save the changes, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.',
      'Share link': 'Share link',
      'Export PDF': 'Export PDF',
      'Export settings': 'Export settings',
      'Validity period': 'Validity period',
      'Make sure this link stops working after certain period of time': 'Make sure this link stops working after certain period of time',
      'Select a link duration period': 'Select a link duration period',
      'Two weeks': 'Two weeks',
      'Three Months': 'Three Months',
      'Set password for the share link': 'Set password for the share link',
      'Copy the link to the report': 'Copy the link to the report',
      'Select duration...': 'Select duration...',
      'View details': 'View details',
      'Countries': 'Countries',
      'Verification': 'Verification',
      'Other': 'Other',
      'Breakdowns': 'Breakdowns',
      'Specific groups': 'Specific groups',
      'State-affiliated actors': 'State-affiliated actors',
      'Compromised actors': 'Compromised actors',
      'Total number': 'Total number',
      'Split by type': 'Split by type',
      'Split by affiliation country': 'Split by affiliation country',
      'Top state-affiliated actors': 'Top state-affiliated actors',
      'Top compromised actors': 'Top compromised actors',
      'Total audience': 'Total audience',
      'Audience size': 'Audience size',
      'Very small (< 1k)': 'Very small (< 1k)',
      'Small (1k - 10k)': 'Small (1k - 10k)',
      'Medium (10k - 100k)': 'Medium (10k - 100k)',
      'Big (100k - 500k)': 'Big (100k - 500k)',
      'Very big (> 500k)': 'Very big (> 500k)',
      'Showing results for Similar message search': 'Showing results for Similar message search',
      'Multilingual search': 'Multilingual search',
      'Multilingual search text': 'Automatically search for relevant messages in other languages',
      'Incident': 'Incident',
      'A case that automatically collects all data for a certain topic based on provided examples and similarity threshold. Is updated every 30 minutes. Supports Stories creation.': 'A case that automatically collects all data for a certain topic based on provided examples and similarity threshold. Is updated every 30 minutes. Supports Stories creation.',
      'A case that automatically collects all data for a certain topic based on provided exampels and similarity threshold. Is updated every 30 minutes. Changing the type "Narrative" to “Incident” case type will reset your search settings.': 'A case that automatically collects all data for a certain topic based on provided exampels and similarity threshold. Is updated every 30 minutes. Changing the type "Narrative" to “Incident” case type will reset your search settings.',
      'More results, less accuracy': 'More results, less accuracy',
      'Less results, more accuracy': 'Less results, more accuracy',
      'Ukraine': 'Ukraine',
      'Russia': 'Russia',
      'Kherson region': 'Kherson region',
      'Zaporizhya region': 'Zaporizhya region',
      'Donetsk region': 'Donetsk region',
      'Luhansk region': 'Luhansk region',
      'Mykolayiv region': 'Mykolayiv region',
      'Kharkiv region': 'Kharkiv region',
      'Crimea': 'Crimea',
      'ToT': 'ToT',
      'Moldova': 'Moldova',
      'Debunk': 'Debunk',
      'Case type': 'Case type',
      'GEO Scope': 'GEO Scope',
      'Counter-narrative': 'Counter-narrative',
      'Counter-incident': 'Counter-incident',
      'Counter-campaign': 'Counter-campaign',
      'Find similar messages': 'Find similar messages',
      'Case types': 'Case types',
      'Detect Automatically': 'Detect Automatically',
      'Instances': 'Instances',
      'Instance type': 'Instance type',
      'Instance topic': 'Instance topic',
      'No bots identified': 'No bots identified',
      'No affiliated actors identified': 'No affiliated actors identified',
      'No compromised actors identified': 'No compromised actors identified',
      'Sanctions': 'Sanctions',
      'Disinformation': 'Disinformation',
      'Indicators of Compromise (IoC)': 'Indicators of Compromise (IoC)',
      'Proof of IoC': 'Proof of IoC',
      'Top tags by number of posts': 'Top tags by number of posts',
      'Top actors by number of posts': 'Top actors by number of posts',
      'Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries': 'Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries',
      'Sources are frequently sharing disinformative content, which was autodetected by Osavul AI': 'Sources are frequently sharing disinformative content, which was autodetected by Osavul AI',
      'The source is reported to be state affiliated by an authoritative organization (government or non-government)': 'The source is reported to be state affiliated by an authoritative organization (government or non-government)',
      'created by ': 'created by ',
      'created by1 ': 'created by ',
      'First message': 'First message',
      'Last message': 'Last message',
      'AI-powered search': 'AI-powered search',
      'AI-powered search hint': 'Apply AI to search for relevant content based on your query',
      'Relevance': 'Relevance',
      'See all messages': 'See all messages',
      'Last messages': 'Last messages',
      'Report an instance': 'Report an instance',
      'Show advanced options': 'Show advanced options',
      'Example of a post, article or message about the instance': 'Example of a post, article or message about the instance',
      "Give your instance a self-explanatory name. Ideally, it should be a complete sentence answering the 'W' questions": "Give your instance a self-explanatory name. Ideally, it should be a complete sentence answering the 'W' questions",
      "The instance will include messages starting from this day": "The instance will include messages starting from this day",
      "The instance will include messages till this day. Leave it open to keep instance updating.": "The instance will include messages till this day. Leave it open to keep instance updating.",
      "This example will be used to find similar content for the instance. Copy paste here post, article or message that describes and instance. Try to insert only relevant information (e.g. only relevant paragraph from the long article). When available - use more than 1 example.": "This example will be used to find similar content for the instance. Copy paste here post, article or message that describes and instance. Try to insert only relevant information (e.g. only relevant paragraph from the long article). When available - use more than 1 example.",
      "Select all regions to which the instance is relevant. Choose Ukraine for national-level stories.": "Select all regions to which the instance is relevant. Choose Ukraine for national-level stories.",
      "Select a type that descries your instance best": "Select a type that descries your instance best",
      "Select one or more topics relevant to the instance": "Select one or more topics relevant to the instance",
      "Use this form to report an instance to Osavul.": "Use this form to report an instance to Osavul.",
      "The instance can be an information threat, fake news, physical event, example of good communication, statement of a spokesperson, example of an online discussion, etc.": "The instance can be an information threat, fake news, physical event, example of good communication, statement of a spokesperson, example of an online discussion, etc.",
      "For the reported instance Osavul AI will collect all the similar messages from Web, Social and Telegram and measure its trend and impact.": "For the reported instance Osavul AI will collect all the similar messages from Web, Social and Telegram and measure its trend and impact.",
      "Once instance is populated, the admin team will be notified. It will review it and consider while setting priorities for strategic communications.": "Once instance is populated, the admin team will be notified. It will review it and consider while setting priorities for strategic communications.",
      "The instance will be also visible on the public dashboard.": "The instance will be also visible on the public dashboard.",
      'Create stories from case': 'Create stories from case',
      'Candidates min threshold': 'Candidates min threshold',
      'Use max grouping distance': 'Use max grouping distance',
      'Grouping max distance': 'Grouping max distance',
      'Showing search results for the actor:': 'Showing search results for the actor:',
      'Total stories': 'Total stories',
      'Add story to case': 'Add story to case',
      'Add story to threat': 'Add story to threat',
      'Add to threat': 'Add to threat',
      'Tops': 'Tops',
      'Total actors:': 'Total actors:',
      'Edit history': 'Edit history',
      'Case edit history': 'Case edit history',
      'Changes': 'Changes',
      'updated by': 'updated by',
      'Users': 'Users',
      'User': 'User',
      'Create user': 'Create user',
      'Are you sure you want to delete a users?': 'Are you sure you want to delete a users?',
      'UNDERPRIVILEGED': 'Underprivileged',
      'STANDARD': 'Standard',
      'MODERATOR': 'Moderator',
      'Underprivileged': 'Underprivileged',
      'Standard': 'Standard',
      'Moderator1': 'Moderator',
      'Admin': 'Admin',
      'Regular': 'Regular',
      'First name': 'First name',
      'Last name': 'Last name',
      'User type': 'User type',
      'Cases moderation': 'Cases moderation',
      'User with this email already exist': 'User with this email already exist',
      'Create stories': 'Create stories',
      'Please, wait till our AI builds stories for this case. This takes 5 mins on average.': 'Please, wait till our AI builds stories for this case. This takes 5 mins on average.',
      'Edit actor group': 'Edit actor group',
      'Linked actors': 'Linked actors',
      'Inbound': 'Inbound',
      'Outbound': 'Outbound',
      "Message relevant": "Message relevant",
      "Message irrelevant": "Message irrelevant",
      'Create link': 'Create link',
      'Edit metadata': 'Edit metadata',
      'Not selected': 'Not selected',
      'You need to select a start date to be able to save the search results as a case': 'You need to select a start date to be able to save the search results as a case',
      'Narrative not found': 'Page not found',
      'Actor background': 'Actor background',
      'Source not found': 'Source not found',
      'Report not found': 'Report not found',
      'Group not found': 'Group not found',
      'Top bots': 'Top bots',
      'Inauthentic account type': 'Inauthentic account type',
      'Special tags': 'Special tags',
      'System language': 'System language',
      'Show only bots': 'Show only bots',
      'Query {{raw_query}} is invalid at position {{pos}} - {{query_copy}}': 'Invalid part of the query after {{query_copy}}',
      '{{class_name}} does not support {{token_name}} as a token': '{{class_name}} does not support {{token_name}} as a token',
      'Cannot extract token {{token_name]} from string \'{{string}}\'': 'Cannot extract token {{token_name]} from string \'{{string}}\'',
      'Number of operands in group less or equal than min operators value ({{min_or_operands}})': 'Number of operands in group with min operands less or equal than min operands value ({{min_or_operands}})',
      'Token {{sub_token_string}} is invalid inside token {{string}}': 'Token {{sub_token_string}} is invalid inside token {{string}}',
      'Token {{string}} cannot contain one or less tokens inside': 'Token {{string}} cannot contain one or less tokens inside',
      'Unbalanced parenthesis in query: {{string_query}} in position {{pos}}': 'Unbalanced parenthesis in query: {{string_query}}',
      'Unbalanced parenthesis in query: {{string_query}}': 'Unbalanced parenthesis in query: {{string_query}}',
      '{{class_name}} does not support {{sub_token_name}} as a sub token': '{{class_name}} does not support {{sub_token_name}} as a sub token',
      'Invalid first token: {{token}}': 'Invalid first token: {{token}}',
      'Invalid last token: {{token}}': 'Invalid last token: {{token}}',
      'Invalid token {{token}} after {{prev_token}}': 'Invalid token {{token}} after {{prev_token}}',
      'Show only inauthentic accounts': 'Show only inauthentic accounts',
      'Bot': 'Bot',
      'Reason': 'Reason',
      'Indicators of Compromise (IoC)1': 'Indicators of Compromise (IoC)',
      'General infomation': 'General infomation',
      'Threat type': 'Threat type',
      'Inauthentic accounts': 'Inauthentic accounts',
      'Top inauthentic accounts': 'Top inauthentic accounts',
      'No inauthentic accounts identified': 'No inauthentic accounts identified',
      'You can report both constructive and destructive information instances. A good example of a constructive information instance could be an article or a statement released about a certain event that mitigates the adverse effects of a specific issue or a prioritized strategic communications topic or narrative. This could also include a portion of a discussion you observe anywhere on the web or social media. Destructive information instances could be threats, fake news, hostile events, manipulative narratives, etc.': 'You can report both constructive and destructive information instances. A good example of a constructive information instance could be an article or a statement released about a certain event that mitigates the adverse effects of a specific issue or a prioritized strategic communications topic or narrative. This could also include a portion of a discussion you observe anywhere on the web or social media. Destructive information instances could be threats, fake news, hostile events, manipulative narratives, etc.',
      'Upon reporting any of these instances, Osavul AI will gather similar messages from the web, social media, and Telegram, and evaluate the trends and impact of the reported instance. This information will be shared with the admin team, which includes specialized civil society organizations and analysts, to validate the instance and make an assessment from the perspective of strategic communications priorities.': 'Upon reporting any of these instances, Osavul AI will gather similar messages from the web, social media, and Telegram, and evaluate the trends and impact of the reported instance. This information will be shared with the admin team, which includes specialized civil society organizations and analysts, to validate the instance and make an assessment from the perspective of strategic communications priorities.',
      'The reported instance with the appropriate machine score will be listed on the StratCom dashboards for visibility. However, the instance can only make it to the priority list of topics and/or narratives after an assessment by the network of StratCom professionals, researchers, and analysts.': 'The reported instance with the appropriate machine score will be listed on the StratCom dashboards for visibility. However, the instance can only make it to the priority list of topics and/or narratives after an assessment by the network of StratCom professionals, researchers, and analysts.',
      'Fake/Disinformation1': 'Fake/Disinformation',
      'Manipulation/Misinformation1': 'Manipulation/Misinformation',
      'Disproof/Debunk1': 'Disproof/Debunk',
      'Strategic narrative/message (reporting)1': 'Strategic narrative/message (reporting)',
      'Unclassified threat1': 'Unclassified threat1',
      'Other_1': 'Other',
      'Change password': 'Change password',
      'New password': 'New password',
      'Repeat password': 'Repeat password',
      'Profile': 'Profile',
      'Settings': 'Settings',
      'Security': 'Security',
      'Use two-step verification': 'Use two-step verification',
      'Enable 2FA': 'Enable 2FA',
      'You will need an authenticator mobile app to complete this process, such as one of the following:': 'You will need an authenticator mobile app to complete this process, such as one of the following:',
      'Scan the QR code with your authenticator': 'Scan the QR code with your authenticator',
      "If you can't scan the code you can enter this secret key into your authentication app": "If you can't scan the code you can enter this secret key into your authentication app",
      'After scanning the QR code above enter the six-digit code generated by your authenticator': 'After scanning the QR code above enter the six-digit code generated by your authenticator',
      'Enable two-step verification': 'Enable two-step verification',
      'Enable': 'Enable',
      'Incorrect one time password': 'Incorrect one-time password',
      'Disable 2FA': 'Disable 2FA',
      'To disable 2FA enter the six-digit code generated by your authenticator': 'To disable 2FA enter the six-digit code generated by your authenticator',
      'Disable two-step verification': 'Disable two-step verification',
      'Disable': 'Disable',
      'One-time password': 'One-time password',
      '2FA is already disabled': '2FA is already disabled',
      'Select all affiliation countries': 'Select all affiliation countries',
      'Is public': 'Is public?',
      'Public name EN': 'Public english name',
      'Public name UK': 'Public ukrainian name',
      'Public description EN': 'Public english description',
      'Public description UK': 'Public ukrainian description',
      'Impact assessment': 'Impact assessment',
      'Show less': 'Show less',
      'Show more': 'Show more',
      'Opinions': 'Opinions',
      'Key opinions': 'Key opinions',
      'Positive opinion': 'Positive opinion',
      'Neutral opinion': 'Neutral opinion',
      'Negative opinion': 'Negative opinion',
      'The case failed to be collected. Please contact your workspace administrator.': 'The case failed to be collected. Please contact your workspace administrator.',
      'Sentiment towards media product': 'Sentiment towards media product',
      'Sentiment towards a topic or narrative': 'Sentiment towards a topic or narrative',
      'Sentiment towards person': 'Sentiment towards person',
      'Sentiment towards organization': 'Sentiment towards organization',
      'Sentiment towards country': 'Sentiment towards country',
      'Custom sentiment option': 'Custom sentiment calculation',
      'Write a name of a country. E.g.: Russia, USA': 'Write a name of a country. E.g.: Russia, USA',
      'Write a custom keyword or phrase to measure sentiment towards. E.g.: "I love my country"': 'Write a custom keyword or phrase to measure sentiment towards. E.g.: "I love my country"',
      'A case to analyze public reaction to a particular media product.': 'A case to analyze public reaction to a particular media product.',
      'Link to media product': 'Link to media product',
      'Sentiment analysis': 'Sentiment analysis',
      'Topic/Narrative': 'Topic/Narrative',
      'Person': 'Person',
      'Organization': 'Organization',
      'Please wait, the case is being collected. This can take up to 30 minutes depending on the amount of data to be collected.': 'Please wait, the case is being collected. This can take up to 30 minutes depending on the amount of data to be collected.',
      'Give a short name of a topic, narrative or opinion to measure reaction to. E.g.: ceasefire in Gaza, Covid vaccines can cause death, sending Western troops to Ukraine.': 'Give a short name of a topic, narrative or opinion to measure reaction to. E.g.: ceasefire in Gaza, Covid vaccines can cause death, sending Western troops to Ukraine.',
      'Write a name and minimum necessary content that helps identify a person in content. E.g.: Vladimir Putin, leader of PWC Wagner': 'Write a name and minimum necessary content that helps identify a person in content. E.g.: Vladimir Putin, leader of PWC Wagner',
      'Write a name of an organization. E.g.: UN, EU': 'Write a name of an organization. E.g.: UN, EU',
      'Searching content in our database. Stage 1': 'Searching content in our database. Stage 1',
      'Searching content in our database. Stage 2': 'Searching content in our database. Stage 2',
      'Searching content in our database. Stage 3': 'Searching content in our database. Stage 3',
      'Fetching data. Stage 1': 'Fetching data. Stage 1',
      'Fetching data. Stage 2': 'Fetching data. Stage 2',
      'Fetching data. Stage 3': 'Fetching data. Stage 3',
      'Processing data. Stage 1': 'Processing data. Stage 1',
      'Processing data. Stage 2': 'Processing data. Stage 2',
      'Processing data. Stage 3': 'Processing data. Stage 3',
      'Values': 'Values',
      'Points': 'Points',
      'Download as PNG': 'Download as PNG',
      'Download as SVG': 'Download as SVG',
      'Chart view': 'Chart view',
      'By countries': 'By countries',
      'By platforms': 'By platforms',
      'By stories': 'By stories',
      'By messages': 'By messages',
      'Messages by countries': 'Messages by countries',
      'Messages by platforms': 'Messages by platforms',
      'Dashboards': 'Dashboards',
      'You have no available dashboards yet': 'You have no available dashboards yet',
      'Link to content1': 'Link to content',
      'Add to actor group': 'Add to actor group',
      "Start typing an actor group name to add actors to an existing one or create a new group by typing its name and pressing the 'Create New Group' button.": "Start typing an actor group name to add actors to an existing one or create a new group by typing its name and pressing the 'Create New Group' button.",
      'Save actors to an actor group': 'Save actors to an actor group',
      'Actor group name': 'Actor group name',
      'Start typing an existing group name or create a new one': 'Start typing an existing group name or create a new one',
      'Create a new actor group': 'Create a new actor group',
      'Frontline report': 'Frontline report',
      'Reform of the Armed Forces': 'Reform of the Armed Forces',
      'Corruption in the Armed Forces': 'Corruption in the Armed Forces',
      'Italian': 'Italian',
      'Hindi': 'Hindi',
      'Thai': 'Thai',
      'Turkish': 'Turkish',
      'Danish': 'Danish',
      'Bengali': 'Bengali',
      'Kurdish': 'Kurdish',
      'Belarusian': 'Belarusian',
      'Copy a group to another workspace': 'Copy a group to another workspace',
      'Are you sure you want to copy these sources from a group?': 'Are you sure you want to copy these sources from a group?',
      'Messages distribution: Countries': 'Messages distribution: Countries',
      'Messages distribution: Platforms': 'Messages distribution: Platforms',
      'Messages distribution: Stories': 'Messages distribution: Stories',
      'Evolution: Messages': 'Evolution: Messages',
      'Evolution: Reactions': 'Evolution: Reactions',
      'Evolution: Views': 'Evolution: Views',
      'Evolution: Manipulation index': 'Evolution: Manipulation index',
      'Sentiment: Messages': 'Sentiment: Messages',
      'Sentiment: Reactions': 'Sentiment: Reactions',
      'Sentiment: Views': 'Sentiment: Views',
      'Actors breakdowns': 'Actors breakdowns',
      'Actors tops': 'Actors tops',
      'Actors specific groups': 'Actors specific groups',
      'Download PDF': 'Download PDF',
      'PDF settings': 'PDF settings',
      'Inauthentic': 'Inauthentic',
      'Disinfo spreaders': 'Disinfo spreaders',
      'Original posts': 'Original posts',
      'Reposts': 'Reposts',
      'Media articles': 'Media articles',
      'Comments': 'Comments',
      'Videos': 'Videos',
      'Ads': 'Ads',
      'Chat messages': 'Chat messages',
      'Likes': 'Likes',
      'Apply aspect based sentiment to case': 'Apply aspect based sentiment to case',
      'Create Stories': 'Create Stories',
      'Group messages into key topics.': 'Group messages into key topics.',
      'Run Contextual Sentiment Analysis': 'Run Contextual Sentiment Analysis',
      'Calculate sentiment towards an entity or event.': 'Calculate sentiment towards an entity or event.',
      'Messages distribution': 'Messages distribution',
      'Evolution: Percentage': 'Evolution: Percentage',
      'Choose context for sentiment analysis': 'Choose context for sentiment analysis',
      'Peace talks': 'Peace talks',
      'Exports': 'Exports',
      'export_status_pending': 'Pending',
      'export_status_in_progress': 'In progress',
      'export_status_completed': 'Completed',
      'export_status_failed': 'Failed',
      'export_type_case': 'Case',
      'export_output_format_csv': 'CSV',
      'export_output_format_jsonl': 'JSON',
      'export_output_format_xml': 'XML',
      'Download': 'Download',
      'Object': 'Object',
      'Output format': 'Output format',
      'Created at': 'Created at',
      'Last downloaded at': 'Last downloaded at',
      'Object ID': 'Object ID',
      'An export of': 'An export of',
      'export_type_of_case': 'Export of case',
      'Merge': 'Merge',
      'Please select at least 2 stories to merge': 'Please select at least 2 stories to merge',
      'Merge stories': 'Merge stories',
      'Proof of affiliation (UK)': 'Proof of affiliation (UK)',
      'Proof of affiliation (EN)': 'Proof of affiliation (EN)',
      'Proof link': 'Proof link',
      'Compromised account': 'Compromised account',
      'Compromised account type': 'Compromised account type',
      'Proof of compromise (UK)': 'Proof of compromise (UK)',
      'Proof of compromise (EN)': 'Proof of compromise (EN)',
      'Select tags': 'Select tags',
      'No tags found': 'No tags found',
      'Please select country of affiliation': 'Please select country of affiliation',
      'Please select type of affiliation': 'Please select type of affiliation',
      'Please enter proof of affiliation (UK)': 'Please enter proof of affiliation (UK)',
      'Please enter proof of affiliation (EN)': 'Please enter proof of affiliation (EN)',
      'Please enter proof link': 'Please enter proof link',
      'Please select compromised account type': 'Please select compromised account type',
      'Please enter proof of compromise (UK)': 'Please enter proof of compromise (UK)',
      'Please enter proof of compromise (EN)': 'Please enter proof of compromise (EN)',
      'Summary: Actors': 'Summary: Actors',
      'Summary: Publications': 'Summary: Publications',
      'Summary: Views': 'Summary: Views',
      'Summary: Reactions': 'Summary: Reactions',
      'Create a note': 'Create a note',
      'Place a note on the top of the widget': 'Place a note on the top of the widget',
      'Note': 'Note',
      'Show full text': 'Show full text',
      'Text length(characters)': 'Text length(characters)',
      'Show highlights': 'Show highlights',
      'Agregation': 'Agregation',
      'Breakdown': 'Breakdown',
      'Show Platforms': 'Show Platforms',
      'Show Countries': 'Show Countries',
      'Show Audience': 'Show Audience',
      'Show Verification': 'Show Verification',
      'Show Top actors': 'Show Top actors',
      'Show Top tags': 'Show Top tags',
      'Show State-affiliated': 'Show State-affiliated actors',
      'Show Compromised': 'Show Compromised actors',
      'Show Inauthentic ': 'Show Inauthentic accounts',
      'Number of actors': 'Number of actors',
      'Number of tags': 'Number of tags',
      'Top stories': 'Top stories',
      'Top messages': 'Top messages',
      'Report name': 'Report name',
      'Please select at least one case': 'Please select at least one case',
      'No cases found': 'No cases found',
      'Posts': 'Posts',
      'Rating': 'Rating',
      'Opinion balance': 'Opinion balance',
      'Actor Types': 'Actor types',
      'Top actors by negative sentiment': 'Top actors by negative sentiment',
      'Top actors by positive sentiment': 'Top actors by positive sentiment',
      'Top opinions': 'Top opinions',
      'Geography': 'Geography',
      'Collect comments to posts': 'Collect comments to posts',
      'Top actors by audience': 'Top actors by audience',
      'Mostly negative': 'Mostly negative',
      'Mostly positive': 'Mostly positive',
      'Controversial': 'Controversial',
      'Topic modeling': 'Topic modeling',
      'Events only': 'Events only',
      'Opinions only': 'Opinions only',
      'Events & opinions': 'Events & opinions',
      'Choose entity for topic modeling analysis': 'Choose entity for topic modeling analysis',
      'Topic modeling entity': 'Topic modeling entity',
      'Edit opinion': 'Edit opinion',
      'Edit event': 'Edit event',
      'Are you sure you want to delete this opinion?': 'Are you sure you want to delete this opinion?',
      'Are you sure you want to delete this event?': 'Are you sure you want to delete this event?',
      'Yay! You have seen it all': 'Yay! You have seen it all',
      'Please select at least 2 opinions to merge': 'Please select at least 2 opinions to merge',
      'Please select at least 2 events to merge': 'Please select at least 2 events to merge',
      'Are you sure you want to delete this events?': 'Are you sure you want to delete this events?',
      'Last updated time:': 'Last updated time:',
      'Content analyzed': 'Content analyzed',
      'To save the changes, you must provide at least one keyword.': 'To save the changes, you must provide at least one keyword.',
      'Fixed date range': 'Fixed date range',
      'Yesterday': 'Yesterday',
      'Yesterday - Today': 'Yesterday - Today',
      'Last 7 days': 'Last 7 days',
      'Current week': 'Current week',
      'Last week': 'Last week',
      'Date range filter preset': 'Date range filter preset',
      'Date range': 'Date range',
      'Opinions have not been detected': 'Opinions have not been detected',
      'Language for translation': 'Language for translation',
      'Exit modal': 'Exit modal',
      'Are you sure you want to exit?': 'Are you sure you want to exit?',
      'Yes': 'Yes',
      'There are no selected events': 'There are no selected events',
      'Copy to dashboard': 'Copy to dashboard',
      'Copy events to a dashboard': 'Copy events to a dashboard',
      'Dashboard name': 'Dashboard name',
      'Start typing a dashboard name': 'Start typing a dashboard name',
      'Add default sorting': 'Add default sorting',
      'Sorting order': 'Sorting order',
      'Ascending order (A-Z)': 'Ascending order (A-Z)',
      'Descending order (Z-A)': 'Descending order (Z-A)',
      'Recency': 'Recency',
      'Event first seen': 'Event first seen',
      'Event last update': 'Event last update',
      'Date filtering returns events updated in the selected period, with statistics based only on messages from that time;' : 'Date filtering returns events updated in the selected period, with statistics based only on messages from that time;',
      'unfiltered events may include earlier or later data.': 'unfiltered events may include earlier or later data.',
      'All actors': 'All actors',
      'Stats aggregation': 'Stats aggregation',
      'Stats breakdown': 'Stats breakdown',
      'Custom': 'Custom',
      'Sort by audience': 'Sort by audience',
      'Sort by number of messages': 'Sort by number of messages',
      'Widgets settings': 'Widgets settings',
      'Summary panel': 'Summary panel',
      'Summary panel view': 'Summary panel view',
      'Event card': 'Event card',
      'Event card settings': 'Event card settings',
      'Opinion card': 'Opinion card',
      'Opinion card settings': 'Opinion card settings',
      'Sentiment bar': 'Sentiment bar',
      'Sentiment bar settings': 'Sentiment bar settings',
      'Geo widget': 'Geo widget',
      'Top actors settings': 'Top actors settings',
      'Actor types': 'Actor types',
      'Evolution chart tabs'  : 'Evolution chart tabs',
      'Select tabs': 'Select tabs',
      'Evolution chart breakdowns': 'Evolution chart breakdowns',
      'Select breakdowns': 'Select breakdowns',
      'There are no selected items to add to the group': 'There are no selected items to add to the group',
      'Are you sure you want to delete the selected sources from the group?' : 'Are you sure you want to delete the selected sources from the group?',
      'Confirm Deletion': 'Confirm Deletion',
      'Copy to group': 'Copy to group',
      'Add sources': 'Add sources',
      'Edit group info': 'Edit group info',
      'Top actors by number of messages': 'Top actors by number of messages',
      'Messages count': 'Messages count',
      'All {{messages}} messages on this page are selected.': 'All {{messages}} messages on this page are selected.',
      'Select all {{messages}} messages': 'Select all {{messages}} messages',
      'allPageMessagesSelected_zero': '{{count}} messages on this page are selected.',
      'allPageMessagesSelected_one': '{{count}} message on this page is selected.',
      'allPageMessagesSelected_few': 'All {{count}} messages on this page are selected.',
      'allPageMessagesSelected_many': 'All {{count}} messages on this page are selected.',
      'allPageMessagesSelected_other': 'All {{count}} messages on this page are selected.',
      'selectAllMessages_zero': 'Select {{count}} messages',
      'selectAllMessages_one': 'Select {{count}} message',
      'selectAllMessages_few': 'Select {{count}} messages',
      'selectAllMessages_many': 'Select {{count}} messages',
      'selectAllMessages_other': 'Select {{count}} messages',
      'allMessagesSelected_zero': 'All {{count}} messages are selected.',
      'allMessagesSelected_one': 'All {{count}} message is selected.',
      'allMessagesSelected_few': 'All {{count}} messages are selected.',
      'allMessagesSelected_many': 'All {{count}} messages are selected.',
      'allMessagesSelected_other': 'All {{count}} messages are selected.',
      'This process may take a few minutes. Please wait before checking the changes.': 'This process may take a few minutes. Please wait before checking the changes.',
      'The process may take some time. Please wait a couple of minutes, then refresh the page to see the changes.': 'The process may take some time. Please wait a couple of minutes, then refresh the page to see the changes.',
      'You can either include or exclude options': 'You can either include or exclude options',
      'Exclude': 'Exclude',
      'Add actors': 'Add actors',
      'Paused': 'Paused',
      'Archived': 'Archived',
      'Sampling': 'Sampling',
      'We used sampling to analyze this data. A sample is a statistically significant representation of the dataset, suitable for AI analysis. The sampling rate indicates the part of the total dataset included in the analysis.': 'We used sampling to analyze this data. A sample is a statistically significant representation of the dataset, suitable for AI analysis. The sampling rate indicates the part of the total dataset included in the analysis.',
      'Internal': 'Internal',
      'Deactivated': 'Deactivated',
      'Trial': 'Trial',
      'Free Access': 'Free Access',
      'Paid Client': 'Paid Client',
      'Paid Client UCBI': 'Paid Client UCBI',
      'Paid Client I3': 'Paid Client I3',
      'Favorites': 'Favorites',
      'Remove from favorites': 'Remove from favorites',
      'private': 'Private',
      'private_female': 'Private',
      'private_male': 'Private',
      'public': 'Public',
      'public_female': 'Public',
      'public_male': 'Public',
      'sharable': 'Sharable',
      'sharable_female': 'Sharable',
      'sharable_male': 'Sharable',
      'Privacy settings': 'Privacy settings',
      'Workspaces': 'Workspaces',
      'Feeds': 'Feeds',
    },
  },
  uk: {
    translation: {
      Dashboard: 'Дашборд',
      Reports: 'Звіти',
      Narratives: 'Наративи',
      Messages: 'Повідомлення',
      Sources: 'Джерела',
      Groups: 'Групи',
      'Search here': 'Шукати тут',
      'Days of war': 'Днів у війні',
      Moderator: 'Модератор',
      Administrator: 'Адміністратор',
      Login: 'Вхід',
      Logout: 'Вихід',
      Email: 'Електронна пошта',
      Password: 'Пароль',
      Date: 'Дата',
      Message: 'Повідомлення',
      Group: 'Група',
      Source: 'Джерело',
      URL: 'Посилання',
      'Source Type': 'Тип джерела',
      'Source types': 'Типи джерел',
      'Source type': 'Тип джерела',
      'Number of subscribers': 'Кількість підписників',
      'Create narrative': 'Створити наратив',
      'Create group': 'Створити групу',
      Narrative: 'Наратив',
      'Number of Messages': 'Кількість повідомлень',
      'Messages (new)': 'Повідомлення (нові)',
      'Are you sure you want to delete a narrative?':
        'Ви впевнені, що хочете видалити наратив?',
      Delete: 'Видалити',
      Cancel: 'Відмінити',
      New: 'Новий',
      Name: 'Назва',
      Description: 'Опис',
      Active: 'Активний',
      Inactive: 'Неактивний',
      Keywords: 'Ключові слова',
      Examples: 'Приклади',
      'Add another example': 'Додати ще один приклад',
      Select: 'Вибрати',
      Add: 'Додати',
      Edit: 'Редагування',
      Save: 'Зберeгти',
      'Edit narrative': 'Редагувати наратив',
      'Copy': 'Скопіювати',
      'Are you sure you want to delete these messages from a narrative?':
        'Ви впевнені, що хочете видалити ці повідомлення з наративу?',
      'Are you sure you want to delete this message from a narrative?':
        'Ви впевнені, що хочете видалити повідомлення з наративу?',
      'Are you sure you want to delete these messages?': 'Ви впевнені, що хочете видалити ці повідомлення з наративу?',
      'Are you sure you want to approve this message?':
        'Ви впевнені, що хочете затвердити це повідомлення?',
      'Are you sure you want to approve these messages?': 'Ви впевнені, що хочете затвердити ці повідомлення?',
      'Create report': 'Додати репорт',
      'Are you sure you want to delete a report?':
        'Ви впевнені, що хочете видалити репорт?',
      'Are you sure you want to delete this group?':
        'Ви впевнені, що хочете видалити групу?',
      'Start date': 'Початкова дата',
      'End date': 'Кінцева дата',
      Create: 'Створити',
      'Edit report': 'Відредагувати репорт',
      Day: 'День',
      Week: 'Тиждень',
      Month: 'Місяць',
      Overview: 'Огляд',
      Publications: 'Публікації',
      Views: 'Перегляди',
      Reactions: 'Реакції',
      Percentage: 'Доля',
      'Number of reactions': 'Кількість реакцій',
      'Number of publications': 'Кількість публікацій',
      'Select All': 'Вибрати все',
      'Deselect All': 'Зняти вибір із усіх',
      'Mark as read': 'Позначити як прочитані',
      'Mark as unread': 'Позначити як непрочитані',
      Approve: 'Затвердити',
      'Refresh whole case history': 'Оновити всю історію наративу',
      'Enhance search results based on user feedback':
        'Покращувати результати пошуку на основі відгуків користувачів',
      'Fix the keywords query': 'Виправте помилку в полі ключові слова',
      'All brackets in the query must be closed':
        'Усі дужки мають бути закриті',
      'Should not contain backslashes':
        'Не можна використовувати бекслеші "\\"',
      'e.g. Zelensky, president AND terrorism AND NOT Biden, Putin':
        'Приклад: Зеленський, президент AND тероризм AND NOT Байден, Путін',
      Author: 'Автор',
      'Show selected': 'Показати обрані',
      'Export report': 'Експортувати звіт',
      'Export to CSV': 'Експортувати в CSV',
      'We automatically build all possible word forms for each search term  (e.g. black cat → black cats, чорний кіт → чорного кота, чорних котів, …).': 'Ми автоматично будуємо всі можливі словоформи для кожного запиту (наприклад, чорний кіт → чорного кота, чорних котів, …).',
      'To limit results to an exact search term, use the ': 'Щоб обмежити результати пошуку точним запитом, використовуйте ',
      ' exact match operator ""': ' оператор точного збігу ""',
      'e.g “cat”': 'наприклад “кіт”',
      'Search supports': 'Пошук підтримує',
      'logical operators': 'логічні оператори',
      Use: 'Використовуйте',
      parenthesis: ' дужки',
      'to group keywords. E.g.: (black cat OR white dog) AND NOT grey elephant': 'щоб групувати ключові слова. Наприклад, ви можете шукати (чорний кіт OR білий пес) AND NOT (сірий слон)',
      'to match any number of characters at the end of a word, e.g. philosoph* → philosophy, philosopher': 'щоб включати в результати слова з будь-якою кількістю довільних символів в кінці, наприклад філософ* → філософія, філософствувати',
      ' [{number}]': ' [{число}]',
      'notation to match a fixed number of characters at the end of a word, e.g. ap[3] → apple, apply': 'щоб включати в результати слова з фіксованою кількістю довільних символів в кінці, наприклад гол[3] → голова, голити',
      'Save narrative': 'Зберeгти наратив',
      'Select date': 'Оберіть дату',
      'Filters': 'Фільтри',
      'Title': 'Заголовок',
      'Search': 'Пошук',
      'We are not able to show results, add your request': 'Ми не можемо показати результати, додайте свій запит',
      'No results were found for this query, refine your search': 'За даним запитом результатів не знайдено, уточніть пошук',
      'Confirm': "Підтвердити",
      'Dismiss': 'Скасувати',
      'Filter by': "Фільтрувати за",
      'Expand all': 'Розгорнути все',
      'Minimize all': 'Згорнути все',
      'Choose platform': 'Обрати платформу',
      'Choose source': 'Обрати джерело',
      'Apply': 'Застосувати',
      'Reset all': 'Скинути все',
      'Operators': 'Оператори',
      'Select all': 'Вибрати все',
      'Deselect all': 'Зняти вибір із усіх',
      'Save as narrative': 'Зберегти як наратив',
      'Engagement': 'Залучення',
      "View mode": 'Режим перегляду',
      'Actors analysis': 'Аналіз джерел',
      'Total': 'Загалом',
      'Verified': 'Верифіковані',
      'Verified by platform (Twitter blue, Telegram verified)': 'Джерела перевірені платформами (Twitter blue, Telegram verified)',
      'Influential': 'Впливові',
      '100k+ followers': 'Більше 100k підписників',
      'State-affiliated': 'Повʼязані з державою',
      'Official gov accounts or accounts known to be linked to governments': 'Офіційні державні акаунти, або акаунти афілійовані з державою',
      'Immature accounts': 'Незрілі акаунти',
      'Recently created accounts or accounts with few followers': 'Нещодавно зареєстровані акаунти, або акаунти з малою кількістю підписників',
      '< 30 days since sign up': 'Акаунти молодші 30 днів на момент публікації',
      'Manipulation': 'Маніпулятивність',
      'There is no data to display in this narrative': 'В даному наративі немає даних для відображення',
      'There is no data to display': 'Немає даних для відображення',
      'Manipulation index': 'Індекс маніпулятивності',
      'From': 'Від',
      'To': 'До',
      'State agency': 'Державний орган',
      'Government official': 'Офіційна особа',
      'State owned media': 'Державне медіа',
      'Reportedly state affiliated': 'Повʼязане з державою',
      'Country': 'Країна',
      'Affiliation': 'Афілійованість',
      'Country of affiliation': 'Афілійована країна',
      'Type of affiliation': `Tип зв'язку`,
      'Proof of affiliation': `Доказ афілійованості`,
      'Followers count': 'Кількість підписників',
      'Content': 'Зміст',
      'All': 'Всі',
      'Compromised accounts': 'Скомпрометовані',
      'Bots': 'Боти',
      'Hackers / Hacktivists': 'Хакери / Хактивісти',
      'Suspended by platform': 'Заблоковані платформою',
      'Influence operations': 'Інформаційні операції',
      'Inauthentic behavior': 'Неавтентична поведінка',
      'Account has been involved in influence operations': 'Акаунт був залучений до інформаційних операцій',
      'Account has been hacked or stolen': 'Акаунт зламано або вкрадено',
      'Engaged or claimed to engage in cyber attacks or similar activity': 'Брав участь або стверджував, що брав участь у кібератаках або подібній діяльності',
      'Account has been suspended by a platform': 'Обліковий запис призупинено платформою',
      'Account was engaged in an inauthentic behavior': 'Обліковий запис був задіяний у неавтентичній поведінці',
      'Accounts that was suspected to be bots': 'Акаунти, які підозрюються як боти',
      'Fake news': 'Фейкові новини',
      'Document leak': 'Витік документів',
      'Protests coordination': 'Координація протестів',
      'Coordinated activity': 'Злагоджена діяльність',
      'Positive narrative': 'Позитивний наратив',
      'Government communication': 'Державна комунікація',
      'Fake/Disinformation': 'Фейк/Дезінформація',
      'Manipulation/Misinformation': 'Маніпуляція/Місінформація',
      'Disproof/Debunk': 'Спростування/дебанк',
      'Strategic narrative/message (reporting)': 'Стратегічний наратив/меседж (звітування)',
      'Unclassified threat': 'Некласифікована загроза',
      'Other_': 'Інше',
      'Time period': 'Період часу',
      'High': 'Високий',
      'Medium': 'Середній',
      'Low': 'Низький',
      'Index': 'Індекс',
      'Type': 'Тип',
      'Ukrainian': 'Українська',
      'Russian': 'Російська',
      'English': 'Англійська',
      'French': 'Французька',
      'Polish': 'Польська',
      'German': 'Німецька',
      'Spanish': 'Іспанська',
      'Portuguese': 'Португальська',
      'Hungarian': 'Угорська',
      'Arabic': 'Арабська',
      'Persian': 'Перська',
      'Norwegian': 'Норвезька',
      'Swedish': 'Шведська',
      'Romanian': 'Румунська',
      'Finnish': 'Фінська',
      'Estonian': 'Естонська',
      'Latvian': 'Латиська',
      'Lithuanian': 'Литовська',
      'Bulgarian': 'Болгарська',
      'Czech': 'Чеська',
      'Greek': 'Грецька',
      'Dutch': 'Голландська',
      'Armenian': 'Вірменська',
      'Indonesian': 'Індонезійська',
      'Kazakh': 'Казахська',
      'Languages': 'Мови',
      'Language': 'Мова',
      'Please enter at least 1 symbol to search': 'Введіть принаймні 1 символ для пошуку',
      'Please enter at least 1 symbol': 'Введіть принаймні 1 символ',
      'Origin country': 'Країна походження',
      'Actors origin countries': 'Країни походження джерел',
      'Loading...': 'Пошук...',
      'Loading...1': 'Завантажуємо...',
      'Something went wrong...': 'Щось пішло не так...',
      'Stories': 'Сюжети',
      'Story': 'Сюжет',
      'Count': 'Кількість',
      'Platform': 'Платформа',
      'Total messages': 'Усього повідомлень',
      'Approved': 'Затверджений',
      'Deleted': 'Видалений',
      'Sort': 'Сортування',
      'Sort by': 'Сортувати за',
      'A-Z': 'А-Я',
      'Z-A': 'Я-А',
      'Are you sure you want to mark this message as unread?': 'Ви впевнені, що хочете позначити це повідомлення як непрочитане?',
      'Are you sure you want to mark these messages as unread?': 'Ви впевнені, що хочете позначити ці повідомлення як непрочитані?',
      'Are you sure you want to restore these messages?': 'Ви впевнені, що бажаєте відновити ці повідомлення?',
      'Are you sure you want to restore this message?': 'Ви впевнені, що бажаєте відновити це повідомлення?',
      'N/A': 'Н/Д',
      'Text': 'Текст',
      'selected': 'обрано',
      'Export': 'Експортувати',
      'Audience': 'Аудиторія', 
      'Messages status': 'Статус повідомлень',
      'Reset': 'Скинути',
      'Cases': 'Наративи',
      'Case': 'Наратив',
      'Save as case': 'Зберегти як наратив',
      'Create case': 'Створити наратив',
      'Unread': 'Непрочитані',
      'Status': 'Cтатус',
      'Choose country': 'Оберіть країну',
      'Source group': 'Група джерел',
      'Enter your query above. For help with search syntax, see our ': 'Введіть свій запит вище. Детальніше про синтаксис пошуку читайте в нашій',
      'search guide': 'довідці',
      'See our ': 'Детальніше читайте в нашій',
      'For advanced AI search': 'Щоб використати просунутий AI-пошук',
      'create a case': 'створіть наратив',
      'Topic discovery': 'Дослідження тем',
      'Actor groups': 'Групи джерел',
      'Actor group': 'Групa джерел',
      'Actors': 'Джерела',
      'Actor': 'Джерело',
      'Are you sure you want to copy these messages from a narrative?': 'Ви впевнені, що хочете скопіювати ці повідомлення з наративу?',
      'Positive': 'Позитивний',
      'Neutral': 'Нейтральний',
      'Negative': 'Негативний',
      'Sentiment': 'Сентимент',
      'Sentiment score': 'Сентимент',
      'You must select a native language for multilanguage search': 'Ви повинні вибрати рідну мову для багатомовного пошуку',
      'Select native language': 'Виберіть рідну мову',
      'Multilanguage search': 'Багатомовний пошук',
      'Content types': 'Тип контенту',
      'Post': 'Пост',
      'Article': 'Стаття',
      'Comment': 'Коментар',
      'Video': 'Відео',
      'Repost': 'Репост',
      'Ad': 'Реклама',
      'Social media': 'Соціальні мережі',
      'Source followers count': 'Кількість підписників',
      'Average post reach': 'Середнє охоплення публікації',
      'Engagement rate': 'Коефіцієнт залучення',
      'WEB': 'Веб',
      'Actor platform': 'Платформа',
      'Actor origin country': 'Країна походження',
      'Actor main language': 'Основна мова джерела',
      'The political compass is based on expert analysis of the actor content': 'Політичний компас базується на експертному аналізі змісту джерела',
      'Ownership / Holding': 'Власність / Холдинг',
      'Actor state affiliation': "Зв’язок з державою",
      'Proof': 'Доказ',
      'Actor involvement in disinformation campaigns': 'Залучення джерела до кампаній з дезінформації',
      'Identified narratives': 'Ідентифіковані наративи',
      'Linked actors: inbound': 'Пов’язані джерела: вхідні',
      'Linked actors: outbound': "Пов’язані джерела: вихідні",
      'Link': 'Посилання',
      'Location': 'Місцезнаходження',
      'Followers': 'Підписники',
      'Number of links': 'Кількість посилань',
      'Similarity': 'Схожістю',
      'Similarity1': 'Схожість',
      'Similarity threshold': 'Поріг схожості',
      'You can\'t set similarity threshold and do not provide any examples': 'Ви не можете встановити поріг схожості і не надати жодних прикладів',
      'Monthly audience': 'Місячна аудиторія',
      'Average video reach': 'Середнє охоплення відео',
      'Links': 'Звʼязки',
      'Engagement / views': 'Залучення / перегляди',
      'Engagement / followers': 'Залученість / аудиторія',
      'Average post engagement': 'Середнє залучення публікації',
      'Cases where the content from the current actor is tracked': 'Наративи, де було знайдено контент з поточного джерела',
      'Threats where the content from the current actor is tracked': 'Загрози, де було знайдено контент з поточного джерела',
      'Actors that generated the most reposts, mentions and comments to the current actor': 'Джерела, які згенерували найбільше репостів, згадувань та коментарів до поточного джерела',
      'Actors which were the most reposted, mentioned and commented by the current actor': 'Джерела, які найчастіше репостило, згадувало або коментувало поточне джерело',
      'Platforms': 'Платформи',
      'entitiesNoData': 'Жодної {{val}} не згадано в цьому наративі',
      'organizations': 'організації',
      'locations': 'локації',
      'persons': 'персони',
      'Organizations': 'Організації',
      'Locations': 'Локації',
      'Persons': 'Персони',
      'Most mentioned entities': 'Найбільш згадувані сутності',
      'Sentiments': 'Сентименти',
      'Evolution': 'Еволюція',
      'Matched keywords': 'Відповідні ключові слова',
      'Publication date': 'Дата публікації',
      'Highlights': 'Виділення',
      'Identifier': 'Ідентифікатор',
      'Page not found...': 'Сторінку не знайдено...',
      'Authentication error': 'Помилка аутентифікації',
      'Load More': 'Завантажити ще',
      'Inbox': 'Вхідні',
      'Reported': 'Зарепорчені',
      'Tag': 'Тег',
      'Tags': 'Теги',
      'Data leak': 'Витік даних',
      'Coordinated online attack': 'Скоординована онлайн-атака',
      'Coordinated offline activity': 'Скоординована офлайн-діяльність',
      'Malign narrative': 'Зловмисний наратив',
      'Pending': 'Модерація',
      'Accepted': 'Прийнято',
      'Declined': 'Відхилено',
      'Threat': 'Загроза',
      'Threats': 'Загрози',
      'Share as a threat': 'Поділитись загрозою',
      'Share as an instance': 'Поділитись інформаційним епізодом',
      "You are about to share your case as a threat. Once submitted, it will become visible to other users of our system and you won’t be able to edit it anymore. Please, double-check that the content of the case and its settings are accurate.":
        'Ви збираєтесь поділитись цим наративом як загрозою. Після збереження, загроза стане доступною для інших користувачів нашої системи. Її більше не можна буде відредагувати. Будь ласка, переконайтесь що весь контент в наративі і його налаштування - вірні.',
      'The case does not meet requirements for sharing': 'Наратив не відповідає вимогам для поширення',
      "This case can’t be shared as a threat because it doesn't meet the necessary criteria. Please update the case according to the following requirements:":
        "Ми не можемо поширити цей наратив як загрозу, оскільки він не відповідає необхідним критеріям. Будь ласка, оновіть наратив відповідно до таких вимог:",
      'Description Required: ': 'Потрібний опис: ',
      'Please provide a description for this case.': 'Будь ласка, надайте опис цього наративу.',
      'Minimum 3 Confirmed Messages: ': 'Мінімум 3 підтверджених повідомлень: ',
      'To share a case as a threat, it should contain at least 3 confirmed messages.':
        'Щоб поділитися наративом як загрозою, він повинен містити принаймні 3 затверджених повідомлень.',
      "Once these requirements are met, you can click the 'Share as a threat' button again, and your case will be shared successfully.":
        "Після виконання цих вимог ви можете ще раз натиснути кнопку «Поділитися загрозою», і ваш наратив буде успішно поширений.",
      "Once these requirements are met, you can click the 'Share as an instance' button again, and your case will be shared successfully.":
        "Після виконання цих вимог ви можете ще раз натиснути кнопку «Поділитись інформаційним епізодом», і ваш наратив буде успішно поширений.",
      'All Messages Confirmation: ': 'Підтвердження всіх повідомлень: ',
      'Ensure that all messages in the case are either confirmed or deleted.': 'Переконайтеся, що всі повідомлення у наративі підтверджені або видалені.',
      'Save as a case': 'Зберегти як наратив',
      'Are you sure you want to save the threat as a case?': 'Ви впевнені, що хочете зберегти загрозу як наратив?',
      'Delete the threat': 'Видалити загрозу',
      'Are you sure you want to delete the threat?': 'Ви впевнені, що хочете видалити загрозу?',
      'Edit the threat': 'Редагування загрози',
      'Edit the instance': 'Редагування інформаційного епізоду',
      'Politics': 'Політика',
      'Economy and finance': 'Економіка і фінанси',
      'Public companies': 'Публічні компанії',
      'Elections': 'Вибори',
      'Defence': 'Оборона',
      'Cyber': 'Кібер',
      'European Integration': 'Європейська інтеграція',
      'Ukraine <> NATO': 'Україна <> НАТО',
      'Healthcare': "Охорона здоров'я",
      'Social/cultural': 'Суспільство/культура',
      'Decolonization': 'Деколонізація',
      'Energy Independence': 'Енергетична незалежність',
      'Anti-corruption': 'Анти-корупція',
      'Ecology': 'Екологія',
      'Reconsruction of Ukraine': 'Відновлення України',
      'Business & investments': 'Бізнес & інвестиції',
      'Public services': 'Державні послуги',
      'Volunteers': 'Волонтери',
      'National minorities': 'Національні меншини',
      'Collaborants': 'Колаборанти',
      'Local Governance': 'Місцеве самоврядування',
      'Constitutional State': 'Правова держава',
      'Defense and Security': 'Безпека та оборона',
      'Weapon supply': 'Постачання зброї',
      'Ukraine <> Partners': 'Україна <> Партнери',
      'Reforms': 'Реформи',
      'Region-specific': 'Регіональні',
      'Russian narratives': 'Російські наративи',
      'Mobilization': 'Мобілізація',
      'Close': 'Закрити',
      'Favorite messages': 'Обрані повідомлення',
      'Show only favorite': 'Показати тільки обране',
      'Moderation': 'Модерація',
      'Delete from favorites': 'Видалити з обраного',
      'Add to favorites': 'Додати в обране',
      'Search score': 'Релевантність',
      'Threat types': 'Типи загроз',
      'Threat subjects': "Суб'єкти загрози",
      'Save messages to a case': 'Зберегти повідомлення у наративі',
      'Case name': 'Назва наративу', 
      'Select a case': 'Оберіть наратив',
      'Create a new case': 'Створити новий наратив',
      'Add to case': 'Додати до наративу',
      "Choose a case from the provided options or begin typing in the search bar to find and select existing cases. You also can create a new empty case by typing its name in the search bar and selecting 'Create a new case '<your case name>''": "Оберіть наратив із запропонованих варіантів або почніть вводити текст у полі пошуку, щоб знайти та вибрати існуючі наративи. Ви також можете створити новий порожній наратив, ввівши його назву в поле пошуку та вибравши 'Створити новий наратив '<назва вашого наративу>''",
      "Start typing a case name to add messages to an existing one or create a new case by typing its name and pressing the 'Create New Case' button.": "Почніть вводити назву наративу, щоб додати повідомлення до існуючого, або створіть новий наратив, ввівши його назву та натиснувши кнопку «Створити новий наратив»",      
      'Summary': 'Підсумки',
      'Original source': 'Першоджерело',
      'Message distribution': 'Розподіл повідомлень',
      'Start typing an existing case name or create a new one': 'Почніть вводити наявну назву наративу або створіть новий',
      'Suggested case type': 'Тип наративу',
      "What's going on here": 'Що тут відбувається',
      'A case that automatically collects all data for a certain topic based on your search query. Can contain both boolean and AI search. Is updated every 30 minutes. Supports Stories creation.' : 'Автоматично збирає повідомлення за вашим пошуковим запитом. Підтримує AI-запити. Оновлюється кожні 30 хвилин. Підтримується створення Сюжетів.',
      'An empty case to which you can manually add messages from search and other cases.': 'Порожній наратив, в який можна збирати повідомлення з пошуку та інших наративів.',
      'An empty case to which you can manually add messages from search and other cases. Changing the type to “Manual” case type will reset your search settings.': 'Порожній наратив, в який можна збирати повідомлення з пошуку та інших наративів. Зміна типу на "Ручний" призведе до скидання налаштувань пошуку.',
      'Manual': 'Ручний',
      'Something went wrong. Please, try again...': 'Щось пішло не так. Будь ласка, спробуйте ще раз...',
      'Disable filters': 'Відключити фільтри',
      'Read/Unread': 'Прочитані/Непрочитані',
      'Subjects': "Суб'єкти",
      'Topic': "Тема",
      'Show only...': 'Показати лише...',
      'Show only read': "Показати лише прочитані",
      'Show only unread': 'Показати лише непрочитані',
      'At least one filter must be present: keywords, examples, sources or source groups': 'Має бути принаймні один фільтр: ключові слова, приклади, джерела або групи джерел',
      'To create a Narrative, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.': 'Щоб створити Наратив, ви повинні виконати принаймні одну з наступних дій: додати ключові слова, додати приклади, вибрати принаймні одне джерело або групу джерел.',
      'To create an instance, you must provide at least one example': 'Щоб створити Наратив, ви повинні додати принаймні один приклад',
      'To save the changes, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.': 'Щоб зберегти зміни, ви повинні виконати принаймні одну з наступних дій: додати ключові слова, додати приклади, вибрати принаймні одне джерело або групу джерел.',
      'Share link': 'Поділитися посиланням',
      'Export PDF': 'Експортувати PDF',
      'Export settings': 'Налаштування експорту',
      'Validity period': 'Термін дії',
      'Make sure this link stops working after certain period of time': 'Переконайтеся, що це посилання перестане працювати через певний час',
      'Select a link duration period': 'Виберіть період тривалості посилання',
      'Two weeks': 'Два тижні',
      'Three Months': 'Три місяці',
      'Set password for the share link': 'Оберіть пароль, щоб поділитися посиланням',
      'Copy the link to the report': 'Скопіювати посилання на звіт',
      'Select duration...': 'Виберіть тривалість...',
      'Copied': 'Скопійовано',
      'View details': 'Докладніше',
      'Countries': 'Країни',
      'Verification': 'Верифікація',
      'Not verified': 'Не верифіковані',
      'Other': 'Інші',
      'Breakdowns': 'Розподілення',
      'Specific groups': 'Специфічні групи',
      'State-affiliated actors': 'Джерела повʼязані з державою',
      'Compromised actors': 'Скомпрометовані джерела',
      'Total number': 'Загальна кількість',
      'Split by type': 'Поділ за типом',
      'Split by affiliation country': 'Поділ за країною афіліації',
      'Top state-affiliated actors': 'Топ джерел повʼязаних з державою',
      'Top compromised actors': 'Топ скомпрометованих джерел',
      'Total audience': 'Загальна аудиторія',
      'Audience size': 'Розмір аудиторії',
      'Very small (< 1k)': 'Дуже маленька (< 1k)',
      'Small (1k - 10k)': 'Маленька (1k - 10k)',
      'Medium (10k - 100k)': 'Середня (10k - 100k)',
      'Big (100k - 500k)': 'Велика (100k - 500k)',
      'Very big (> 500k)': 'Дуже велика (> 500k)',
      'Total actors': 'Усього джерел',
      'Showing results for Similar message search': 'Показані результати для пошуку схожих повідомлень',
      'Multilingual search': 'Мультимовний пошук',
      'Multilingual search text': 'Автоматично шукати релевантні повідомлення іншими мовами',
      'Incident': 'Інцидент',
      'A case that automatically collects all data for a certain topic based on provided exampels and similarity threshold. Is updated every 30 minutes.': 'Наратив, який автоматично збирає всі дані для певної теми на основі наданих прикладів і порогу схожості. Оновлюється кожні 30 хвилин.',
      'A case that automatically collects all data for a certain topic based on provided exampels and similarity threshold. Is updated every 30 minutes. Changing the type "Narrative" to “Incident” case type will reset your search settings.': 'Наратив, який автоматично збирає всі дані для певної теми на основі наданих прикладів і порогу схожості. Оновлюється кожні 30 хвилин. Зміна типу "Наратив" на тип "Інцидент" призведе до скидання налаштувань пошуку.',
      'More results, less accuracy': 'Більше результатів, менша точність',
      'Less results, more accuracy': 'Менше результатів, вища точність',
      'Ukraine': 'Україна',
      'Russia': 'Росія',
      'Kherson region': 'Херсонська область',
      'Zaporizhya region': 'Запорізька область',
      'Donetsk region': 'Донецька область',
      'Luhansk region': 'Луганська область',
      'Mykolayiv region': 'Миколаївська область',
      'Kharkiv region': 'Харківська область',
      'Crimea': 'Крим',
      'ToT': 'Тимчасово окуповані території',
      'Moldova': 'Молдова',
      'Debunk': 'Викриття',
      'Case type': 'Тип наративу',
      'GEO Scope': 'ГЕО область',
      'Counter-narrative': 'Контр-наратив',
      'Counter-incident': 'Контр-інцидент',
      'Counter-campaign': 'Контр-кампанія',
      'Find similar messages': 'Знайти схожі повідомлення',
      'Case types': 'Типи наративу',
      'Detect Automatically': 'Визначити автоматично',
      'Instances': 'Інформаційні епізоди',
      'Instance type': 'Тип інформ епізоду',
      'Instance topic': 'Тема інформ епізоду',
      'No bots identified': 'Ботів не виявлено',
      'No affiliated actors identified': 'Афілійованих джерел не виявлено',
      'No compromised actors identified': 'Скомпрометованих джерел не виявлено',
      'Sanctions': 'Санкції',
      'Disinformation': 'Дезінформація',
      'Indicators of Compromise (IoC)': 'Скомпрометованість',
      'Proof of IoC': 'Доказ скомпрометованості',
      'Top tags by number of posts': 'Топ тегів за кількістю повідомлень',
      'Top actors by number of posts': 'Топ джерел за кількістю повідомлень',
      'Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries': 'Джерела зазначені в офіційному списку санкцій ЄС (і країн-членів), США, Великобританії та інших країн',
      'Sources are frequently sharing disinformative content, which was autodetected by Osavul AI': 'Джерела, які часто діляться дезінформативним контентом, який було автоматично виявлено Osavul AI',
      'The source is reported to be state affiliated by an authoritative organization (government or non-government)': 'Авторитетна організація (урядова чи неурядова) повідомляє, що дане джерело повʼязане з державою',
      'AI-powered search': 'Пошук з використанням ШІ',
      'AI-powered search hint': 'Застосувати штучний інтелект для пошуку релевантних повідомлень базуючись на Вашому запиті',
      'Relevance': 'Релевантність',
      'See all messages': 'Подивитись усі повідомлення',
      'Last messages': 'Останні повідомлення',
      'Report an instance': 'Повідомити про інформаційний епізод',
      'Show advanced options': 'Показати просунуті опції',
      'Example of a post, article or message about the instance': 'Приклад пост, статті, або повідомлення про інформаційний епізод',
      "Give your instance a self-explanatory name. Ideally, it should be a complete sentence answering the 'W' questions": "Дайте вашому епізоду назву, зрозумілу без додаткового контексту. В ідеалі, це має бути повне речення.",
      "The instance will include messages starting from this day": "Інформаційний епізод включатиме повідомлення починаючи з цієї дати",
      "The instance will include messages till this day. Leave it open to keep instance updating.": "Інформаційний епізод включатиме повідомлення до цієї дати. Залиште порожнім, щоб епізод  продовжував оновлюватися.",
      "This example will be used to find similar content for the instance. Copy paste here post, article or message that describes and instance. Try to insert only relevant information (e.g. only relevant paragraph from the long article). When available - use more than 1 example.": "Інформацію у полі нижче буде використано для пошуку іншого вмісту, подібного до зафіксованого Вами інформаційного епізоду. Скопіюйте сюди частину публікації, статті чи повідомлення, які описують інформаційний епізод. Намагайтеся вставляти лише найбільш релевантну частину тексту (наприклад, лише відповідний абзац із довгої статті). За необхідності – Ви можете надати більше ніж 1 приклад для зафіксованого епізоду.",
      "Select all regions to which the instance is relevant. Choose Ukraine for national-level stories.": "Виберіть усі регіони, для яких релевантний цей інформаційний епізод. Виберіть Україну для історій національного рівня.",
      "Select a type that descries your instance best": "Виберіть тип, який найкраще описує ваш епізод",
      "Select one or more topics relevant to the instance": "Виберіть одну чи декілька тем, які відповідають епізоду",
      "Use this form to report an instance to Osavul.": "Використайте цю форму, щоб повідомити в Osavul про інформаційний епізод",
      "The instance can be an information threat, fake news, physical event, example of good communication, statement of a spokesperson, example of an online discussion, etc.": "Інформаційним епізодом може бути загроза, фейкова новина, фізична подія, приклад гарної комунікації, заява спікера, активна онлайн-дискусія, тощо.",
      "For the reported instance Osavul AI will collect all the similar messages from Web, Social and Telegram and measure its trend and impact.": "Для кожного епізоду Osavul AI збере всі подібні повідомлення з Інтернету, соціальних мереж і Telegram і виміряє їх тренд та вплив.",
      "Once instance is populated, the admin team will be notified. It will review it and consider while setting priorities for strategic communications.": "Коли епізод буде наповнено, команда адміністраторів отримає сповіщення. Вона розгляне його та врахує під час визначення пріоритетів для стратегічних комунікацій.",
      "The instance will be also visible on the public dashboard.": "Інформаційний епізод також буде видно на загальнодоступних дашбордах.",
      'created by ': 'створено ',
      'created by1 ': 'автор ',
      'First message': 'Перше повідомлення',
      'Last message': 'Останнє повідомлення',
      'Create stories from case': 'Створити сюжети з наративу',
      'Candidates min threshold': 'Мінімальний поріг для кандидатів',
      'Use max grouping distance': 'Використовувати максимальну відстань групування',
      'Grouping max distance': 'Максимальна відстань групування',
      'Showing search results for the actor:': 'Результати пошуку для актора:',
      'Total stories': 'Усього сюжетів',
      'Add story to case': 'Додати сюжет до наративу',
      'Add story to threat': 'Додати сюжет до загрози',
      'Add to threat': 'Додати до загрози',
      'Tops': 'Топи',
      'Total actors:': 'Усього джерел:',
      'Edit history': 'Історія редагування',
      'Case edit history': 'Історія редагування наративу',
      'Changes': 'Зміни',
      'updated by': 'змінено',
      'Users': 'Користувачі',
      'User': 'Користувач',
      'Create user': 'Створити користувача',
      'Are you sure you want to delete a users?': 'Ви впевнені що хочете видалити наратив?',
      'UNDERPRIVILEGED': 'Непривілегований',
      'STANDARD': 'Стандартний',
      'MODERATOR': 'Модератор',
      'Underprivileged': 'Непривілегований',
      'Standard': 'Стандартний',
      'Moderator1': 'Модератор',
      'Admin': 'Адміністратор',
      'Regular': 'Звичайний',
      'First name': "Ім'я",
      'Last name': 'Прізвище',
      'User type': 'Тип користувача',
      'Cases moderation': 'Модерація наративів',
      'User with this email already exist': 'Користувач з цією електронною адресою вже існує',
      'Create stories': 'Створити сюжети',
      'Please, wait till our AI builds stories for this case. This takes 5 mins on average.': 'Будь ласка. зачекайте, поки наш ШІ побудує сюжети для цього наратива. В середньому, це займає 5 хв.',
      'Edit actor group': 'Редагувати группу джерел',
      'Linked actors': 'Пов’язані джерела',
      'Inbound': 'Вхідні',
      'Outbound': 'Вихідні',
      "Message relevant": "Повідомлення актуальне",
      "Message irrelevant": "Повідомлення нерелевантно",
      'Create link': 'Створити посилання',
      'Edit metadata': 'Редагувати метадані',
      'Not selected': 'Не обрано',
      'You need to select a start date to be able to save the search results as a case': " Оберіть початкову дату, щоб мати можливість зберегти результати пошуку як наратив",
      'Narrative not found': 'Сторінку не знайдено',
      'Actor background': 'Профіль джерела',
      'Source not found': 'Джерело не знайдено',
      'Report not found': 'Репорт не знайдено',
      'Group not found': 'Групу не знайдено',
      'Top bots': 'Топ ботів',
      'Inauthentic account type': 'Тип неавтентичного акаунта',
      'Special tags': 'Спеціальні теги',
      'System language': 'Мова системи',
      'Show only bots': 'Показувати лише ботів',
      'Query {{raw_query}} is invalid at position {{pos}} - {{query_copy}}': 'Частина запиту некоректна {{query_copy}}',
      '{{class_name}} does not support {{token_name}} as a token': '{{class_name}} не підтримує токен {{token_name}}',
      'Cannot extract token {{token_name]} from string \'{{string}}\'': 'Некоректний токен {{token_name]}',
      'Number of operands in group less or equal than min operators value ({{min_or_operands}})': 'Кількість операндів в групі з мінімальною кількістю операндів менша або дорівнює значенню ({{min_or_operands}})',
      'Token {{sub_token_string}} is invalid inside token {{string}}': 'Токен {{sub_token_string}} невалідний в токені {{string}}',
      'Token {{string}} cannot contain one or less tokens inside': 'Токен {{string}} не може містити один чи менше токенів всередині',
      'Unbalanced parenthesis in query: {{string_query}} in position {{pos}}': 'Некоректна кількість дужок',
      'Unbalanced parenthesis in query: {{string_query}}': 'Некоректна кількість дужок',
      '{{class_name}} does not support {{sub_token_name}} as a sub token': '{{class_name}} не підтримує підтокен {{sub_token_name}}',
      'Invalid first token: {{token}}': 'Некоректний перший токен {{token}}',
      'Invalid last token: {{token}}': 'Некоректний останній токен {{token}}',
      'Invalid token {{token}} after {{prev_token}}': 'Токен {{token}} не може йти після токену {{prev_token}}',
      'Show only inauthentic accounts': 'Показувати лише неавтентичні акаунти',
      'Bot': 'Бот',
      'Reason': 'Причина',
      'Indicators of Compromise (IoC)1': 'Індикатори скомпрометованості',
      'General infomation': 'Загальна інформація',
      'Threat type': 'Тип загрози',
      'Inauthentic accounts': 'Неавтентичні акаунти',
      'Top inauthentic accounts': 'Топ неавтентичних акаунтів',
      'No inauthentic accounts identified': 'Неавтентичних акаунтів не виявлено',
      'You can report both constructive and destructive information instances. A good example of a constructive information instance could be an article or a statement released about a certain event that mitigates the adverse effects of a specific issue or a prioritized strategic communications topic or narrative. This could also include a portion of a discussion you observe anywhere on the web or social media. Destructive information instances could be threats, fake news, hostile events, manipulative narratives, etc.': 'Ви можете повідомляти як про конструктивні, так і про деструктивні інформаційні епізоди. Хорошим прикладом конструктивного інформаційного епізоду може бути стаття чи заява про певну подію, яка нівелює негативний контекст одної з пріоритетних стратегічних тем чи наративів доступних для перегляду в дешбордах Osavul. Це також може включати частину дискусії, яку ви спостерігаєте в Інтернеті чи соціальних мережах. Прикладами деструктивного інформаційного епізоду можуть бути фейкові новини, загрозливі заяви чи події, маніпулятивні наративи тощо.',
      'Upon reporting any of these instances, Osavul AI will gather similar messages from the web, social media, and Telegram, and evaluate the trends and impact of the reported instance. This information will be shared with the admin team, which includes specialized civil society organizations and analysts, to validate the instance and make an assessment from the perspective of strategic communications priorities.': 'Після повідомлення про будь-який такий інформаційний епізод, Osavul AI збере подібні повідомлення з Інтернету, соціальних мереж і Telegram і оцінить тенденції та інформаційний вплив епізоду, про який Ви повідомили. Ця інформацію потрапляє до команди адміністраторів, до складу якої входять спеціалізовані організації громадянського суспільства та аналітики, які перевіряють повідомлення про нові епізоди і оцінюють їх з точки зору пріоритетів стратегічних комунікацій.',
      'The reported instance with the appropriate machine score will be listed on the StratCom dashboards for visibility. However, the instance can only make it to the priority list of topics and/or narratives after an assessment by the network of StratCom professionals, researchers, and analysts.': 'Повідомлений епізод із відповідною машинною оцінкою буде опубліковано на дешбордах Osavul для забезпечення прозорості процесу. Однак епізод може потрапити до пріоритетного списку тем та/або наративів лише після професійної оцінки аналітиків, дослідників та робочих груп зі спеціалізацією у стратегічних комунікаціях.',
      'Fake/Disinformation1': '(Деструктив) Фейк/дезінформація',
      'Manipulation/Misinformation1': '(Деструктив) Маніпуляція/місінформація',
      'Disproof/Debunk1': '(Конструктив) Спростовування/розвінчення',
      'Strategic narrative/message (reporting)1': '(Конструктив) Позитивний наратив/стратегічний наратив (звітування)',
      'Unclassified threat1': 'Некласифікований епізод',
      'Other_1': 'Інше',
      'Change password': 'Змінити пароль',
      'New password': 'Новий пароль',
      'Repeat password': 'Повторіть пароль',
      'Profile': 'Профайл',
      'Settings': 'Налаштування',
      'Security': 'Безпека',
      'Use two-step verification': 'Використовувати двоетапну верифікацію',
      'Enable 2FA': 'Увімкнути ДФА',
      'You will need an authenticator mobile app to complete this process, such as one of the following:': 'Щоб завершити цей процес, вам знадобиться мобільний додаток автентифікатора, наприклад один із наведених нижче:',
      'Scan the QR code with your authenticator': 'Відскануйте QR-код за допомогою автентифікатора',
      "If you can't scan the code you can enter this secret key into your authentication app": "Якщо ви не можете відсканувати код, ви можете ввести цей секретний ключ у свою програму автентифікації",
      'After scanning the QR code above enter the six-digit code generated by your authenticator': 'Після сканування наведеного вище QR-коду введіть шестизначний код, згенерований вашим аутентифікатором',
      'Enable two-step verification': 'Увімкнути двоетапну верифікацію',
      'Enable': 'Увімкнути',
      'Incorrect one time password': 'Неправильний одноразовий пароль',
      'Disable 2FA': 'Вимкнути ДФА',
      'To disable 2FA enter the six-digit code generated by your authenticator': 'Щоб вимкнути ДФА, введіть шестизначний код, згенерований вашим автентифікатором',
      'Disable two-step verification': 'Вимкнути двоетапну верифікацію',
      'Disable': 'Вимкнути',
      'One-time password': 'Одноразовий пароль',
      '2FA is already disabled': 'ДФА вже вимкнено',
      'Select all affiliation countries': 'Обрати усі країни афіліацій',
      'Is public': 'Публічна?',
      'Public name EN': 'Публічне ім\'я англійською',
      'Public name UK': 'Публічне ім\'я українською',
      'Public description EN': 'Публічний опис англійською',
      'Public description UK': 'Публічний опис українською',
      'Impact assessment': 'Оцінка впливу',
      'Show less': 'Показати менше',
      'Show more': 'Показати все',
      'Opinions': 'Точки зору',
      'Key opinions': 'Ключові точки зору',
      'Positive opinion': 'Позитивна точка зору',
      'Neutral opinion': 'Нейтральна точка зору',
      'Negative opinion': 'Негативна точка зору',
      'The case failed to be collected. Please contact your workspace administrator.': 'Не вдалося зібрати наратив. Будь ласка, зверніться до адміністратора свого воркспейсу.',
      'Sentiment towards media product': 'Сентимент стосовно інформаційного продукту',
      'Sentiment towards a topic or narrative': 'Сентимент стосовно теми чи наративу',
      'Sentiment towards person': 'Сентимент стосовно персони',
      'Sentiment towards organization': 'Сентимент стосовно організації',
      'Sentiment towards country': 'Сентимент стосовно країни',
      'Write a name of a country. E.g.: Russia, USA': 'Напишіть назву країни. Наприклад: Росія, США',
      'Write a custom keyword or phrase to measure sentiment towards. E.g.: "I love my country"': 'Напишіть індивідуальне ключове слово або фразу для вимірювання сентименту. Наприклад: "Я люблю свою країну"',
      'Custom sentiment option': 'Індивідуальний варіант сентименту',
      'A case to analyze public reaction to a particular media product.': 'Кейс для аналізу реакції аудиторії на конкретний інформаційний продукт.',
      'Link to media product': 'Посилання на інформаційний продукт',
      'Sentiment analysis': 'Аналіз сентименту',
      'Topic/Narrative': 'Тема/Наратив',
      'Person': 'Персона',
      'Organization': 'Організація',
      'Please wait, the case is being collected. This can take up to 30 minutes depending on the amount of data to be collected.': 'Зачекайте, наратив збирається. Це може зайняти до 30 хвилин залежно від обсягу даних, які потрібно зібрати.',
      'Give a short name of a topic, narrative or opinion to measure reaction to. E.g.: ceasefire in Gaza, Covid vaccines can cause death, sending Western troops to Ukraine.': 'Дайте коротку назву теми, наративу чи думки, щоб виміряти реакцію. Наприклад: припинення вогню в Газі, вакцини від Covid можуть спричинити смерть, відправка західних військ в Україну.',
      'Write a name and minimum necessary content that helps identify a person in content. E.g.: Vladimir Putin, leader of PWC Wagner': 'Напишіть ім’я та мінімально необхідний вміст, який допоможе ідентифікувати особу в контенті. Наприклад: Володимир Путін, лідер ПВК Вагнер',
      'Write a name of an organization. E.g.: UN, EU': 'Напишіть назву організації. Наприклад: ООН, ЄС',
      'Searching content in our database. Stage 1': 'Пошук контенту у нашій базі даних. Етап 1',
      'Searching content in our database. Stage 2': 'Пошук контенту у нашій базі даних. Етап 2',
      'Searching content in our database. Stage 3': 'Пошук контенту у нашій базі даних. Етап 3',
      'Fetching data. Stage 1': 'Отримання даних. Етап 1',
      'Fetching data. Stage 2': 'Отримання даних. Етап 2',
      'Fetching data. Stage 3': 'Отримання даних. Етап 3',
      'Processing data. Stage 1': 'Обробка даних. Етап 1',
      'Processing data. Stage 2': 'Обробка даних. Етап 2',
      'Processing data. Stage 3': 'Обробка даних. Етап 3',
      'Values': 'Значення',
      'Points': 'Точки',
      'Download as PNG': 'Завантажити як PNG',
      'Download as SVG': 'Завантажити як SVG',
      'Chart view': 'Вигляд графіка',
      'By countries': 'По країнах',
      'By platforms': 'По платформах',
      'By stories': 'По сюжетах',
      'By messages': 'По повідомленням',
      'Messages by countries': 'Повідомлення по країнах',
      'Messages by platforms': 'Повідомлення по платформах',
      'Dashboards': 'Графіки',
      'You have no available dashboards yet': 'У вас ще немає доступних графіків',
      'Link to content1': 'Посилання на медіапродукт',
      'Add to actor group': 'Додати до групи акторів',
      "Start typing an actor group name to add actors to an existing one or create a new group by typing its name and pressing the 'Create New Group' button.": "Почніть вводити назву групи акторів, щоб додати повідомлення до існуючої, або створіть нову групу, ввівши її назву та натиснувши кнопку «Створити нову групу»",
      'Save actors to an actor group': 'Зберігти акторів у групу акторів',
      'Actor group name': 'Назва групи акторів',
      'Start typing an existing group name or create a new one': 'Почніть вводити назву групи акторів або створіть нову',
      'Create a new actor group': 'Створити нову групу акторів',
      'Frontline report': 'Фронтові зведення',
      'Reform of the Armed Forces': 'Реформа ЗСУ',
      'Corruption in the Armed Forces': 'Корупція ЗСУ',
      'Italian': 'Італійська',
      'Hindi': 'Хінді',
      'Thai': 'Тайська',
      'Turkish': 'Турецька',
      'Danish': 'Датська',
      'Bengali': 'Бенгальська',
      'Kurdish': 'Курдська',
      'Belarusian': 'Білоруська',
      'Copy a group to another workspace': 'Копіювання групи в інший воркспейс',
      'Are you sure you want to copy these sources from a group?': 'Ви впевнені, що хочете скопіювати ці сорси з групи?',
      'Messages distribution: Countries': 'Розподіл повідомлень: Країни',
      'Messages distribution: Platforms': 'Розподіл повідомлень: Платформи',
      'Messages distribution: Stories': 'Розподіл повідомлень: Сюжети',
      'Evolution: Messages': 'Еволюція: Повідомлення',
      'Evolution: Reactions': 'Еволюція: Реакції',
      'Evolution: Views': 'Еволюція: Перегляди',
      'Evolution: Manipulation index': 'Еволюція: Індекс маніпуляції',
      'Sentiment: Messages': 'Сентимент: Повідомлення',
      'Sentiment: Reactions': 'Сентимент: Реакції',
      'Sentiment: Views': 'Сентимент: Перегляди',
      'Actors breakdowns': 'Розподіл джерел',
      'Actors tops': 'Топи джерел',
      'Actors specific groups': 'Специфічні групи джерел',
      'Top actors': 'Топ джерел',
      'Top tags': 'Топ тегів',
      'Download PDF': 'Завантажити PDF',
      'PDF settings': 'Налаштування PDF',
      'Inauthentic': 'Неавтентичні',
      'Disinfo spreaders': 'Розповсюджувачі дезінформації',
      'Original posts': 'Оригінальні пости',
      'Reposts': 'Репости',
      'Media articles': 'Медіа-статті',
      'Comments': 'Коментарі',
      'Videos': 'Відео',
      'Ads': 'Реклама',
      'Chat messages': 'Повідомлення чату',
      'Likes': 'Вподобання',
      'Apply aspect based sentiment to case': 'Порахувати специфічний сентимент для наративу',
      'Create Stories': 'Створити сюжети',
      'Group messages into key topics.': 'Повідомлення обʼєднаються у ключові теми.',
      'Run Contextual Sentiment Analysis': 'Порахувати контекстний сентимент',
      'Calculate sentiment towards an entity or event.': 'Сентимент відносно персони, події чи теми.',
      'Messages distribution': 'Розподіл повідомлень',
      'Evolution: Percentage': 'Еволюція: Доля',
      'Choose context for sentiment analysis': 'Оберіть контекст для аналізу сентименту',
      'Peace talks': 'Мирні переговори',
      'Exports': 'Експорти',
      'export_status_pending': 'В очікуванні',
      'export_status_in_progress': 'В процесі',
      'export_status_completed': 'Завершено',
      'export_status_failed': 'Помилка',
      'export_type_case': 'Наратив',
      'export_output_format_csv': 'CSV',
      'export_output_format_jsonl': 'JSON',
      'export_output_format_xml': 'XML',
      'Download': 'Завантажити',
      'Object': 'Обʼєкт',
      'Output format': 'Формат виводу',
      'Created at': 'Дата створення',
      'Last downloaded at': 'Останнє завантаження',
      'Object ID': 'ID обʼєкта',
      'An export of': 'Експорт',
      'export_type_of_case': 'Наративу',
      'Merge': 'Обʼєднати',
      'Please select at least 2 stories to merge': 'Будь ласка, оберіть принаймні 2 сюжети для обʼєднання',
      'Merge stories': 'Обʼєднати сюжети',
      'Proof of affiliation (UK)': 'Доказ афіліації (UK)',
      'Proof of affiliation (EN)': 'Доказ афіліації (EN)',
      'Proof link': 'Посилання на доказ',
      'Compromised account': 'Скомпрометований акаунт',
      'Compromised account type': 'Тип скомпрометованого акаунта',
      'Proof of compromise (UK)': 'Доказ скомпрометованості (UK)',
      'Proof of compromise (EN)': 'Доказ скомпрометованості (EN)',
      'Select tags': 'Обрати теги',
      'No tags found': 'Теги не знайдено',
      'Please select country of affiliation': 'Будь ласка, оберіть країну афіліації',
      'Please select type of affiliation': 'Будь ласка, оберіть тип афіліації',
      'Please enter proof of affiliation (UK)': 'Будь ласка, введіть доказ афіліації (UK)',
      'Please enter proof of affiliation (EN)': 'Будь ласка, введіть доказ афіліації (EN)',
      'Please enter proof link': 'Будь ласка, введіть посилання на доказ',
      'Please select compromised account type': 'Будь ласка, оберіть тип скомпрометованого акаунта',
      'Please enter proof of compromise (UK)': 'Будь ласка, введіть доказ скомпрометованості (UK)',
      'Please enter proof of compromise (EN)': 'Будь ласка, введіть доказ скомпрометованості (EN)',
      'Summary: Actors': 'Підсумки: Джерела',
      'Summary: Publications': 'Підсумки: Публікації',
      'Summary: Views': 'Підсумки: Перегляди',
      'Summary: Reactions': 'Підсумки: Реакції',
      'Create a note': 'Створити нотатку',
      'Place a note on the top of the widget': 'Розташувати нотатку вгорі віджету',
      'Note': 'Нотатка',
      'Show full text': 'Показати повний текст',
      'Text length(characters)': 'Довжина тексту(символи)',
      'Show highlights': 'Показати виділення',
      'Agregation': 'Агрегація',
      'Breakdown': 'Розбивка',
      'Show Platforms': 'Показати Платформи',
      'Show Countries': 'Показати Країни',
      'Show Audience': 'Показати Аудиторію',
      'Show Verification': 'Показати Верифікацію',
      'Show Top actors': 'Показати Топ джерел',
      'Show Top tags': 'Показати Топ тегів',
      'Show State-affiliated actors': 'Показати Джерела повʼязані з державою',
      'Show Compromised actors': 'Показати Скомпрометовані джерела',
      'Show Inauthentic accounts': 'Показати Неавтентичні акаунти',
      'Number of actors': 'Кількість джерел',
      'Number of tags': 'Кількість тегів',
      'Top stories': 'Топ сюжетів',
      'Top messages': 'Топ повідомлень',
      'Report name': 'Назва звіту',
      'Please select at least one case': 'Будь ласка, оберіть принаймні один наратив',
      'No cases found': 'Наративи не знайдено',
      'Posts': 'Пости',
      'Rating': 'Рейтинг',
      'Opinion balance': 'Баланс думок',
      'Actor Types': 'Типи джерел',
      'Top actors by negative sentiment': 'Топ джерел за негативним сентиментом',
      'Top actors by positive sentiment': 'Топ джерел за позитивним сентиментом',
      'Top opinions': 'Топ думок',
      'Geography': 'Географія',
      'Collect comments to posts': 'Зібрати також коментарі до постів',
      'Top actors by audience': 'Топ джерел за аудиторією',
      'Mostly negative': 'Переважно негативний',
      'Mostly positive': 'Переважно позитивний',
      'Controversial': 'Контроверсійний',
      'Topic modeling': 'Моделювання теми',
      'Events only': 'Тільки події',
      'Opinions only': 'Тільки думки',
      'Events & opinions': 'Події та думки',
      'Choose entity for topic modeling analysis': 'Оберіть сутність для аналізу моделювання теми',
      'Topic modeling entity': 'Сутність для аналізу моделювання теми',
      'Edit opinion': 'Редагувати думку',
      'Edit event': 'Редагувати подію',
      'Are you sure you want to delete this opinion?': 'Ви впевнені, що хочете видалити цю думку?',
      'Are you sure you want to delete this event?': 'Ви впевнені, що хочете видалити цю подію?',
      'Yay! You have seen it all': 'Вітаємо! Ви переглянули все',
      'Please select at least 2 opinions to merge': 'Будь ласка, оберіть принаймні 2 думки для обʼєднання',
      'Please select at least 2 events to merge': 'Будь ласка, оберіть принаймні 2 події для обʼєднання',
      'Are you sure you want to delete this events?': 'Ви впевнені, що хочете видалити ці події?',
      'Last updated time:': 'Час останнього оновлення:',
      'Content analyzed:': 'Проаналізований контент:',
      'To save the changes, you must provide at least one keyword.': 'Для збереження змін, ви повинні вказати принаймні одне ключове слово.',
      'Fixed date range': 'Фіксований діапазон дат',
      'Yesterday': 'Вчора',
      'Yesterday - Today': 'Вчора - Сьогодні',
      'Last 7 days': 'Останні 7 днів',
      'Current week': 'Поточний тиждень',
      'Last week': 'Минулий тиждень',
      'Date range filter preset': 'Попередньо встановлений фільтр діапазону дат',
      'Date range': 'Діапазон дат',
      'Opinions have not been detected': 'Думок не виявлено',
      'Language for translation': 'Мова для перекладу',
      'Exit modal': 'Вийти з модалу',
      'Are you sure you want to exit?': 'Ви впевнені, що хочете вийти?',
      'Yes': 'Так',
      'There are no selected events': 'Не вибрано жодної події',
      'Copy to dashboard': 'Скопіювати у репорт',
      'Copy events to a dashboard': 'Скопіювати події у репорт',
      'Dashboard name': 'Назва репорту',
      'Start typing a dashboard name': 'Почніть вводити назву репорту',
      'Add default sorting': 'Додати сортування за замовчуванням',
      'Sorting order': 'Порядок сортування',
      'Ascending order (A-Z)': 'За зростанням (A-Z)',
      'Descending order (Z-A)': 'За спаданням (Z-A)',
      'Recency': 'Актуальність',
      'Event first seen': 'Сюжет вперше виявлено',
      'Event last update': 'Останнє оновлення сюжету',
      'Date filtering returns events updated in the selected period, with statistics based only on messages from that time;' : 'Фільтрація за датою повертає сюжети, оновлені за обраний період, зі статистикою, що базується лише на повідомленнях з цього часу;',
      'unfiltered events may include earlier or later data.': 'невідфільтровані сюжети можуть включати раніші або пізніші дані.',
      'All actors': 'Усі джерела',
      'Stats aggregation': 'Агрегація статистики',
      'Stats breakdown': 'Розбивка статистики',
      'Custom': 'Налаштований',
      'Sort by audience': 'Сортувати за аудиторією',
      'Sort by number of messages': 'Сортувати за кількістю повідомлень',
      'Widgets settings': 'Налаштування віджетів',
      'Summary panel': 'Панель підсумків',
      'Summary panel view': 'Вигляд панелі підсумків',
      'Event card': 'Карточка cюжету',
      'Event card settings': 'Налаштування карточки cюжету',
      'Opinion card': 'Карточка думки',
      'Opinion card settings': 'Налаштування карточки думки',
      'Sentiment bar': 'Сентимент бар',
      'Sentiment bar settings': 'Налаштування сентимент бару',
      'Geo widget': 'Гео віджет',
      'Top actors settings': 'Налаштування топ джерел',
      'Actor types': 'Типи джерел',
      'Evolution chart tabs'  : 'Вкладки графіка еволюції',
      'Select tabs': 'Обрати вкладки',
      'Evolution chart breakdowns': 'Розбивка графіка еволюції',
      'Select breakdowns': 'Обрати розбивку',
      'There are no selected items to add to the group': 'Не вибрано жодного елемента для додавання до групи',
      'Are you sure you want to delete the selected sources from the group?' : 'Ви впевнені, що хочете видалити вибрані джерела з групи?',
      'Confirm Deletion': 'Підтвердити видалення',
      'Copy to group': 'Скопіювати до групи',
      'Add sources': 'Додати джерела',
      'Edit group info': 'Редагувати інфо групи',
      'Top actors by number of messages': 'Топ джерел за кількістю повідомлень',
      'Messages count': 'Кількість повідомлень',
      'All {{messages}} messages on this page are selected.': 'Всі {{messages}} повідомлень на цій сторінці вибрані.',
      'Select all {{messages}} messages': 'Обрати усі {{messages}} повідомлень',
      "allPageMessagesSelected_zero": "Всі {{count}} повідомлень на цій сторінці вибрано.",
      "allPageMessagesSelected_one": "Всі {{count}} повідомлення на цій сторінці вибрано.",
      "allPageMessagesSelected_few": "Всі {{count}} повідомлення на цій сторінці вибрані.",
      "allPageMessagesSelected_many": "Всі {{count}} повідомлень на цій сторінці вибрано.",
      "allPageMessagesSelected_other": "Всі {{count}} повідомлень на цій сторінці вибрано.",
      'selectAllMessages_zero': 'Обрати усі {{count}} повідомлень',
      'selectAllMessages_one': 'Обрати {{count}} повідомлення',
      'selectAllMessages_few': 'Обрати {{count}} повідомлення',
      'selectAllMessages_many': 'Обрати {{count}} повідомлень',
      'selectAllMessages_other': 'Обрати {{count}} повідомлень',
      'allMessagesSelected_zero': 'Всі {{count}} повідомлень вибрано.',
      'allMessagesSelected_one': 'Всі {{count}} повідомлення вибрано.',
      'allMessagesSelected_few': 'Всі {{count}} повідомлення вибрані.',
      'allMessagesSelected_many': 'Всі {{count}} повідомлень вибрано.',
      'allMessagesSelected_other': 'Всі {{count}} повідомлень вибрано.',
      'This process may take a few minutes. Please wait before checking the changes.': 'Цей процес може зайняти кілька хвилин. Будь ласка, зачекайте, перш ніж перевіряти зміни.',
      'The process may take some time. Please wait a couple of minutes, then refresh the page to see the changes.': 'Процес може зайняти деякий час. Будь ласка, зачекайте кілька хвилин, а потім оновіть сторінку, щоб побачити зміни.',
      'You can either include or exclude options': 'Ви можете включати або виключати опції',
      'Exclude': 'Виключити',
      'Add actors': 'Додати джерела',
      'Paused': 'Призупинений',
      'Archived': 'Архівований',
      'Sampling': 'Вибірка',
      'We used sampling to analyze this data. A sample is a statistically significant representation of the dataset, suitable for AI analysis. The sampling rate indicates the part of the total dataset included in the analysis.': 'Ми використали вибірку для аналізу цих даних. Вибірка - це статистично значуще представлення набору даних, придатне для аналізу штучного інтелекту. Значення вибірки вказує на частину загального набору даних, включену в аналіз.',
      'Internal': 'Внутрішній',
      'Deactivated': 'Деактивований',
      'Trial': 'Пробний',
      'Free Access': 'Безкоштовний доступ',
      'Paid Client': 'Платний клієнт',
      'Paid Client UCBI': 'Платний клієнт UCBI',
      'Paid Client I3': 'Платний клієнт I3',
      'Favorites': 'Oбране',
      'Remove from favorites': 'Видалити з обраного',
      'private': 'Приватнe',
      'private_female': 'Приватнa',
      'private_male': 'Приватний',
      'public': 'Публічнe',
      'public_female': 'Публічнa',
      'public_male': 'Публічний',
      'sharable': 'Доступнe для поширення',
      'sharable_female': 'Доступнa для поширення',
      'sharable_male': 'Доступний для поширення',
      'Privacy settings': 'Налаштування приватності',
      'Workspaces': 'Воркспейси',
      'Feeds': 'Стрічки',
    },
  },
  ru: {
    translation: {

    }
  }
};
resources.ru.translation = resources.uk.translation

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    // debug: true,
    // lng: "ua", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    // interpolation: {
    //   escapeValue: false // react already safes from xss
    // }
    react: {
      bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      // useSuspense: true,
    }
  });

export default i18n;
