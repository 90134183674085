import { useEffect, useState, useContext, useCallback } from "react";
import * as d3 from 'd3';
import { format, set } from 'date-fns';
import { API } from "../../API";
import { ShareAPI } from "../../API";
import { useTranslation } from 'react-i18next';
import { ampli } from "../../ampli";
import { useCurrentUser } from "../../contexts/CurrentUser";
import { Link } from 'react-router-dom';
// import { BreakdownsContext } from "./contexts/BreakdownsContext";
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import { BreakdownsContainer } from "../BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";
import { decodeSourceName } from '../../utils/decodeURI';
import { ReactComponent as VerifiedTwitter } from '../../pages/SourcePage/assets/verifiedTwitter.svg';
import Tooltip from "../Tooltip/Tooltip";
import { cutString } from "../../utils/cutString";
import { ReactComponent as AnchorIcon } from '../../pages/SourcePage/assets/anchor.svg';
import { Paginator } from "../Paginator/Paginator";
import styles from '../../pages/NarrativePage/NarrativePage.module.scss';
import { WidgetContentModal } from "../WidgetContentModal/WidgetContentModal";
import { AddToNarrativeModal } from '../AddToNarrativeModal/AddToNarrativeModal';
import { AddToSourceGroupModal } from '../AddToSourceGroupModal/AddToSourceGroupModal';


const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const TopActorsWidget = ({
  report,
  story,
  isShare,
  sourceGroups,
  countries,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [actors, setActors] = useState();
  const [negativeOpinionActors, setNegativeOpinionActors] = useState();
  const [positiveOpinionActors, setPositiveOpinionActors] = useState();
  const [actorsPage, setActorsPage] = useState(1);
  const [negativeOpinionActorsPage, setNegativeOpinionActorsPage] = useState(1);
  const [positiveOpinionActorsPage, setPositiveOpinionActorsPage] = useState(1);
  const [widgetTab, setWidgetTab] = useState('actors');
  const [isLoading, setIsLoading] = useState(false);
  const [sourceFilter, setSourceFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addToCaseModal, setAddToCaseModal] = useState({ isOpen: false, sources: null, });
  const [addToGroupModal, setAddToGroupModal] = useState({ isOpen: false, sources: null, });

  const sorting = report?.widget_config?.top_actors ? report?.widget_config?.top_actors : 'audience';

  const api = isShare ? ShareAPI : API;
  const size = 5;

  const fetchActorsData = useCallback(() => {
    if (!story) return;
    const urlParams = new URLSearchParams();
    urlParams.append('size', size);
    urlParams.append('sort', '-'+ sorting);

    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    Promise.all([
      api.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/sources?${urlParams.toString()}`),
      api.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/sources?${urlParams.toString()}&sentiment=NEGATIVE`),
      api.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/sources?${urlParams.toString()}&sentiment=POSITIVE`)
    ]).then(([actorsData, negative, positive]) => {
      setActors(actorsData);
      setNegativeOpinionActors(negative);
      setPositiveOpinionActors(positive);
    }).catch(error => {
      console.error("Error fetching Tops widget data:", error);
    })

  }, [story])

  useEffect(() => {
    fetchActorsData();
  }, [fetchActorsData]);

  const getAllActors = (page) => {
    setIsLoading(true);

    const urlParams = new URLSearchParams();
    urlParams.append('size', size);
    urlParams.append('sort', '-audience');
    urlParams.append('page', page)

    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    api.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/sources?${urlParams.toString()}`).then(
      data => {
        setActors(data);
        setIsLoading(false);
      }
    )
  }

  const getAllNegativeOpinionActors = (page) => {
    setIsLoading(true);

    const urlParams = new URLSearchParams();
    urlParams.append('size', size);
    urlParams.append('sort', '-audience');
    urlParams.append('page', page)
    urlParams.append('sentiment', 'NEGATIVE');

    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    api.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/sources?${urlParams.toString()}`).then(
      data => {
        setNegativeOpinionActors(data);
        setIsLoading(false);
      }
    )
  }

  const getAllPositiveOpinionActors = (page) => {
    setIsLoading(true);
    const urlParams = new URLSearchParams();
    urlParams.append('size', size);
    urlParams.append('sort', '-audience');
    urlParams.append('page', page)
    urlParams.append('sentiment', 'POSITIVE');

    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    api.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/sources?page=${page}&size=${size}&sort=-audience&sentiment=POSITIVE`).then(
      data => {
        setPositiveOpinionActors(data);
        setIsLoading(false);
      }
    )
  }

  return (
    <div className="report-section" style={{'width': '100%'}} id="actors"> 
      <h3 className="trend-chart-controlls">
        {t('Tops')}
        {widgetTab ? <button
            type="button"
            className={widgetTab === 'actors' ? 'active' : ''}
            onClick={() => {
              setWidgetTab('actors');
            }}
          >
          <span>{t('Actors')}</span>
          <span className={styles.tooltipControls}>
            <Tooltip 
              content={t('Top actors by audience')}
              position='right'
              > 
              <AnchorIcon />
            </Tooltip>
          </span>
        </button> : ''}
        {widgetTab ? <button
            type="button"
            className={widgetTab === 'positive' ? 'active' : ''}
            onClick={() => {
              setWidgetTab('positive');
            }}
          >
            <span>{t('Positive')}</span>
            <span className={styles.tooltipControls}>
            <Tooltip 
              content={t('Top actors by positive sentiment')}
              position='right'
              > 
              <AnchorIcon />
            </Tooltip>
          </span>
          </button> : ''}
        {widgetTab ? <button
            type="button"
            className={widgetTab === 'negative' ? 'active' : ''}
            onClick={() => {
              setWidgetTab('negative');
            }}
          > 
            <span>{t('Negative')}</span>
            <span className={styles.tooltipControls}>
            <Tooltip 
              content={t('Top actors by negative sentiment')}
              position='right'
              > 
              <AnchorIcon />
            </Tooltip>
          </span>
          </button> : ''}
          
        </h3>
        {!actors || !negativeOpinionActors || !positiveOpinionActors 
          ? <div className="card"><div className={styles.widdgetLoader}><LoaderSmall/></div></div>
          : actors?.objects?.length > 0 || negativeOpinionActors?.objects?.length > 0 || positiveOpinionActors?.objects?.length > 0
          ? <div>
              {widgetTab === 'actors'
                ? <div>
                <BreakdownsContainer
                  title={sorting === 'audience' ? t('Top actors by audience') : t('Top actors by number of messages')}
                  // onClick={() => openModal()}
                  details={false}
                >
                {isLoading 
                ? <div className={styles.widdgetLoader}><LoaderSmall /></div> 
                :(actors?.objects?.length > 0 
                  ? <div className={styles.topSourcesContainer}>
                      <div className={styles.nameHeader}><h5>{t('Actor')}</h5></div>
                      <div className={styles.countryHeader}><h5>{t('Location')}</h5></div>
                      <div className={styles.audienceHeader}><h5>{t('Audience')}</h5></div>
                      <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
                      <div className={styles.name}>
                        <div className={styles.breakdownsListMain}>
                          {actors.objects.map(source => {
                            return(
                              <div key={`topSources_${source?.id}`} className={styles.listItem}>
                                <div className={styles.info}>
                                  <span>{platformIconsMapping[source?.platform]}</span>
                                  <span 
                                    className={styles.title}
                                    onClick={() => {
                                      setSourceFilter(source?.id)
                                      setIsModalOpen(true)
                                      ampli.track({
                                        event_type: 'Open wiget popup in event',
                                        event_properties: {
                                          user_id: currentUser?.id,
                                          workspace_id: currentUser?.workspace_id,
                                          report_id: report?.id,
                                          popup_type: 'Top actors by audience',
                                          popup_value: 'Actor: ' + source.name,
                                        }
                                      });
                                    }}
                                    >
                                      <Tooltip content={source.name} position='right'>
                                        {cutString(source.name, 23)}
                                      </Tooltip>
                                    </span>
                                </div>
                                {/* <div className={styles.count}>{source.messages_count}</div> */}
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>
                      
                      <div className={styles.country}>
                        <div className={styles.breakdownsListMain}>
                          {actors?.objects?.map(source => {
                            return(
                              <div key={`topSources_country_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{source?.country_name ? source?.country_name : ''} &nbsp; </div>
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>

                      <div className={styles.audience}>
                        <div className={styles.breakdownsListMain}>
                          {actors?.objects?.map(source => {
                            let followers_cnt = source?.audience;

                            return(
                              <div key={`topSources_audience_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{followers_cnt === null ? '' : formatNumberSignificant(followers_cnt)} &nbsp;</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className={styles.messages}>
                        <div className={styles.breakdownsListMain}>
                            {actors?.objects?.map(source => {
                              return(
                                <div key={`topSources_messages_${source?.id}`} className={styles.listItem}>
                                  <div className={styles.count}>{source.messages_count}</div>
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    </div>

                  : <div className={styles.noData}>{t('There is no data to display')}</div>
                  )}
                </BreakdownsContainer>
                <div className={styles.paginator}>
                  <Paginator
                    size={size}
                    page={actorsPage}
                    total={actors?.total}
                    id='actors'
                    onPageChange={(page) => {
                      setActorsPage(page);
                      getAllActors(page);
                    }}
                  />
                </div>
              </div> 
              : <div>
                {widgetTab === 'positive' && !isShare
                ? <div>
                   <BreakdownsContainer
                    title={t('Top actors by positive sentiment')}
                    // onClick={() => openModal()}
                    details={false}
                  >
                    {isLoading 
                      ? <div className={styles.widdgetLoader}><LoaderSmall /></div> 
                      : positiveOpinionActors?.objects?.length > 0 
                      ? <div className={styles.topSourcesContainer}>
                      <div className={styles.nameHeader}><h5>{t('Actor')}</h5></div>
                      <div className={styles.countryHeader}><h5>{t('Location')}</h5></div>
                      <div className={styles.audienceHeader}><h5>{t('Audience')}</h5></div>
                      <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
                      <div className={styles.name}>
                        <div className={styles.breakdownsListMain}>
                          {positiveOpinionActors?.objects.map(source => {
                            return(
                              <div key={`topSources_${source?.id}`} className={styles.listItem}>
                                <div className={styles.info}>
                                  <span>{platformIconsMapping[source?.platform]}</span>
                                  <span 
                                    className={styles.title}
                                    onClick={() => {
                                      setSourceFilter(source?.id)
                                      setIsModalOpen(true)
                                      ampli.track({
                                        event_type: 'Open wiget popup in event',
                                        event_properties: {
                                          user_id: currentUser?.id,
                                          workspace_id: currentUser?.workspace_id,
                                          report_id: report?.id,
                                          popup_type: 'Top actors by positive sentiment',
                                          popup_value: 'Actor: ' + source.name,
                                        }
                                      });
                                    }}
                                    >
                                      <Tooltip content={source.name} position='right'>
                                        {cutString(source.name, 23)}
                                      </Tooltip>
                                    </span>
                                </div>
                                {/* <div className={styles.count}>{source.messages_count}</div> */}
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>
                      
                      <div className={styles.country}>
                        <div className={styles.breakdownsListMain}>
                          {positiveOpinionActors?.objects?.map(source => {
                            return(
                              <div key={`topSources_country_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{source?.country_name ? source?.country_name : ''} &nbsp; </div>
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>

                      <div className={styles.audience}>
                        <div className={styles.breakdownsListMain}>
                          {positiveOpinionActors?.objects?.map(source => {
                            let followers_cnt = source?.audience;

                            return(
                              <div key={`topSources_audience_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{followers_cnt === null ? '' : formatNumberSignificant(followers_cnt)} &nbsp;</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className={styles.messages}>
                        <div className={styles.breakdownsListMain}>
                            {positiveOpinionActors?.objects?.map(source => {
                              return(
                                <div key={`topSources_messages_${source?.id}`} className={styles.listItem}>
                                  <div className={styles.count}>{source.messages_count}</div>
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    </div> : <div className={styles.noData}>{t('There is no data to display')}</div>}
                   </BreakdownsContainer>
                   <div className={styles.paginator}>
                    <Paginator
                      size={size}
                      page={positiveOpinionActorsPage}
                      total={positiveOpinionActors?.total}
                      id='actors'
                      onPageChange={(page) => {
                        setPositiveOpinionActorsPage(page);
                        getAllPositiveOpinionActors(page);
                      }}
                    />
                  </div>
                </div> : ''}
                {widgetTab === 'negative' && !isShare
                ? <div>
                   <BreakdownsContainer
                    title={t('Top actors by negative sentiment')}
                    // onClick={() => openModal()} 
                    details={false}
                  >
                    {isLoading 
                      ? <div className={styles.widdgetLoader}><LoaderSmall /></div> 
                      : negativeOpinionActors?.objects?.length > 0 
                      ? <div className={styles.topSourcesContainer}>
                      <div className={styles.nameHeader}><h5>{t('Actor')}</h5></div>
                      <div className={styles.countryHeader}><h5>{t('Location')}</h5></div>
                      <div className={styles.audienceHeader}><h5>{t('Audience')}</h5></div>
                      <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
                      <div className={styles.name}>
                        <div className={styles.breakdownsListMain}>
                          {negativeOpinionActors.objects.map(source => {
                            return(
                              <div key={`topSources_${source?.id}`} className={styles.listItem}>
                                <div className={styles.info}>
                                  <span>{platformIconsMapping[source?.platform]}</span>
                                  <span 
                                    className={styles.title}
                                    onClick={() => {
                                      setSourceFilter(source?.id)
                                      setIsModalOpen(true)
                                      ampli.track({
                                        event_type: 'Open wiget popup in event',
                                        event_properties: {
                                          user_id: currentUser?.id,
                                          workspace_id: currentUser?.workspace_id,
                                          report_id: report?.id,
                                          popup_type: 'Top actors by negative sentiment',
                                          popup_value: 'Actor: ' + source.name,
                                        }
                                      });
                                    }}
                                    >
                                      <Tooltip content={source.name} position='right'>
                                        {cutString(source.name, 23)}
                                      </Tooltip>
                                    </span>
                                </div>
                                {/* <div className={styles.count}>{source.messages_count}</div> */}
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>
                      
                      <div className={styles.country}>
                        <div className={styles.breakdownsListMain}>
                          {negativeOpinionActors?.objects?.map(source => {
                            return(
                              <div key={`topSources_country_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{source?.country_name ? source?.country_name : ''} &nbsp; </div>
                              </div>
                            )
                          })}
                        </div>
                        
                      </div>

                      <div className={styles.audience}>
                        <div className={styles.breakdownsListMain}>
                          {negativeOpinionActors?.objects?.map(source => {
                            let followers_cnt = source?.audience;

                            return(
                              <div key={`topSources_audience_${source?.id}`} className={styles.listItem}>
                                <div className={styles.count}>{followers_cnt === null ? '' : formatNumberSignificant(followers_cnt)} &nbsp;</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className={styles.messages}>
                        <div className={styles.breakdownsListMain}>
                            {negativeOpinionActors?.objects?.map(source => {
                              return(
                                <div key={`topSources_messages_${source?.id}`} className={styles.listItem}>
                                  <div className={styles.count}>{source.messages_count}</div>
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    </div> : <div className={styles.noData}>{t('There is no data to display')}</div>}
                   </BreakdownsContainer>
                   <div className={styles.paginator}>
                    <Paginator
                      size={size}
                      page={negativeOpinionActorsPage}
                      total={negativeOpinionActors?.total}
                      id='actors'
                      onPageChange={(page) => {
                        setNegativeOpinionActorsPage(page);
                        getAllNegativeOpinionActors(page);
                      }}
                    />
                  </div>
                </div> : ''}

          </div>} 
      </div> : <div className="card"><div className={styles.noData}>{t('There is no data to display')}</div></div>}
      {isModalOpen ? (
        <WidgetContentModal
          infoModal={isModalOpen}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          // platformsOptions={platformOptions}
          audienceRange={null}
          narrative={report}
          sourceGroup={null}
          source={sourceFilter}
          sentimentOptions={null}
          eventIdsOptions={[story.id]}
          startDate={startDate}
          endDate={endDate}
          isShare={isShare}
          onChange={(bool) => {
            setIsModalOpen(bool);
          }}
          showAddToActions={true}
          onAddToCase={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              sources: [sourceFilter],
            });
          }}
          onAddToGroup={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              sources: [sourceFilter],
              
            });
          }}
        />
      ) : (
        ''
      )}

      {addToCaseModal.isOpen ? (
        <AddToNarrativeModal
          isOpen={addToCaseModal.isOpen}
          deduplication={false}
          caseId={report?.id}
          eventIds={[story?.id]}
          areAllMessagesSelected={true}
          startDate={startDate}
          endDate={endDate}
          sourceGroups={sourceGroups}
          countries={addToCaseModal.countries}
          sources={addToCaseModal.sources}
          onChange={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              contentTypes: null,
              sentiment: null,
            });
          }}
        />
      ) : (
        ''
      )}
      {addToGroupModal.isOpen ? (
        <AddToSourceGroupModal
          isOpen={addToGroupModal.isOpen}
          deduplication={false}
          areAllMessagesSelected={true}
          startDate={startDate}
          endDate={endDate}
          sourceGroups={sourceGroups}
          countries={addToGroupModal.countries}
          sources={addToGroupModal.sources}
          narrative={report}
          eventIds={[story?.id]}
          onChange={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              contentTypes: null,
              sentiment: null,
            });
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
};
