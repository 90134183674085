import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect, useCallback, startTransition } from 'react';
import { API } from '../../../API';

import { Collapsible } from './Collapsible';
import { PlatformChooser } from './PlatformChooser'; 
import { SourceGroupsChooser } from './SourceGroupsChooser';
import { ReactComponent as FilterIcon } from '../assets/filter.svg';
import WebIcon from '../../../assets/web.svg';
import { DoubleRangeSlider } from './DoubleRangeSlider/DoubleRangeSlider';
import { LanguageChooser } from './LanguageChooser';
import { SourceChooser } from './SourceChooser';
import { CountryChooser } from "./CountryChooser";
import { BotsChooser } from './BotsChooser';
import { IoCChooser } from './IoCChooser';
import { AffiliationCountries } from './AffiliationCountries';
import { Sentiment } from './Sentiment';
import { sentimentNameMapping } from '../../../utils/sentiment';
import { ContentTypeChooser } from "./ContentTypeChooser";
import {useClickOutside} from '../../../utils/useClickOutside.jsx';
import {getSelectedCountries} from '../../../utils/countries';
import './Filter.scss';
import styles from './Filter.module.scss';
import { platformIconsMapping } from '../../../utils/platforms';
import { useCurrentUser } from '../../../contexts/CurrentUser';
import { ExcludeSwitch } from './ExcludeSwitch';
import {FeedsChooser} from "./FeedsChooser";

export const Filter = ({onChange, state}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');
  const showExclusiveFilters = featureFlags?.includes('exclusive_filters');
  const showFeedsFilter = featureFlags?.includes('show_feeds_filter');

  const [isActive, setIsActive] = useState(false);
  const [platforms, setPlatforms] = useState(state.platforms || []);
  const [sourceGroups, setSourceGroups] = useState(state.sourceGroups || []);
  const [feeds, setFeeds] = useState(state.feeds || []);
  const [languages, setLanguages] = useState(state.languages || []);
  const [sources, setSources] = useState(state.sources || []);
  const [countries, setCountries] = useState(state.countries || []);
  const [manIndexRange, setManIndexRange] = useState([state.manipulation_index_gte, state.manipulation_index_lte]);
  const [sentiment, setSentiment] = useState(state.sentiment || []);
  const [contentTypes, setContentTypes] = useState(state.contentTypes || []);
  const [showOnlyBots, setShowOnlyBots] = useState(state.bots || false);
  const [discreditations, setDiscreditations] = useState(state.discreditations || false);
  const [affiliationCountries, setAffiliationCountries] = useState(state.affiliations || []);
  const [isPlatformsOpen, setIsPlatformsOpen] = useState(false);
  const [isSourceGroupsOpen, setIsSourceGroupsOpen] = useState(false);
  const [isFeedsOpen, setIsFeedsOpen] = useState(false);
  const [isManipulationOpen, setIsManipulationOpen] = useState(false);
  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);
  const [isSentimentOpen, setIsSentimentOpen] = useState(false);
  const [isContentTypesOpen, setIsContentTypesOpen] = useState(false);
  const [isShowOnlyBotsOpen, setIsShowOnlyBotsOpen] = useState(false);
  const [isDiscreditationsOpen, setIsDiscreditationsOpen] = useState(false);
  const [isAffiliationCountriesOpen, setIsAffiliationCountriesOpen] = useState(false);
  const [languagesExclude, setLanguagesExclude] = useState(state.languagesExclude || false);
  const [countriesExclude, setCountriesExclude] = useState(state.countriesExclude || false);
  const [sourceGroupsExclude, setSourceGroupsExclude] = useState(state.sourceGroupsExclude || false);
  const [feedsExclude, setFeedsExclude] = useState(state.feedsExclude || false);
  const [sourcesExclude, setSourcesExclude] = useState(state.sourcesExclude || false);
  const [affiliationCountriesExclude, setAffiliationCountriesExclude] = useState(state.affiliationCountriesExclude || false);


  let filtersSumm = platforms.length + sourceGroups.length + languages.length + sources.length + countries.length + sentiment.length + contentTypes.length + discreditations.length + (showOnlyBots ? 1 : 0) + affiliationCountries.length;

  const getSelectedLanguages = (selected) => {
    if (selected.length === 0) {
      return
    }

    const urlParams = new URLSearchParams();
    
    selected.forEach(id => {
      urlParams.append('ids', id)
    });

    return API.fetch('GET', `/API/v1/search-languages?${urlParams.toString()}`).then(data => {
      setLanguages(data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((language) => {
          return {value: language.code, label: t(language.name), id: language.id};
        })
      )
    })
  }

  useEffect(() => {
    getSelectedLanguages(languages)
  }, [state.languages])

  
  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let selected = getSelectedCountries(countries, data.objects);
      setCountries(selected);

      if(affiliationCountries.length === 1 && affiliationCountries[0] === 'all') {
        setAffiliationCountries([{value: 'all', label: t('Select all')}])
      } else {
        let selectedAffiliationCountries = getSelectedCountries(affiliationCountries, data.objects);
        setAffiliationCountries(selectedAffiliationCountries);
      }
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);

  const getSelectedSources = (selected) => {
    if (selected.length === 0) {
      return
    }

    const urlParams = new URLSearchParams();

    selected.forEach(id => {
      urlParams.append('ids', id)
    });

    return API.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(data => {
      setSources(data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((source) => {

          let followers_cnt = source.audience;
          return {
            value: source.id,
            label: source.name,
            icon: platformIconsMapping[source.source_type] || WebIcon,
            followers_count: followers_cnt || 0,
          };
        }))
    })
  }

  useEffect(() => {
    getSelectedSources(sources)
  }, [state.source])

  const getSelectedSourceGroups = (selected) => {
    if (selected.length === 0) {
      return
    }

    const urlParams = new URLSearchParams();

    selected.forEach(id => {
      urlParams.append('ids', id)
    });

    return API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then(data => {
      setSourceGroups(data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((group) => {
          return {
            value: group.id,
            label: group.name,
          };
        }))
    })
  }

  useEffect(() => {
    getSelectedSourceGroups(sourceGroups)
  }, [state.sourceGroups])

  const getSelectedFeeds = (selected) => {
    if (selected.length === 0) {
      return
    }

    const urlParams = new URLSearchParams();

    selected.forEach(id => {
      urlParams.append('ids', id)
    });

    return API.fetch('GET', `/API/v1/feeds?${urlParams.toString()}`).then(data => {
      setFeeds(data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((feed) => {
          return {
            value: feed.id,
            label: feed.name,
          };
        }))
    })
  }

  useEffect(() => {
    getSelectedFeeds(feeds)
  }, [state.feeds])

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isPlatformsOpen || !isSourceGroupsOpen || !isLanguagesOpen || !isSourcesOpen || !isManipulationOpen || !isCountriesOpen || !isSentimentOpen || !isContentTypesOpen || !isShowOnlyBotsOpen || !isDiscreditationsOpen || !isAffiliationCountriesOpen || !isFeedsOpen) {
              setIsPlatformsOpen(true);
              setIsSourceGroupsOpen(true);
              setIsManipulationOpen(true);
              setIsLanguagesOpen(true);
              setIsSourcesOpen(true);
              setIsCountriesOpen(true);
              setIsSentimentOpen(true);
              setIsContentTypesOpen(true);
              setIsShowOnlyBotsOpen(true);
              setIsDiscreditationsOpen(true);
              setIsAffiliationCountriesOpen(true);
              setIsFeedsOpen(true);
            } else {
              setIsPlatformsOpen(false);
              setIsSourceGroupsOpen(false);
              setIsManipulationOpen(false);
              setIsLanguagesOpen(false);
              setIsSourcesOpen(false);
              setIsCountriesOpen(false);
              setIsSentimentOpen(false);
              setIsContentTypesOpen(false);
              setIsShowOnlyBotsOpen(false);
              setIsDiscreditationsOpen(false);
              setIsAffiliationCountriesOpen(false);
              setIsFeedsOpen(false);
            }
          }}>
            {isPlatformsOpen && isSourceGroupsOpen && isManipulationOpen && isLanguagesOpen && isSourcesOpen && isCountriesOpen && isSentimentOpen && isContentTypesOpen && isShowOnlyBotsOpen && isDiscreditationsOpen && isAffiliationCountriesOpen && isFeedsOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>
          <Collapsible
            name='Content types'
            summ={contentTypes.length}
            isOpen={isContentTypesOpen}
            onChange={(open) => setIsContentTypesOpen(open)}
          >
            <ContentTypeChooser
              selected={contentTypes}
              onChange={(checklist) => setContentTypes(checklist)}
            />
          </Collapsible>

          <Collapsible
            name='Language'
            summ={languages.length}
            isOpen={isLanguagesOpen}
            onChange={(open) => setIsLanguagesOpen(open)}
            exclude={languagesExclude}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='language-switch'
                value={languagesExclude}
                onChange={setLanguagesExclude}
                color='red'
              />
            </div> : ''}
            
            <LanguageChooser
              selected={languages}
              onChange={(checklist) => setLanguages(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>

          <Collapsible 
            name='Platform'
            summ={platforms.length}
            isOpen={isPlatformsOpen}
            onChange={(open) => setIsPlatformsOpen(open)}
          >
            <PlatformChooser 
              selected={platforms}
              onChange={(checklist) => setPlatforms(checklist)}
            />
          </Collapsible>

          <Collapsible
            name='Country'
            summ={countries.length}
            isOpen={isCountriesOpen}
            onChange={(open) => setIsCountriesOpen(open)}
            exclude={countriesExclude}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='country-switch'
                value={countriesExclude}
                onChange={setCountriesExclude}
                color='red'
              />
            </div> : ''}
            
            <CountryChooser
              selected={countries}
              onChange={(checklist) => setCountries(checklist)}
              onClose={() => startTransition(() => setIsActive(true))}
            />
          </Collapsible>

          <Collapsible 
            name='Actor group' 
            summ={sourceGroups.length}
            isOpen={isSourceGroupsOpen}
            onChange={(open) => setIsSourceGroupsOpen(open)}
            exclude={sourceGroupsExclude}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='actor-group-switch'
                value={sourceGroupsExclude}
                onChange={setSourceGroupsExclude}
                color='red'
              />
            </div> : ''}
            <SourceGroupsChooser 
              selected={sourceGroups}
              onChange={(checklist) => setSourceGroups(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>

          { showFeedsFilter ? <Collapsible
            name='Feeds'
            summ={feeds.length}
            isOpen={isFeedsOpen}
            onChange={(open) => setIsFeedsOpen(open)}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='feeds-switch'
                value={feedsExclude}
                onChange={setFeedsExclude}
                color='red'
              />
            </div> : ''}
            <FeedsChooser
              selected={feeds}
              onChange={(checklist) => setFeeds(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible> : ''}

          <Collapsible
            name='Actor'
            summ={sources.length}
            isOpen={isSourcesOpen}
            onChange={(open) => setIsSourcesOpen(open)}
            exclude={sourcesExclude}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='source-switch'
                value={sourcesExclude}
                onChange={setSourcesExclude}
                color='red'
              />
            </div> : ''}
            
            <SourceChooser
              selected={sources}
              onChange={(checklist) => setSources(checklist)}
              onClose={() => startTransition(() => setIsActive(true))}
            />
          </Collapsible>

          <Collapsible
            name='Compromised accounts'
            summ={discreditations.length}
            isOpen={isDiscreditationsOpen}
            onChange={(open) => setIsDiscreditationsOpen(open)}
          >
            <IoCChooser
              selected={discreditations}
              onChange={(checklist) => setDiscreditations(checklist)}
            />
          </Collapsible>

          <Collapsible
            name='Inauthentic behavior'
            isOpen={isShowOnlyBotsOpen}
            onChange={(open) => setIsShowOnlyBotsOpen(open)}
          >
            <BotsChooser
              checked={showOnlyBots}
              onChange={(value) => setShowOnlyBots(value)}
            />
          </Collapsible>

          <Collapsible
            name='State-affiliated'
            summ={affiliationCountries.length}
            isOpen={isAffiliationCountriesOpen}
            onChange={(open) => setIsAffiliationCountriesOpen(open)}
            exclude={affiliationCountriesExclude}
          >
            <AffiliationCountries
              selected={affiliationCountries}
              onChange={(value) => setAffiliationCountries(value)}
              onClose={() => startTransition(() => setIsActive(true))}
              onExcludeChange={(bool) => setAffiliationCountriesExclude(bool)}
              excludeValue={affiliationCountriesExclude}
            />
          </Collapsible>

          {showMessageManipulationIndex ? <Collapsible 
            name='Manipulation index'
            isOpen={isManipulationOpen}
            onChange={(open) => setIsManipulationOpen(open)}
          >
            <DoubleRangeSlider
              min={0}
              max={1}
              step={0.1}
              manIndexRange={manIndexRange}
              onChange={(range) => setManIndexRange(range)}

            />
          </Collapsible> : ''}

          <Collapsible 
            name='Sentiment'
            summ={sentiment.length}
            isOpen={isSentimentOpen}
            onChange={(open) => setIsSentimentOpen(open)}
          >
            <Sentiment
              selected={sentiment}
              onChange={(checklist) => setSentiment(checklist)}
            />
          </Collapsible>

          {/*  nest filter order: sentiment, persons, locations, organizations */}
        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            onClick={() => {
              onChange(
                platforms,
                sourceGroups.map(group => group.value),
                manIndexRange,
                languages.map(lang => lang.id),
                sources.map(source => source.value),
                countries.map(country => country.value),
                sentiment,
                contentTypes,
                showOnlyBots,
                discreditations,
                affiliationCountries.map(country => country.value),
                feeds.map(feed => feed.value),
                languagesExclude,
                countriesExclude,
                sourceGroupsExclude,
                sourcesExclude,
                affiliationCountriesExclude,
                feedsExclude,
              )
              setIsActive(false)
            }}
          >
            {t('Apply')}
          </button>

          <button 
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setPlatforms([]);
              setSourceGroups([]);
              setManIndexRange([0,1]);
              setLanguages([]);
              setSources([]);
              setCountries([]);
              setSentiment([]);
              setContentTypes([]);
              setShowOnlyBots(false);
              setDiscreditations([]);
              setAffiliationCountries([]);
              setFeeds([]);
              setLanguagesExclude(false);
              setCountriesExclude(false);
              setSourceGroupsExclude(false);
              setSourcesExclude(false);
              setAffiliationCountriesExclude(false);
              setFeedsExclude(false);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>


    </div>
  )
}
