import { useTranslation } from 'react-i18next';

export const FavoriteChooser = ({onChange, checked = false}) => {
  const { t } = useTranslation();

  return(
    <div className='chooser'>
      <ul>
        <li>
          <input
            id='show_only_favorite'
            type='checkbox'
            checked={checked}
            onChange={() => {
              onChange(!checked) 
            }}
          />
          <label htmlFor='show_only_favorite'>{t('Show only favorite')}</label>
        </li>
        
      </ul>
    </div>
  )
}
