import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { API, ShareAPI } from '../../API';
import { ampli } from '../../ampli';
import { ReactComponent as DiscoverIcon} from '../../assets/discover.svg';
import { SummaryPanel } from '../SummaryPanel/SummaryPanel';
import { SentimentBar } from '../SentimentBar/SentimentBar';
import { ActorsTypes } from '../ActorsTypesWidget/ActorsTypes';
import { TopActorsWidget } from '../TopActorsWidget/TopActorsWidget';
import { trendData } from './evolution';
import { OpinionsWidget } from '../OpinionsWidget/OpinionsWidget';
import { GeoChart } from '../GeoChart/GeoChart';
import { Loader } from '../Loader/Loader';
import {ReactComponent as NotFoundIcon} from '../../assets/notFound.svg';

import styles from './StoryContent.module.scss';
import { EvolutionChart } from '../EvolutionChart/EvolutionChart';


export const StoryContent = ({
  report,
  story,
  isShare,
  isData=true,
  sourceGroups,
  countries,
  sentiment,
  startDate,
  endDate,
  scrollRef
}) => {
  const { t } = useTranslation();
  const descriptionRef = useRef(null);
  const [currentUser] = useCurrentUser();
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
  const [storyData, setStoryData] = useState(null);
  const [sentimentData, setSentimentData] = useState(null);
  const [opinions, setOpinions] = useState(null);
  const [modal, setModal] = useState({isVisible: false, opinion: null});

  const widgetConfig = report?.widget_config || {};
  
  const scrollToWidget = () => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', passive: true});
  }


  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsBreakdownOptions] = useState(() => [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ]);

  const [viewOptions] = useState(() => [
    {value: 'STANDARD', name: t('Standard')},
    {value: 'VALUES', name: t('Values')},
    {value: 'POINTS', name: t('Points')}
  ])

  const api = isShare ? ShareAPI : API;
  const [totalEvolutionChart, setTotalEvolutionChart] = useState('publications');
  const [storyShares, setStoryShares] = useState(trendData[story?.id]);

  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );

  const [statsBreakdown, setStatsBreakdown] = useState(
    () => statsBreakdownOptions[0],
  );

  const [chartViewOptions, setChartViewOptions] = useState(() => viewOptions[0]);

  const fetchStory = () => {
    if (!story) return;
    setStoryData(null);
    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}`).then((data) => {
      setStoryData(data);
      if(descriptionRef?.current !== null) {
        scrollToWidget();
      }
      
    });
  };

  useEffect(() => {
    setTotalEvolutionChart('publications');
    setStatsAggregation(statsAggregationOptions[0]);
    setStatsBreakdown(statsBreakdownOptions[0]);
    fetchStory();
  }, [story]);

  const fetchSentiment = () => {
    if (!story) return;
    const urlParams = new URLSearchParams();
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }
    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}/sentiment?${urlParams.toString()}`).then((data) => {
      setSentimentData(data);
    });
  };

  useEffect(() => {
    fetchSentiment();
  }, [story]);

  const fetchStoryShares = (story, statsAggregation, statsBreakdown) => {
    if (!story) return;
    const urlParams = new URLSearchParams();

    urlParams.set('aggregation', statsAggregation);
    urlParams.set('cumulative', false)
    urlParams.set('top', 6)

    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    let url = `/API/v1/topic-modeling/${report.id}/events/${story.id}/messages/stats?${urlParams.toString()}`;

    if (statsBreakdown !== null) {
      urlParams.set('breakdown', statsBreakdown);
      url = `/API/v1/topic-modeling/${report.id}/events/${story.id}/messages/stats?${urlParams.toString()}`;
    }

    api.fetch('GET', url)
    .then((data) => {
      setStoryShares(data);
    });
  }

  useEffect(() => {
    fetchStoryShares(story, statsAggregation.value, statsBreakdown.value)
  }, [statsAggregation, statsBreakdown, story])



  const handleCreateCase = () => {
    api.fetch('POST', `/API/v1/topic-modeling/${report.id}/events/${story.id}/to_case`)
    .then((data) => {
      window.open(`/narratives/${data}`, '_blank');
    }
    );
    
  };

  useEffect(() => {
    ampli.track({
      event_type: 'Open event',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        report_id: report?.id,
      }
    });
  }, []);

  useEffect(() => {
    if (descriptionRef?.current !== null) {
      scrollToWidget();
    }
  }, [descriptionRef, storyData]);

  if(!isData){
    return (
      <div className={styles.noDataContainer}>
        <NotFoundIcon />
      </div>
    )
  }

  if (!story || !storyData) {
    return <Loader />;
  } 


  return (
    <div className={styles.storyContentContainer}>
      <div ref={descriptionRef} className={styles.storyDescriptionContainer}>
        <div className={styles.storyDescriptionHeader}>
          <h3 className={styles.storyTitle}>{story?.title}</h3>
          <div className={styles.storyDescriptionControls}>
            <Tooltip content={t('Save as case')} position="bottom">
              <button
                type="button"
                className={styles.saveButton}
                onClick={() => {
                  handleCreateCase();
                  ampli.track({
                    event_type: 'Save event to case',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      report_id: report?.id,
                    }
                  });
                }}
              >
                <DiscoverIcon />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className={styles.storyContent}>{story?.description}</div>
      </div>

      <div className="report-section">
        <h3>{t('Summary')}</h3>
        <SummaryPanel
          report={report}
          narrativeIds={[storyData?.id]}
          isShare={isShare}
          isStory={true}
          sourceGroups={sourceGroups}
          countries={countries}
          startDate={startDate}
          endDate={endDate}
          summaryPanelType={widgetConfig?.summary_panel || 'standard'}
        />
      </div>

      <SentimentBar
        data={sentimentData}
        entity={story}
        entityType={'story'}
        tabList={widgetConfig?.sentiment_bar?.length < 2 ? widgetConfig?.sentiment_bar : ['messages', 'posts']}
        sourceGroups={sourceGroups}
        countries={countries}
        startDate={startDate}
        endDate={endDate}
        report={report}
      />


      {report?.type !== 'EVENTS_ONLY' ? <OpinionsWidget
        report={report}
        story={story}
        isShare={isShare}
        sourceGroups={sourceGroups}
        countries={countries}
        startDate={startDate}
        endDate={endDate}
        viewMode={widgetConfig?.opinion_card || 'rating'}
      /> : null}
      

      {Object.keys(widgetConfig).length === 0 || widgetConfig?.geo_widget ? <div className="report-section">
        <h3>{t('Geography')}</h3>
        <GeoChart
          report={report}
          story={story}
          sourceGroups={sourceGroups}
          countries={countries}
          startDate={startDate}
          endDate={endDate}
          isShare={isShare}

        />
      </div> : ''}

      <TopActorsWidget
        report={report}
        story={story}
        isShare={isShare}
        sourceGroups={sourceGroups}
        countries={countries}
        startDate={startDate}
        endDate={endDate}
      />

      {Object.keys(widgetConfig).length === 0 || widgetConfig?.actor_types ? <ActorsTypes
        report={report}
        story={story}
        sourceGroups={sourceGroups}
        countries={countries}
        startDate={startDate}
        endDate={endDate}
      /> : ''}

      <EvolutionChart
        data={storyShares}
        narrative={story}
        showMessageManipulationIndex={false}
        chartTab={totalEvolutionChart}
        agregation={statsAggregation}
        breakdown={statsBreakdown}
        view={chartViewOptions}
        onChange={(tab, agregation, breakdown, view) => {
          setTotalEvolutionChart(tab);
          setStatsAggregation(agregation);
          setStatsBreakdown(breakdown);
          setChartViewOptions(view);
        }}
        tabList={widgetConfig?.evolution_tabs || ['publications', 'impressions', 'engagement', 'manipulation_index']}
        filtersSettings={widgetConfig?.evolution_breakdowns || ['aggregation', 'breakdown']}
      />
    </div>
  );
};
