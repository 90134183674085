import { useState } from 'react';
import styles from './ImageComponent.module.scss';
import { ReactComponent as ImageIcon } from '../../assets/image.svg';

export const ImageComponent = ({ url, onClick }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={`${styles.imageContainer} ${loaded ? styles.loaded : ''}`}>
      
      {!loaded ? <div className={styles.skeletonCard}>
        <ImageIcon />
      </div> : null}
      
      <img
        src={url}
        alt="Content"
        loading="lazy"
        onLoad={handleImageLoad}
        className={styles.contentImage}
        onClick={() => onClick(true)}
      />
    
    </div>
  );
};