import { format } from 'date-fns';
import { ampli } from '../../ampli';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { useEffect, useState, useContext, useRef } from "react";
import { Chart } from "react-google-charts";
import { API, ShareAPI } from "../../API";
import { useTranslation } from "react-i18next";
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";
import { WidgetContentModal } from '../WidgetContentModal/WidgetContentModal';
import { AddToNarrativeModal } from '../AddToNarrativeModal/AddToNarrativeModal';
import { AddToSourceGroupModal } from '../AddToSourceGroupModal/AddToSourceGroupModal';

import styles from './ActorsTypes.module.scss';

export const AudienceChart = ({
  report,
  story,
  isShare,
  isClickable=true,
  sourceGroups,
  countries,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [audience, setAudience] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const api = isShare ? ShareAPI : API;
  const abortControllerRef = useRef();
  const [addToCaseModal, setAddToCaseModal] = useState({ isOpen: false, audience: null, });
  const [addToGroupModal, setAddToGroupModal] = useState({ isOpen: false, audience: null, });

  const bucketNameMap = {
    'very_small': t('Very small (< 1k)'),
    'small': t('Small (1k - 10k)'),
    'medium': t('Medium (10k - 100k)'),
    'big': t('Big (100k - 500k)'),
    'very_big': t('Very big (> 500k)')
  }

  const audienceSizeMap = {
    'very_small': [0, 1000],
    'small': [1000, 10000],
    'medium': [10000, 100000],
    'big': [100000, 500000],
    'very_big': [500000, 1000000000]
  }

  const getAudience = () => {
    if (!story) return;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const urlParams = new URLSearchParams();
    urlParams.append('breakdown', 'audience');
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_ids', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}/sources/breakdown?${urlParams.toString()}`, null, null, signal).then(
      data => setAudience(data)
    )
  }

  useEffect(() => {
    getAudience()
  }, [story])

  const colors = ['#BEFDEE', '#7FFADD', '#45F1C9', '#1CD6AA', '#00BA8D'];

  const getChartData = (audience) => {
    let data = [
      ['Audience', 'Number', { role: "style" }, { role: 'annotation' }]
    ];
    
    audience.objects?.forEach((audience, index) => 
       data.push([bucketNameMap[audience.audience_size], audience.sources_count, colors[index], audience.sources_count])
    )
    
    return data;
  }

  const options = {
    chartArea: { left: '5%', top: '15%', width:'100%', height:'75%' },
    legend: 'none',
    bar: { groupWidth: "55%" },
    annotations: {alwaysOutside: true, stem: {color: 'transparent'}, textStyle: {bold: true, opacity: 1, color: '#151419'}, highContrast: false}
  }

  return (
    <>
      {audience ? (
        audience.objects?.length > 0 ? (
          <div className={styles.chartWrapper}>
            <Chart
              chartType="ColumnChart"
              data={getChartData(audience)}
              width={'100%'}
              height={'400px'}
              className="barChart"
              options={options}
              chartEvents={
                isClickable
                  ? [
                      {
                        eventName: 'select',
                        callback({ chartWrapper }) {
                          let selected = chartWrapper.getChart().getSelection();
                          let index = selected[0]?.row;
                          let bucket = audience.objects[index].audience_size;
                          // let option = {value: 'audience', label: 'Audience',}
                          setFilter(audienceSizeMap[bucket]);
                          setIsModalOpen(true);
                          ampli.track({
                            event_type: 'Open wiget popup in event',
                            event_properties: {
                              user_id: currentUser?.id,
                              workspace_id: currentUser?.workspace_id,
                              report_id: report?.id,
                              popup_type: 'Actor Types: Audience',
                              popup_value: 'Auduience size: ' + bucketNameMap[bucket],
                            }
                          });
                        },
                      },
                    ]
                  : []
              }
              style={{ margin: '0px', padding: '0px' }}
              chartVersion="51"
            />
          </div>
        ) : (
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        )
      ) : (
        <div className={styles.widdgetLoader}>
          <LoaderSmall />
        </div>
      )}

      {isModalOpen ? (
        <WidgetContentModal
          infoModal={isModalOpen}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          // platformsOptions={platformOptions}
          audienceRange={filter}
          narrative={report}
          sourceGroup={sourceGroups.length > 0 ? sourceGroups?.map((sourceGroup) => sourceGroup.value) : null}
          source={null}
          sentimentOptions={null}
          eventIdsOptions={[story.id]}
          startDate={startDate}
          endDate={endDate}
          contentTypeOptions={null}
          isShare={isShare}
          onChange={(bool) => {
            setIsModalOpen(bool);
          }}
          showAddToActions={true}
          onAddToCase={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              audience: filter,
            });
          }}
          onAddToGroup={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              audience: filter,
              
            });
          }}
        />
      ) : (
        ''
      )}

      {addToCaseModal.isOpen ? (
        <AddToNarrativeModal
          isOpen={addToCaseModal.isOpen}
          deduplication={false}
          caseId={report?.id}
          eventIds={[story?.id]}
          areAllMessagesSelected={true}
          startDate={startDate}
          endDate={endDate}
          sourceGroups={sourceGroups}
          countries={countries}
          audienceRange={addToCaseModal.audience}
          onChange={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              contentTypes: null,
              sentiment: null,
            });
          }}
        />
      ) : (
        ''
      )}
      {addToGroupModal.isOpen ? (
        <AddToSourceGroupModal
          isOpen={addToGroupModal.isOpen}
          deduplication={false}
          areAllMessagesSelected={true}
          startDate={startDate}
          endDate={endDate}
          sourceGroups={sourceGroups}
          countries={countries}
          audienceRange={addToGroupModal.audience}
          narrative={report}
          eventIds={[story?.id]}
          onChange={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              contentTypes: null,
              sentiment: null,
            });
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};