import * as d3 from 'd3';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Select from 'react-select';
import { format, parse, parseISO, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ampli } from "../../ampli";

import ExportReportPopup from '../../components/ExportReportPopup';
import { API, ShareAPI } from '../../API';
import { CircleChart } from '../../components/CircleChart/CircleChart';
import { TrendChart } from '../../components/TrendChart/TrendChart';
import { ExpandableText } from '../../components/CollapsibleText/ExpandableText';
import { Loader } from '../../components/Loader/Loader';
import { Paginator } from '../../components/Paginator/Paginator';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as RecoverIcon } from '../../assets/recover.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/envelope.svg';
import { ReactComponent as EnvelopeOpenIcon } from '../../assets/envelopeOpen.svg';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../assets/originalText.svg';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';
import { ReactComponent as ChevronIcon } from '../../pages/SearchPage/assets/chevron-down.svg';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';
import { ReactComponent as HeartSolidIcon } from '../../assets/heart-solid.svg';
import { ReactComponent as HeartRegularIcon } from '../../assets/heart-regular.svg';
import { ReactComponent as SearchIcon } from '../../pages/SearchPage/assets/search.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import { MessageModal } from '../../components/MessagesTable/MessageModal';
import { getSentimentName, getSentimentIcon } from "../../utils/sentiment";
import { useCurrentUser } from '../../contexts/CurrentUser';
import './ReportPage.css';
import { decodeSourceName } from '../../utils/decodeURI';
import { palette } from '../../utils/colors';
import Tooltip from '../../components/Tooltip/Tooltip';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Multichoice } from '../../components/DropdownMenu/Multichoice';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { ButtonsList } from '../../components/DropdownMenu/ButtonsList';
import { buildImageUrl } from '../../utils/downloadChart';
import { SentimentBar } from '../../components/SentimentBar/SentimentBar';
import { ImagesContainer } from '../../components/Image/ImagesContainer';

import messageStyles from '../../components/MessagesTable/ExtendedMessageView.module.scss';
import narrativeStyles from '../NarrativePage/NarrativePage.module.scss';
import { replaceNewlinesWithBreaks } from '../../utils/replaceNewlinesWithBreaks';


const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export function ReportPage({ isShare = false }) {
  const [currentUser] = useCurrentUser();
  const [exportModal, setExportModal] = useState({ isActive: false, id: null });
  const { t } = useTranslation();
  const [report, setReport] = useState(null);
  const [viewOptions] = useState(() => [
    {value: 'STANDARD', name: t('Standard')},
    {value: 'VALUES', name: t('Values')},
    {value: 'POINTS', name: t('Points')}
  ])

  const mapViewOptions = {
    'STANDARD': {value: 'STANDARD', name: t('Standard')},
    'VALUES': {value: 'VALUES', name: t('Values')},
    'POINTS': {value: 'POINTS', name: t('Points')}
  }
  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );

  const [narrativeShares, setNarrativeShares] = useState(null);
  const [dashboard, setDashboard] = useState(null);
  const [messages, setMessages] = useState(null);
  const [currentNarrative, setCurrentNarrative] = useState(null);
  const [page, setPage] = useState(1);

  
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { reportId } = useParams();
  const itemsRef = useRef([]);
  const [error, setError] = useState(null);
  const [messageModal, setMessageModal] = useState({message: null, isOpen: false, narrartiveMessage: null});

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const nativeLang = currentUser?.translation_language ? currentUser?.translation_language : window.clientInformation.language.split('-', 1)[0].toUpperCase();
  const showNer = featureFlags?.includes('ner_widget');

  const [highlights, setHighlights] = useState(['KEYWORDS']);
  const [modalHighlights, setModalHighlights] = useState([]);
  const [highlightsMenu, setHighlightsMenu] = useState(false);
  const trendChartRefViews = useRef(null);
  const trendChartCanvasRefViews = useRef(null);
  const [isTrendChartViewsMenuOpen, setIsTrendChartViewsMenuOpen] = useState(false);
  const [chartViewOptionsViews, setChartViewOptionsViews] = useState(() => viewOptions[0]);

  const trendChartRefShare = useRef(null);
  const trendChartCanvasRefShare = useRef(null);
  const [isTrendChartShareMenuOpen, setIsTrendChartShareMenuOpen] = useState(false);
  const [chartViewOptionsShare, setChartViewOptionsShare] = useState(() => viewOptions[0]);

  const trendChartRefEngagement = useRef(null);
  const trendChartCanvasRefEngagement = useRef(null);
  const [isTrendChartEngagementMenuOpen, setIsTrendChartEngagementMenuOpen] = useState(false);
  const [chartViewOptionsEngagement, setChartViewOptionsEngagement] = useState(() => viewOptions[0]);

  const trendChartRefPublications = useRef(null);
  const trendChartCanvasRefPublications = useRef(null);
  const [isTrendChartPublicationsMenuOpen, setIsTrendChartPublicationsMenuOpen] = useState(false);
  const [chartViewOptionsPublications, setChartViewOptionsPublications] = useState(() => viewOptions[0]);

  const pageSize = 50;

  const select = (
    <Select
      className="chart-select"
      onChange={setStatsAggregation}
      value={statsAggregation}
      options={statsAggregationOptions}
    />
  );

  const sameHeightHeaders = () => {
    let maxHeight = 0;

    if (itemsRef.current.length > 0) {
      itemsRef.current.forEach((item) => {
        if (item?.clientHeight > maxHeight) {
          maxHeight = item.clientHeight;
        }
      });
      itemsRef.current.forEach((item) => {
        item.style.height = `${maxHeight}px`;
      });
    }
  };

  const onRequestError = useCallback(
    (resp) => {
      if (isShare && (resp.status === 401 || resp.status === 403)) {
        const hash = searchParams.get('h');
        const with_password = searchParams.get('with_password');
        navigate(`/reports/${reportId}/share/login?h=${hash}&with_password=${with_password}`);
      }
    },
    [navigate, reportId, searchParams, isShare],
  );

  const fetchReport = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    api
      .fetch('GET', `/API/v1/reports/${reportId}`, null, null, null, onRequestError)
      .then((data) => {
        setError(null);
        setReport(data);

        const urlParams = new URLSearchParams();
        data.parameters.narrative_ids.forEach((narrative_id) =>
          urlParams.append('narrative_id', narrative_id),
        );
        if (data.parameters.start_date !== null) {
          urlParams.set(
            'start',
            data.parameters.start_date.split('T')[0] + 'T00:00:00'
          );
        }
        if (data.parameters.end_date !== null) {
          urlParams.set(
            'end',
            data.parameters.end_date.split('T')[0] + 'T23:59:59'
          );
        }

        api
          .fetch(
            'GET',
            `/API/v1/dashboard/narratives?${urlParams.toString()}`,
            null,
            null,
            null,
            onRequestError,
          )
          .then((data) => {
            data.objects.sort((a, b) => (a.id - b.id));
            setDashboard(data)
            // if (data.objects.length > 0) {
              setCurrentNarrative({id: data.objects[0]?.id, name: data.objects[0]?.name})
            // }
          });
      })
      .catch((e) => {
        setError(e.message);
      });
      
  }, [isShare, reportId, onRequestError]);

  const fetchNarrativeMessages = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!currentNarrative || !currentNarrative.id) {
      return
    }

    const urlParams = new URLSearchParams();
    urlParams.set('size', pageSize);
    urlParams.set('page', page);
    urlParams.set('narrative_id', currentNarrative.id);

    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    api.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then(
      (data) => {
        setMessages(data);
      },
    );
  }, [currentNarrative, page])

  const fetchNarrativesShare = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();
    report.parameters.narrative_ids.forEach((narrative_id) =>
      urlParams.append('narrative_id', narrative_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    urlParams.set('aggregation', statsAggregation.value);

    api
      .fetch(
        'GET',
        `/API/v1/stats/narratives/shares?${urlParams.toString()}`,
        null,
        null,
        null,
        onRequestError,
      )
      .then((data) => {
        let casesLength = data.objects.map(
          (narrative) => narrative.stats.length,
        );
        let maxCasesLength = Math.max(...casesLength);
        if (
          narrativeShares === null &&
          maxCasesLength > 30 &&
          maxCasesLength < 180
        ) {
          setStatsAggregation({ value: 'WEEK', label: t('Week') });
        } else if (narrativeShares === null && maxCasesLength >= 180) {
          setStatsAggregation({ value: 'MONTH', label: t('Month') });
        } else {
          data.objects.sort((a, b) => a.id - b.id);
          setNarrativeShares(data);
        }
      });
  }, [isShare, onRequestError, report, statsAggregation]);

  useEffect(() => {
    sameHeightHeaders();
    setTimeout(sameHeightHeaders, 500);
  }, [narrativeShares]);

  useEffect(fetchReport, [fetchReport]);

  useEffect(fetchNarrativesShare, [fetchNarrativesShare]);
  useEffect(fetchNarrativeMessages, [currentNarrative, page]);

  const translateMessage = (messageId, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    return API.fetch('POST', '/API/v1/translations/translate', null, body)
  }

  const toggleTranslate = (messageId, isTranslation) => {
    setMessages({...messages, objects: messages.objects.map(message => {
      if(message.id === messageId) {
        message.isTranslation = isTranslation;
        if (message.content_type === 'COMMENT' && message.parent_message) {
          message.parent_message.isTranslation = isTranslation;
        }
        return message;
      } else {
        return message;
      }
    })})
  }

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find(message => message.id === messageId);
    const originalPostText =
      target?.content_type === 'COMMENT' ? target?.parent_message?.text : '';
    if (target.translated) {
      toggleTranslate(messageId, isTranslation)
    } else {
      toggleTranslate(messageId, true)
      const nativeLanguage = currentUser?.translation_language
        ? currentUser?.translation_language
        : window.clientInformation.language.split('-', 1)[0];

      let newMessagesList = [...messages.objects];

      if (originalPostText) {
        Promise.allSettled([
          translateMessage(messageId, originalPostText, nativeLanguage),
          translateMessage(messageId, text, nativeLanguage),
        ]).then((results) => {
          if (results[0].status === 'fulfilled') {
            const originalPostData = results[0].value;

            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                let m = {
                  ...message,
                  parent_message: {
                    ...message.parent_message,
                    isTranslation: true,
                    translated: originalPostData.destination_text,
                  },
                };
                return m;
              } else {
                return message;
              }
            });
          } else {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  parent_message: {
                    ...message.parent_message,
                    isTranslation: true,
                    translated: t('We were not able to translate this text.'),
                    error: true,
                  },
                };
              } else {
                return message;
              }
            });
          }

          if (results[1].status === 'fulfilled') {
            const messageData = results[1].value;
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: messageData.destination_text,
                };
              } else {
                return message;
              }
            });
          } else {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: t('We were not able to translate this text.'),
                  error: true,
                };
              } else {
                return message;
              }
            });
          }

          setMessages({ ...messages, objects: newMessagesList });
        });
      } else {
        translateMessage(messageId, text, nativeLanguage)
          .then((data) => {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: data.destination_text,
                };
              } else {
                return message;
              }
            });

            setMessages({ ...messages, objects: newMessagesList });
          })
          .catch((e) => {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: t('We were not able to translate this text.'),
                  error: true,
                };
              } else {
                return message;
              }
            });

            setMessages({ ...messages, objects: newMessagesList });
          });
      }
    }
  }

  if(!report && error) {
    return <div className='noData'> {t(error)}</div>
  }

  if (!report || !narrativeShares || !currentNarrative) {
    return <Loader />;
  }

  const narrativeColor = Object.fromEntries(
    narrativeShares.objects.map((narrative, i) => [narrative.id, palette[i % palette.length]])
  );

  const nerTypesList = [
    {value: 'KEYWORDS', label: t('Matched keywords')},
  ]

  if(showNer) {
    nerTypesList.push(
      {value: 'PERSON', label: t('Persons')},
      {value: 'ORGANIZATION', label: t('Organizations')},
      {value: 'LOCATION', label: t('Locations')}
    )
  }

  if(dashboard.objects.length === 0) {
    return <div className='noData'>{t('No cases found')}</div>
  }
  

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/reports">{t('Reports')}</Link>
          </span>
          <span>{report.name}</span>
        </div>
        <div className="controls">
          {isShare ? (
            ''
          ) : (
            <>
              <Link
                to={`/reports/${reportId}/edit`}
                className="button new-button"
              >
                <EditIcon /> {t('Edit report')}
              </Link>
              <button
                type="button"
                className="button new-button"
                onClick={() =>
                  setExportModal({ isActive: true, id: report.id })
                }
              >
                <ShareIcon /> {t('Export report')}
              </button>
            </>
          )}
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Overview')}</h3>
        <div className="overview">
          <div className="card-wrapper color0">
            <span className="card-content__icon">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M11.7076 17.3639L10.2933 18.7781C8.34072 20.7308 5.1749 20.7308 3.22228 18.7781C1.26966 16.8255 1.26966 13.6597 3.22228 11.7071L4.63649 10.2929M17.3644 11.7071L18.7786 10.2929C20.7312 8.34024 20.7312 5.17441 18.7786 3.22179C16.826 1.26917 13.6602 1.26917 11.7076 3.22179L10.2933 4.636M7.50045 14.4999L14.5005 7.49994"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Actors')}</div>
              <div className="body">
                {formatNumber(narrativeShares.sources)}
              </div>
            </div>
          </div>
          <div className="card-wrapper color1">
            <span className="card-content__icon">
              <svg width="15" height="18" viewBox="0 0 15 18" fill="none">
                <path
                  d="M9.125 1.21563V4.52006C9.125 4.9681 9.125 5.19212 9.21356 5.36325C9.29145 5.51378 9.41575 5.63616 9.56863 5.71286C9.74244 5.80006 9.96996 5.80006 10.425 5.80006H13.7811M10.75 9.8H4.25M10.75 13H4.25M5.875 6.6H4.25M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7384C13.1277 16.5083 13.5006 16.1412 13.7343 15.6896C14 15.1762 14 14.5041 14 13.16V6.63752C14 6.10172 13.785 5.58831 13.4032 5.21239L9.70885 1.57487C9.33471 1.20648 8.8307 1 8.30564 1H4.9C3.53487 1 2.85231 1 2.3309 1.26158C1.87225 1.49168 1.49936 1.85883 1.26567 2.31042C1 2.82381 1 3.49587 1 4.84V13.16C1 14.5041 1 15.1762 1.26567 15.6896C1.49936 16.1412 1.87225 16.5083 2.3309 16.7384C2.85231 17 3.53487 17 4.9 17Z"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Messages')}</div>
              <div className="body">
                {formatNumber(narrativeShares.publications)}
              </div>
            </div>
          </div>
          <div className="card-wrapper color2">
            <span className="card-content__icon">
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                <path
                  d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181 8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028 1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1 19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782 7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972 20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418 15 2.41127 10.4952 1.26805 8.71318Z"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315 12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692 11 11 11Z"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Views')}</div>
              <div className="body">
                {formatWithCustomGigaBillion(narrativeShares.impressions)}
              </div>
            </div>
          </div>
          <div className="card-wrapper color3">
            <span className="card-content__icon">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M5.10176 17V8.2M1 9.8V15.4C1 16.2837 1.73457 17 2.6407 17H13.655C14.8697 17 15.9027 16.1357 16.0874 14.9649L16.9709 9.36494C17.2002 7.91112 16.0468 6.6 14.5384 6.6H11.6646C11.2115 6.6 10.8442 6.24183 10.8442 5.8V2.97267C10.8442 1.8832 9.93857 1 8.82137 1C8.5549 1 8.31343 1.15304 8.2052 1.3905L5.31829 7.72491C5.18662 8.01381 4.89283 8.2 4.56864 8.2H2.6407C1.73457 8.2 1 8.91634 1 9.8Z"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Reactions')}</div>
              <div className="body">
                {formatWithCustomGigaBillion(narrativeShares.engagement)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Cases')}</h3>
        <div className="narratives">
          {narrativeShares.objects.map((narrative, i) => {
            return (
              <div key={i} className={`narrative color${i}`}>
                <div
                  className="header"
                  ref={(el) => (itemsRef.current[i] = el)}
                >
                  <div className="header__number">#{i + 1}</div>{' '}
                  <div className="header__title">{narrative.name} </div>
                </div>
                <div className="body">
                  <div className="metrics">
                    <div className="card-content">
                      <span className="card-content__icon card-content__icon-small">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            d="M11.7076 17.3639L10.2933 18.7781C8.34072 20.7308 5.1749 20.7308 3.22228 18.7781C1.26966 16.8255 1.26966 13.6597 3.22228 11.7071L4.63649 10.2929M17.3644 11.7071L18.7786 10.2929C20.7312 8.34024 20.7312 5.17441 18.7786 3.22179C16.826 1.26917 13.6602 1.26917 11.7076 3.22179L10.2933 4.636M7.50045 14.4999L14.5005 7.49994"
                            stroke="#2563EB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </span>
                      {formatNumber(narrative.sources)}
                    </div>
                    <div className="card-content">
                      <span className="card-content__icon card-content__icon-small">
                        <svg
                          width="15"
                          height="18"
                          viewBox="0 0 15 18"
                          fill="none"
                        >
                          <path
                            d="M9.125 1.21563V4.52006C9.125 4.9681 9.125 5.19212 9.21356 5.36325C9.29145 5.51378 9.41575 5.63616 9.56863 5.71286C9.74244 5.80006 9.96996 5.80006 10.425 5.80006H13.7811M10.75 9.8H4.25M10.75 13H4.25M5.875 6.6H4.25M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7384C13.1277 16.5083 13.5006 16.1412 13.7343 15.6896C14 15.1762 14 14.5041 14 13.16V6.63752C14 6.10172 13.785 5.58831 13.4032 5.21239L9.70885 1.57487C9.33471 1.20648 8.8307 1 8.30564 1H4.9C3.53487 1 2.85231 1 2.3309 1.26158C1.87225 1.49168 1.49936 1.85883 1.26567 2.31042C1 2.82381 1 3.49587 1 4.84V13.16C1 14.5041 1 15.1762 1.26567 15.6896C1.49936 16.1412 1.87225 16.5083 2.3309 16.7384C2.85231 17 3.53487 17 4.9 17Z"
                            stroke="#2563EB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </span>
                      {formatNumber(narrative.publications)}
                    </div>
                    <div className="card-content">
                      <span className="card-content__icon card-content__icon-small">
                        <svg
                          width="22"
                          height="16"
                          viewBox="0 0 22 16"
                          fill="none"
                        >
                          <path
                            d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181 8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028 1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1 19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782 7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972 20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418 15 2.41127 10.4952 1.26805 8.71318Z"
                            stroke="#2563EB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315 12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692 11 11 11Z"
                            stroke="#2563EB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </span>
                      {formatWithCustomGigaBillion(narrative.impressions)}
                    </div>
                    <div className="card-content">
                      <span className="card-content__icon card-content__icon-small">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.10176 17V8.2M1 9.8V15.4C1 16.2837 1.73457 17 2.6407 17H13.655C14.8697 17 15.9027 16.1357 16.0874 14.9649L16.9709 9.36494C17.2002 7.91112 16.0468 6.6 14.5384 6.6H11.6646C11.2115 6.6 10.8442 6.24183 10.8442 5.8V2.97267C10.8442 1.8832 9.93857 1 8.82137 1C8.5549 1 8.31343 1.15304 8.2052 1.3905L5.31829 7.72491C5.18662 8.01381 4.89283 8.2 4.56864 8.2H2.6407C1.73457 8.2 1 8.91634 1 9.8Z"
                            stroke="#2563EB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </span>
                      {formatWithCustomGigaBillion(narrative.engagement)}
                    </div>
                  </div>
                  <div className="percentage">
                    <div className="percentage__pertitle">
                      {t('Percentage')}{' '}
                      {formatNumber(narrative.narrative_share)}%
                    </div>
                    <div className="percentage__range">
                      <i style={{ width: `${narrative.narrative_share}%` }}></i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="card card-circlechartwrapper">
        <div className="card-header" />
        <div className="card-body">
          {(dashboard) ? (
            <CircleChart data={dashboard} narrativeColor={narrativeColor} />
          ) : <Loader />}
        </div>
      </div>

      <SentimentBar data={narrativeShares.sentiment} entity={report} entityType={'report'}/>

      <div className="report-section">
        <h3>{t('Views')}</h3>

        <div className="card">
          <div className="card-header">
            <div className='cardControlls'>
              {select}
              <DropdownMenu 
                icon={<SettingsIcon/>}
                isOpen={isTrendChartViewsMenuOpen}
                onClick={() => setIsTrendChartViewsMenuOpen(!isTrendChartViewsMenuOpen)}
                menuClassName='chartMenu'
                header={t('Chart view')}
              >
                <Radiobutton 
                  itemsList={viewOptions}
                  current={chartViewOptionsViews.value}
                  onChange={(value) => setChartViewOptionsViews(mapViewOptions[value])}
                />

                <ButtonsList
                  itemsList={[{value: 'PNG', name: t('Download as PNG')}, {value: 'SVG', name: t('Download as SVG')}]}
                  header={t('Download chart')}
                  onClick={(value) => {
                    let name = `${report.name}-evolution-chart-views-${statsAggregation.label}`
                    buildImageUrl(trendChartRefViews, trendChartCanvasRefViews, value, name)
                  }}
                />
                
              </DropdownMenu>
            </div>
          </div>
          <div className="card-body">
            <TrendChart
              rawdata={narrativeShares?.objects}
              metric="impressions"
              narrativeColor={narrativeColor}
              svgRef={trendChartRefViews}
              canvasRef={trendChartCanvasRefViews}
              view={chartViewOptionsViews}
            />
          </div>
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Percentage')}</h3>

        <div className="card">
          <div className="card-header">
            <div className='cardControlls'>
              {select}
                <DropdownMenu 
                  icon={<SettingsIcon/>}
                  isOpen={isTrendChartShareMenuOpen}
                  onClick={() => setIsTrendChartShareMenuOpen(!isTrendChartShareMenuOpen)}
                  menuClassName='chartMenu'
                  header={t('Chart view')}
                >
                  <Radiobutton 
                    itemsList={viewOptions}
                    current={chartViewOptionsShare.value}
                    onChange={(value) => setChartViewOptionsShare(mapViewOptions[value])}
                  />

                  <ButtonsList
                    itemsList={[{value: 'PNG', name: t('Download as PNG')}, {value: 'SVG', name: t('Download as SVG')}]}
                    header={t('Download chart')}
                    onClick={(value) => {
                      let name = `${report.name}-evolution-chart-share-${statsAggregation.label}`
                      buildImageUrl(trendChartRefShare, trendChartCanvasRefShare, value, name)
                    }}
                  />
                  
                </DropdownMenu>
            </div>
          </div>
          <div className="card-body">
            <TrendChart
              rawdata={narrativeShares?.objects}
              metric="narrative_share"
              narrativeColor={narrativeColor}
              svgRef={trendChartRefShare}
              canvasRef={trendChartCanvasRefShare}
              view={chartViewOptionsShare}
            />
          </div>
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Number of reactions')}</h3>

        <div className="card">
          <div className="card-header">
            <div className='cardControlls'>
              {select}
              <DropdownMenu 
                icon={<SettingsIcon/>}
                isOpen={isTrendChartEngagementMenuOpen}
                onClick={() => setIsTrendChartEngagementMenuOpen(!isTrendChartEngagementMenuOpen)}
                menuClassName='chartMenu'
                header={t('Chart view')}
              >
                <Radiobutton 
                  itemsList={viewOptions}
                  current={chartViewOptionsEngagement.value}
                  onChange={(value) => setChartViewOptionsEngagement(mapViewOptions[value])}
                />

                <ButtonsList
                  itemsList={[{value: 'PNG', name: t('Download as PNG')}, {value: 'SVG', name: t('Download as SVG')}]}
                  header={t('Download chart')}
                  onClick={(value) => {
                    let name = `${report.name}-evolution-chart-reactions-${statsAggregation.label}`
                    buildImageUrl(trendChartRefEngagement, trendChartCanvasRefEngagement, value, name)
                  }}
                />
                
              </DropdownMenu>
            </div>
          </div>
          <div className="card-body">
            <TrendChart
              rawdata={narrativeShares?.objects}
              metric="engagement"
              narrativeColor={narrativeColor}
              svgRef={trendChartRefEngagement}
              canvasRef={trendChartCanvasRefEngagement}
              view={chartViewOptionsEngagement}
            />
          </div>
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Number of publications')}</h3>

        <div className="card">
          <div className="card-header">
            <div className='cardControlls'>
              {select}
              <DropdownMenu 
                icon={<SettingsIcon/>}
                isOpen={isTrendChartPublicationsMenuOpen}
                onClick={() => setIsTrendChartPublicationsMenuOpen(!isTrendChartPublicationsMenuOpen)}
                menuClassName='chartMenu'
                header={t('Chart view')}
              >
                <Radiobutton 
                  itemsList={viewOptions}
                  current={chartViewOptionsPublications.value}
                  onChange={(value) => setChartViewOptionsPublications(mapViewOptions[value])}
                />

                <ButtonsList
                  itemsList={[{value: 'PNG', name: t('Download as PNG')}, {value: 'SVG', name: t('Download as SVG')}]}
                  header={t('Download chart')}
                  onClick={(value) => {
                    let name = `${report.name}-evolution-chart-publications-${statsAggregation.label}`
                    buildImageUrl(trendChartRefPublications, trendChartCanvasRefPublications, value, name)
                  }}
                />
                
              </DropdownMenu>
            </div>
            
          </div>
          <div className="card-body">
            <TrendChart
              rawdata={narrativeShares?.objects}
              metric="publications"
              narrativeColor={narrativeColor}
              svgRef={trendChartRefPublications}
              canvasRef={trendChartCanvasRefPublications}
              view={chartViewOptionsPublications}
            />
          </div>
        </div>
      </div>

      <div className={report?.parameters?.narrative_ids?.length < 2 ? "card card-messageswraper-show" : "card card-messageswraper"}>
        <div className="card-header">
          <h2>{t('Messages')}</h2>
          <Select
            onChange={(value) => {
              setCurrentNarrative({id: value.value, name: value.label});
              setPage(1);
            }}
            value={{
              value: currentNarrative.id,
              label: currentNarrative.name,
            }}
            options={narrativeShares?.objects.map((narrative) => {
              return { value: narrative.id, label: narrative.name };
            })}
          />
        </div>
          {(messages) ? (
            messages.objects.length > 0 ? <table className={messageStyles.extendedMessages}>
            <thead>
              <tr>
                {/* <td className='message-controlls'>
                  {(currentUser?.is_super_admin === true || (!isShare && ((narrative.is_threat && narrative.review_status === 'PENDING') || !narrative.is_threat))) ? <CheckboxField
                    onChange={() => toggleAll(messages, selectedMessages)}
                    checked={
                      areAllIdsSelected(messages.objects, selectedMessages)
                      // Object.keys(selectedMessages).length === messages.objects.length
                    }
                  /> : ''}
                </td> */}
                <td className={messageStyles.headerInfo}>
                  <span>{t('Total messages')}: {formatNumber(messages.total)}</span>
                </td>
                <td className='message-controlls'>
                  <DropdownMenu 
                      buttonName={t('Highlights')}
                      isOpen={highlightsMenu}
                      onClick={() => setHighlightsMenu(!highlightsMenu)}
                      menuClassName='highlights'
                      icon={<ChevronIcon/>}
                    >
                      <Multichoice
                        itemsList={nerTypesList}
                        selected={highlights}
                        onChange={selectedList => {
                          setHighlights(selectedList);
      
                          ampli.track({
                            event_type: 'Change highlights settings',
                            event_properties: {
                              user_id: currentUser?.id,
                              workspace_id: currentUser?.workspace_id,
                              report_id: report.id,
                              highlights: selectedList,
                            }
                          });
                        }}
                      />
                  </DropdownMenu>
                </td>
              </tr>
            </thead>
            <tbody>
              {messages.objects.map(message => {
                let followers_cnt = message?.source?.audience;
                let textClass = 'extendedMessage';
                let textClassParent = messageStyles.extendedMessage;

                if (message.parent_message?.error && message.parent_message?.isTranslation) {
                  textClassParent = `${messageStyles.extendedMessage} ${messageStyles.error}`
                } else if (message.error && !message.isTranslation) {
                  textClassParent = messageStyles.extendedMessage;
                }
      
                if(message.error && message.isTranslation) {
                  textClass = `${messageStyles.extendedMessage} ${messageStyles.error}`
                } else if (message.error && !message.isTranslation) {
                  textClass = messageStyles.extendedMessage;
                }

                const textForTranslation = message.highlighted_text ? replaceNewlinesWithBreaks(message.highlighted_text) : replaceNewlinesWithBreaks(message.text);
      
                // const narrartiveMessage = {
                //   narrative: narrative,
                //   page: page,
                //   searchQuery: searchQuery,
                //   sorting: sorting, 
                //   statuses: statuses
                // }
      
                return(
                  <tr key={message.id}>
                    {/* <td className='message-controlls'>
                    </td> */}
                    <td>
                      <div className={messageStyles.messageInfoWrapper}>
                        <div className={messageStyles.messageInfo}>
                          {message.content_type ? <div className={messageStyles.contentType}>
                            <Tooltip content={t(contentTypesMapping[message.content_type])} position='bottom'>
                              {contentTypesIconsMapping[message.content_type]}
                            </Tooltip>
                          </div> : ''}
                          <div className={messageStyles.date}>
                            <span  className={messageStyles.anchor}>
                              <Tooltip content={t('Publication date')} position='bottom'><CalendarIcon /></Tooltip>
                            </span>
                            {format(parseISO(message.date_publicated + 'Z'), 'dd LLL yyyy HH:mm',)}
                          </div>
                          <div className={messageStyles.platform}>
                            <span className={messageStyles.anchor}>
                              <Tooltip content={platformNamesMapping[message.source.source_type]} position='bottom'>
                                {platformIconsMapping[message.source.source_type]}
                              </Tooltip>
                            </span>
                            {followers_cnt ? <span className={messageStyles.anchor}>
                                <Tooltip content={t('Followers')} position='bottom'>
                                  <FollowersIcon />
                                </Tooltip>
                                <span>{formatNumberSignificant(followers_cnt)}</span>
                              </span> : ''}
                          </div>
                          <div className={messageStyles.sourceActorWrapper}>
                            <span className={messageStyles.anchor}>
                              <Tooltip content={decodeSourceName(message.source.name)} position='bottom'>
                                <ActorIcon />
                                <span className={messageStyles.cutText}>
                                  {isShare ? decodeSourceName(message.source.name) : message.source ? (
                                    <Link 
                                      to={`/sources/${message.source?.id}`}
                                      onClick={() => {
                                        ampli.track({
                                          event_type: 'Open source profile',
                                          event_properties: {
                                            user_id: currentUser?.id,
                                            workspace_id: currentUser?.workspace_id,
                                            source_id: message.source.id,
                                          }
                                        });
                                      }}
                                    >
                                      {decodeSourceName(message.source.name)}
                                    </Link>
                                  ) : t('N/A')}
                                </span>
                              </Tooltip>
                              { message.actor && message.actor.id !== message.source?.id ?
                                <Tooltip content={decodeSourceName(message.actor?.name)} position='bottom'>
                                  <SourceIcon />
                                  <span className={messageStyles.cutText}>
                                  {<Link to={`/sources/${message.actor?.id}`}>{decodeSourceName(message.actor?.name)}</Link>}
                                  </span>
                                </Tooltip> : ''}
                              <a 
                                href={message.url}
                                className={messageStyles.externalLink}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                  ampli.track({
                                    event_type: 'Open external message link',
                                    event_properties: {
                                      user_id: currentUser?.id,
                                      workspace_id: currentUser?.workspace_id,
                                    }
                                  });
                                }}
                              >
                                <LinkIcon/>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className={'open-message-popup ' + messageStyles.modalIcon}>
                          <ExpandIcon
                            onClick={() => {
                              ampli.track({
                                event_type: 'Open message in a pop-up',
                                event_properties: {
                                  user_id: currentUser?.id,
                                  workspace_id: currentUser?.workspace_id,
                                  report_id: report.id,
                                }
                              });
                              setMessageModal({message: message, isOpen: true, narrartiveMessage: null});
                            }}
                          /> 
                        </div>
                      </div>
                      
                      <div className={messageStyles.messageContainer}>
                        <div className={messageStyles.messageMetricsWrapper}>
                          <div className={messageStyles.messageMetrics}>
                            {message.sentiment_score !== null ? <Tooltip content={t(getSentimentName(message.sentiment_score))} position='bottom'>
                              {getSentimentIcon(message.sentiment_score)}
                            </Tooltip> : ''}
                            <span className={messageStyles.anchor}>
                              <Tooltip content={t('Views')} position='bottom'>
                                <ViewsIcon/>
                              </Tooltip>
                              {formatNumberSignificant(message.impressions)}
                            </span>
                            <span className={messageStyles.anchor}>
                              <Tooltip content={t('Reactions')} position='bottom'>
                                <EngagementIcon />
                              </Tooltip>
                              {formatNumberSignificant(message.engagement)}
                            </span>
                            <span className={messageStyles.anchor}>
                              <Tooltip content={t('Manipulation')} position='bottom'>
                                <ManipulationIcon />
                              </Tooltip>
      
                              {message.manipulation_index ? message.manipulation_index.toFixed(2) : '0'}
                              </span>
                            {message.similarity_score ?
                            <span className={messageStyles.anchor}>
                              <Tooltip content={t('Similarity1')} position='bottom'>
                                <SimilarityIcon />
                              </Tooltip>
                              {(message.similarity_score + "").slice(0, 4)}
                              </span>
                            : ''}
                          </div>
                          <div className='searchIconWrapper'>
                            <Tooltip content={ t('Find similar messages') } position='bottom'>
                              <span className={messageStyles.cutText}>
                                <Link to={`/search?similar_to=${message.composite_id}`} onClick={() => window.location.href = `/search?similar_to=${message.composite_id}` }><SearchIcon /></Link>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        {message.content_type === 'COMMENT' &&
                          message.parent_message ? (
                            <div className={narrativeStyles.originalTextBlock}>
                              {message.isTranslation === true &&
                              !message.parent_message?.translated ? (
                                <div
                                  className={narrativeStyles.loaderContainer}
                                >
                                  <LoaderSmall />
                                </div>
                              ) : (
                                <>
                                  <Tooltip
                                    content={decodeSourceName(
                                      message.source?.name,
                                    )}
                                    position="bottom"
                                  >
                                    <span
                                      className={messageStyles.sourceLink}
                                    >
                                      {message.source ? (
                                        <Link
                                          to={`/sources/${message.source?.id}`}
                                          onClick={() => {
                                            ampli.track({
                                              event_type: 'Open source profile',
                                              event_properties: {
                                                user_id: currentUser?.id,
                                                workspace_id:
                                                  currentUser?.workspace_id,
                                                source_id: message.source?.id,
                                              },
                                            });
                                          }}
                                        >
                                          {decodeSourceName(
                                            message.source.name,
                                          )}
                                        </Link>
                                      ) : (
                                        t('N/A')
                                      )}
                                    </span>
                                  </Tooltip>
                                  {message?.parent_message?.images && message?.parent_message?.images?.length > 0 ? <ImagesContainer images={message?.parent_message?.images} showCount={3} /> : ''}
                                  {message.parent_message?.text ? (
                                    <ExpandableText
                                      length={100}
                                      text={
                                        message.isTranslation
                                          ? message.parent_message?.translated
                                          : message.parent_message?.text
                                      }
                                      textClassName={textClassParent}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        {message?.images && message?.images?.length > 0 ? <ImagesContainer images={message?.images} showCount={3} className={narrativeStyles.imagesContainer} /> : ''}
                        <div className={messageStyles.textBlock}>
                          {message.isTranslation === true && !message.translated ?  
                          <div className={messageStyles.loaderContainer}><LoaderSmall /></div> : 
                          <ExpandableText
                            length={250}
                            highlights={highlights}
                            text={message.isTranslation ? message.translated : message.highlighted_text || message.text}
                            textClassName={textClass}
                            onExpand={(expanded) => {
                              ampli.track({
                                event_type: (expanded) ? 'Expand message in-place' : 'Collapse message in-place',
                                event_properties: {
                                  user_id: currentUser?.id,
                                  workspace_id: currentUser?.workspace_id,
                                  report_id: report.id,
                                }
                              });
                            }}
                          />}
                        
                        </div>
                      </div>
                    </td>
                    <td className='message-controlls'>
                      <div className={messageStyles.translationWrapper}>
                        <span 
                          className={message?.isTranslation ? '' : messageStyles.active}
                          onClick={() =>  handleTranslation(message.id, textForTranslation, false)}
                        >
                          <OriginalTextIcon />
                        </span>
                        <span 
                          className={message?.isTranslation ? messageStyles.active : '' }
                          onClick={() => handleTranslation(message.id, textForTranslation, true)}
                        >
                          <TranslationIcon />
                        </span> 
      
                      </div>
                      
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table> : <div className='noData'>{t('There is no data to display')}</div>
          ) : <Loader /> }
        </div>
      {/* </div> */}

      {(messages) ? (
        <Paginator
          size={messages.size}
          page={messages.page}
          total={messages.total}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      ) : ''}

      <ExportReportPopup
        isVisible={exportModal.isActive}
        onClose={() => setExportModal({ isActive: false, id: null })}
        reportID={reportId}
      />

    {messageModal.isOpen ? <MessageModal 
      message={messageModal.message}
      isOpen={messageModal.isOpen}
      onChange={(bool, selected) => {
        setMessageModal({message: null, isOpen: bool, narrartiveMessage: null})
        setModalHighlights(selected)
      }}
      narrativeMessage={messageModal.narrartiveMessage}
      selected={modalHighlights?.length === 0 ? highlights : modalHighlights}
      // narrative={narrative}
      isShare={isShare}
    /> : ''}
    </div>
  );
}
