import Async from 'react-select/async';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import Modal from '../../components/Modal';
import { Loader } from '../../components/Loader/Loader';
import styles from './GroupsPage.module.scss';
import Switch from "../../components/Switch";
import {useCurrentUser} from "../../contexts/CurrentUser";

export const GroupEditModal = ({editModal, handleEdit, clearEdit}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const groupId = editModal?.group?.id;
  const [group, setGroup] = useState(null);
  const [name, setName] = useState(group?.name || '');
  const [privacySettings, setPrivacySettings] = useState(group?.visibility || 'PRIVATE');
  const [workspaces, setWorkspaces] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState(group?.workspace_ids || []);
  const [error, setError] = useState(null);

  useEffect(() => {
    API.fetch('GET', `/API/v1/groups/${groupId}`)
      .then((data) => {
        setGroup(data)
        setName(data?.name)
        setPrivacySettings(data?.visibility ? data?.visibility : 'PRIVATE')
        setSelectedWorkspaces(data?.workspace_ids)
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [groupId]);

  const fetchAllWorkspaces = useCallback(
    () => {
      if (!currentUser?.is_super_admin) {
        return;
      }
      const urlParams = new URLSearchParams();

      urlParams.set('size', '20');
      urlParams.set('sorting', '-id');

      API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
        (data) => {
          let workspaces = data.objects
            .map((workspace) => {
              return { value: workspace.id, label: workspace.name };
            });
          setWorkspaces(workspaces);
        },
      );
    },
    [],
  );

  useEffect(fetchAllWorkspaces, [fetchAllWorkspaces]);

  const fetchWorkspaces = useMemo(
    () =>
      debounce((inputValue, callback) => {
        if (!currentUser?.is_super_admin) {
          return;
        }
        const urlParams = new URLSearchParams();

        if (inputValue) {
          urlParams.set('q', inputValue);
        }

        API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
          (data) => {
            callback(
              data.objects
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((workspace) => {
                  return { value: workspace.id, label: workspace.name };
                }),
            );
          },
        );
      }, 1000),
    [],
  );

  const workspacesPromiseOptions = (inputValue) => {
    if (!inputValue) {
      return [];
    }
    return new Promise((resolve) => {
      fetchWorkspaces(inputValue, resolve);
    });
  };

  const fetchSelectedWorkspaces = (selectedWorkspaces) => {
    if (!currentUser?.is_super_admin) {
      return;
    }
    if (!selectedWorkspaces || selectedWorkspaces?.length === 0) {
      return [];
    }
    const urlParams = new URLSearchParams();
    selectedWorkspaces.forEach((workspace) => {
      urlParams.append('ids', workspace);
    });

    API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
      (data) => {
        let workspaces = data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((workspace) => {
            return { value: workspace.id, label: workspace.name };
          });
        setSelectedWorkspaces(workspaces);
      },
    );
  };

  useEffect(() => {
    fetchSelectedWorkspaces(group?.workspace_ids);
  }, [group]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name.length === 0) {
      setError('Please fill in this field')
      return
    }

    handleEdit(name, editModal.group, privacySettings, selectedWorkspaces)
  }

  const privacySettingsOptions = [
    {value: 'PRIVATE', label: t('private_female', {context: 'female'})},
    {value: 'PUBLIC', label: t('public_female', {context: 'female'})},
    {value: 'SHARABLE', label: t('sharable_female', {context: 'female'})},
  ];

  return(
    <Modal
      isVisible={editModal.isOpen}
      title={t('Edit actor group')}
      content={
        <form method="post" onSubmit={e => handleSubmit(e)} >
          <div className={error ? 'form-element error' : 'form-element'}>
            <label htmlFor="name">{t('Name')}</label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          {currentUser?.is_super_admin && (
            <div className="form-element">
              <label className={styles.label} htmlFor="privacy-settings">
                {t('Privacy settings')}
              </label>
              <Select
                id="privacy-settings"
                name="privacy-settings"
                options={privacySettingsOptions}
                value={privacySettingsOptions.find(
                  (option) => option.value === privacySettings,
                )}
                onChange={(option) => setPrivacySettings(option.value)}
              />
            </div>
          )}

          {currentUser?.is_super_admin && privacySettings === 'SHARABLE' && (
            <div className="form-element">
              <label className={styles.label} htmlFor="workspaces">
                {t('Workspaces')}
              </label>
              <Async
                cacheOptions
                defaultOptions={workspaces}
                isMulti={true}
                isClearable={true}
                loadOptions={workspacesPromiseOptions}
                value={selectedWorkspaces}
                placeholder={t('Please enter at least 1 symbol to search')}
                noOptionsMessage={() => t('Please enter at least 1 symbol')}
                loadingMessage={() => t('Loading...')}
                id='workspaces'
                onChange={(options) => {
                  setSelectedWorkspaces(options);
                }}
                required={currentUser?.is_super_admin && privacySettings === 'SHARABLE'}
              />
            </div>
          )}
          <div className="error-message">{t(error)}</div>
        </form>
      }
      footer={
        <>
          <button className="new-button" onClick={(e) => handleSubmit(e)}>
            {t('Save')}
          </button>
          <button className="btn-reset" onClick={() => clearEdit()}>
            {t('Cancel')}
          </button>
        </>
      }
      onClose={clearEdit}
    />
  )
}