import * as d3 from 'd3';
import { ampli } from '../../../ampli';
import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { API } from '../../../API';
import { Paginator } from '../../../components/Paginator/Paginator';
import {
  Feedback,
  feedbackContexts,
} from '../../../components/Feedback/Feedback';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../../assets/followers.svg';
import { ReactComponent as TranslationIcon } from '../../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../../assets/originalText.svg';
import { ReactComponent as ActorIcon } from '../../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../../pages/SearchPage/assets/person.svg';
import { ReactComponent as AddToIcon } from '../../../assets/addTo.svg';
import { ReactComponent as ShareThreatIcon } from '../../../assets/shareThreat.svg';
import { ReactComponent as SearchIcon } from '../../../pages/SearchPage/assets/search.svg';
import { decodeSourceName } from '../../../utils/decodeURI';
import {
  platformIconsMapping,
  platformNamesMapping,
} from '../../../utils/platforms';
import {
  contentTypesIconsMapping,
  contentTypesMapping,
} from '../../../utils/contentTypes';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { getSentimentName, getSentimentIcon } from '../../../utils/sentiment';
import { useCurrentUser } from '../../../contexts/CurrentUser';
import { format, parseISO } from 'date-fns';
import { ExpandableText } from '../../../components/CollapsibleText/ExpandableText';
import { Loader } from '../../../components/Loader/Loader';
import { LoaderSmall } from '../../../components/LoaderSmall/LoaderSmall';
import { replaceNewlinesWithBreaks } from '../../../utils/replaceNewlinesWithBreaks';
import { ImagesContainer } from '../../../components/Image/ImagesContainer';

import messageStyles from '../../../components/MessagesTable/ExtendedMessageView.module.scss';

import styles from '../NarrativePage.module.scss';

const sortingFieldNames = {
  DATE: 'date_publicated',
  SOURCE: 'source',
  MESSAGE: 'text',
  VIEWS: 'impressions',
  REACTIONS: 'engagement',
  MANIPULATION_INDEX: 'manipulation_index',

  AGE: 'user_age',
  LOCATION: 'user_location',
  GROUPS: 'user_groups',
};

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const NarrativeStoriesModal = ({
  infoModal,
  type = null,
  subType = null,
  countryId = null,
  narrative,
  onChange,
  isShare = false,
  onAddToCase,
  onAddToThreat,
}) => {
  const { t } = useTranslation();

  const sourcesTypes = [
    { value: null, label: t('All') },
    { value: 'is_verified', label: t('Verified') },
    { value: 'influential', label: t('Influential') },
    { value: 'state_affiliated', label: t('State-affiliated') },
    { value: 'discreditated', label: t('Compromised accounts') },
    { value: 'is_bot', label: t('Bots') },
  ];

  const platformOptions = [
    { value: null, label: t('All') },
    { value: 'TELEGRAM', label: platformNamesMapping['TELEGRAM'] },
    { value: 'TIKTOK', label: platformNamesMapping['TIKTOK'] },
    { value: 'VK', label: platformNamesMapping['VK'] },
    { value: 'TWITTER', label: platformNamesMapping['TWITTER'] },
    { value: 'WEB', label: platformNamesMapping['WEB'] },
    
  ];

  const [currentUser] = useCurrentUser();
  const nativeLang = currentUser?.translation_language ? currentUser?.translation_language : window.clientInformation.language.split('-', 1)[0].toUpperCase();

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  const [platform, setPlatform] = useState(() => platformOptions[0]);
  const [sourceType, setSourceType] = useState(type || sourcesTypes[0]);
  const [countryID, setCountryID] = useState(countryId || null);
  const [sourceSubType, setSourceSubType] = useState(subType || null);
  const [messages, setMessages] = useState(null);
  const [messagesSorting, setMessagesSorting] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const statusMapping = {
    NEW: { class: messageStyles.new, name: 'New' },
    READ: { class: messageStyles.approved, name: 'Approved' },
    DELETED: { class: messageStyles.deleted, name: 'Deleted' },
  };

  const nerTypesList = [{ value: 'KEYWORDS', label: t('Matched keywords') }];

  if (showNer) {
    nerTypesList.push(
      { value: 'PERSON', label: t('Persons') },
      { value: 'ORGANIZATION', label: t('Organizations') },
      { value: 'LOCATION', label: t('Locations') },
    );
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);

  const fetchMessages = useCallback(
    (
      sourceType,
      sourceSubType,
      platform,
      countryID,
      messagesSorting,
      page = 1,
    ) => {
      if (!narrative) {
        return;
      }

      setIsLoading(true);

      const urlParams = new URLSearchParams();
      urlParams.set('narrative_id', narrative.id);
      urlParams.set('size', '10');
      urlParams.set('page', page);
      urlParams.set('narrative_story_id', infoModal.id);

      const messagesSortingQuery = messagesSorting
        ? `${messagesSorting.isAscending ? '' : '-'}${
            messagesSorting.fieldName
          }`
        : null;

      if (messagesSorting) {
        urlParams.set('sorting', messagesSortingQuery);
      }

      if (sourceType.value) {
        urlParams.set(sourceType.value, true);
      }

      if (sourceSubType) {
        if (sourceType.value === 'discreditated') {
          urlParams.set('entity_type', sourceSubType);
        } else {
          urlParams.set(sourceSubType, true);
        }
      }

      if (platform.value) {
        urlParams.set('source_types', platform.value);
      }

      if (countryID) {
        urlParams.set('country_id', countryID);
      }

      API.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then(
        (data) => {
          setMessages(data);
          setIsLoading(false);
        },
      );
    },
    [],
  );

  useEffect(() => {
    fetchMessages(
      sourceType,
      sourceSubType,
      platform,
      countryID,
      messagesSorting,
      page,
    );
  }, [platform, sourceSubType, sourceType, countryID, messagesSorting, page]);

  const select = (
    <Select
      className="chart-select"
      onChange={(e) => {
        setPlatform(e);
        setCountryID(null);
        setSourceSubType(null);
      }}
      value={platform}
      options={platformOptions}
    />
  );

  const typesSelect = (
    <Select
      className={styles.chartSelect}
      classNamePrefix="chartSelect"
      onChange={(e) => {
        setSourceType(e);
        setCountryID(null);
        setSourceSubType(null);
      }}
      value={sourceType}
      options={sourcesTypes}
    />
  );

  const handleMessagesSortingClick = (fieldName) => {
    const isAscending =
      messagesSorting && messagesSorting.fieldName === fieldName
        ? !messagesSorting.isAscending
        : true;

    setMessagesSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  const deleteStoryMessage = (narrativeId, storyId, messageId) => {
    API.fetch(
      'DELETE',
      `/API/v1/narrative_stories/${narrativeId}/stories/${storyId}/messages/${messageId}`,
    )
      .then(() => {
        fetchMessages(
          sourceType,
          sourceSubType,
          platform,
          countryID,
          messagesSorting,
          page,
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const translateMessage = (messageId, text, nativeLang, sourceLang = '') => {
    let body;
    if (sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang,
      };
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      };
    }
    return API.fetch('POST', '/API/v1/translations/translate', null, body);
  };

  const toggleTranslate = (messageId, isTranslation) => {
    setMessages({
      ...messages,
      objects: messages.objects.map((message) => {
        if (message.id === messageId) {
          message.isTranslation = isTranslation;
          if (message.content_type === 'COMMENT' && message.parent_message) {
            message.parent_message.isTranslation = isTranslation;
          }
          return message;
        } else {
          return message;
        }
      }),
    });
  };

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find((message) => message.id === messageId);
    const originalPostText =
      target?.content_type === 'COMMENT' ? target?.parent_message?.text : '';
    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      toggleTranslate(messageId, true);

      const nativeLanguage = currentUser?.translation_language
        ? currentUser?.translation_language
        : narrative.parameters?.translate_keywords_query
        ? narrative.parameters?.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];

      let newMessagesList = [...messages.objects];

      if (originalPostText) {
        Promise.allSettled([
          translateMessage(messageId, originalPostText, nativeLanguage),
          translateMessage(messageId, text, nativeLanguage),
        ]).then((results) => {
          if (results[0].status === 'fulfilled') {
            const originalPostData = results[0].value;

            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                let m = {
                  ...message,
                  parent_message: {
                    ...message.parent_message,
                    isTranslation: true,
                    translated: originalPostData.destination_text,
                  },
                };
                return m;
              } else {
                return message;
              }
            });
          } else {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  parent_message: {
                    ...message.parent_message,
                    isTranslation: true,
                    translated: t('We were not able to translate this text.'),
                    error: true,
                  },
                };
              } else {
                return message;
              }
            });
          }

          if (results[1].status === 'fulfilled') {
            const messageData = results[1].value;
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: messageData.destination_text,
                };
              } else {
                return message;
              }
            });
          } else {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: t('We were not able to translate this text.'),
                  error: true,
                };
              } else {
                return message;
              }
            });
          }

          setMessages({ ...messages, objects: newMessagesList });
        });
      } else {
        translateMessage(messageId, text, nativeLanguage)
          .then((data) => {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: data.destination_text,
                };
              } else {
                return message;
              }
            });

            setMessages({ ...messages, objects: newMessagesList });
          })
          .catch((e) => {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: t('We were not able to translate this text.'),
                  error: true,
                };
              } else {
                return message;
              }
            });

            setMessages({ ...messages, objects: newMessagesList });
          });
      }
    }
  };

  return (
    <Modal
      isVisible={infoModal.isActive}
      title={`${infoModal.title}...`}
      content={
        <>
          {/* <div className={styles.modalSelects}>
            {select}
            {typesSelect}
          </div> */}

          {!messages || isLoading ? (
            <Loader />
          ) : messages.objects.length > 0 ? (
            <div>
              <table className={messageStyles.extendedMessages} id='storiesModal'>
                <thead>
                  <tr>
                    <td className={messageStyles.headerInfo}>
                      <span>
                        {t('Total messages')}: {formatNumber(messages.total)}
                      </span>
                    </td>
                    <td className="message-controlls"></td>
                  </tr>
                </thead>
                <tbody>
                  {messages.objects.map((message) => {
                    let followers_cnt = message?.source?.audience;
                    let textClass = styles.extendedMessage;
                    let textClassParent = messageStyles.extendedMessage;

                    if (message.parent_message?.error && message.parent_message?.isTranslation) {
                      textClassParent = `${messageStyles.extendedMessage} ${messageStyles.error}`
                    } else if (message.error && !message.isTranslation) {
                      textClassParent = messageStyles.extendedMessage;
                    }

                    if (message.error && message.isTranslation) {
                      textClass = `${styles.extendedMessage} ${styles.error}`;
                    } else if (message.error && !message.isTranslation) {
                      textClass = styles.extendedMessage;
                    }

                    const textForTranslation = message.highlighted_text ? replaceNewlinesWithBreaks(message.highlighted_text) : replaceNewlinesWithBreaks(message.text);

                    return (
                      <tr key={message.id}>
                        <td>
                          <div className={messageStyles.messageInfoWrapper}>
                            <div className={messageStyles.messageInfo}>
                              <div
                                className={`${messageStyles.status} ${
                                  statusMapping[message.status].class
                                }`}
                              >
                                {t(statusMapping[message.status].name)}
                              </div>
                              {message.content_type ? (
                                <div className={messageStyles.contentType}>
                                  <Tooltip
                                    content={t(
                                      contentTypesMapping[message.content_type],
                                    )}
                                    position="bottom"
                                  >
                                    {
                                      contentTypesIconsMapping[
                                        message.content_type
                                      ]
                                    }
                                  </Tooltip>
                                </div>
                              ) : (
                                ''
                              )}
                              <div className={messageStyles.date}>
                                <span className={messageStyles.anchor}>
                                  <Tooltip
                                    content={t('Publication date')}
                                    position="bottom"
                                  >
                                    <CalendarIcon />
                                  </Tooltip>
                                </span>
                                {format(
                                  parseISO(message.date_publicated + 'Z'),
                                  'dd LLL yyyy HH:mm',
                                )}
                              </div>
                              <div className={messageStyles.platform}>
                                <span className={messageStyles.anchor}>
                                  <Tooltip
                                    content={
                                      platformNamesMapping[
                                        message.source.source_type
                                      ]
                                    }
                                    position="bottom"
                                  >
                                    {
                                      platformIconsMapping[
                                        message.source.source_type
                                      ]
                                    }
                                  </Tooltip>
                                </span>
                                {followers_cnt ? (
                                  <span className={messageStyles.anchor}>
                                    <Tooltip
                                      content={t('Followers')}
                                      position="bottom"
                                    >
                                      <FollowersIcon />
                                    </Tooltip>
                                    <span>
                                      {formatNumberSignificant(followers_cnt)}
                                    </span>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className={messageStyles.sourceActorWrapper}>
                                <span className={messageStyles.anchor}>
                                  <Tooltip
                                    content={decodeSourceName(
                                      message.source.name,
                                    )}
                                    position="bottom"
                                  >
                                    <ActorIcon />
                                    <span className={messageStyles.cutText}>
                                      {isShare ? (
                                        decodeSourceName(message.source.name)
                                      ) : message.source ? (
                                        <Link
                                          to={`/sources/${message.source?.id}`}
                                          onClick={() => {
                                            ampli.track({
                                              event_type: 'Open source profile',
                                              event_properties: {
                                                user_id: currentUser?.id,
                                                workspace_id:
                                                  currentUser?.workspace_id,
                                                source_id: message.source?.id,
                                              },
                                            });
                                          }}
                                        >
                                          {decodeSourceName(
                                            message.source.name,
                                          )}
                                        </Link>
                                      ) : (
                                        t('N/A')
                                      )}
                                    </span>
                                  </Tooltip>
                                  {message.actor &&
                                  message.actor.id !== message.source?.id ? (
                                    <Tooltip
                                      content={decodeSourceName(
                                        message.actor?.name,
                                      )}
                                      position="bottom"
                                    >
                                      <SourceIcon />
                                      <span className={messageStyles.cutText}>
                                        {
                                          <Link
                                            to={`/sources/${message.actor?.id}`}
                                            onClick={() => {
                                              ampli.track({
                                                event_type: 'Open source profile',
                                                event_properties: {
                                                  user_id: currentUser?.id,
                                                  workspace_id:
                                                    currentUser?.workspace_id,
                                                  source_id: message.source?.id,
                                                },
                                              });
                                            }}
                                          >
                                            {decodeSourceName(
                                              message.actor?.name,
                                            )}
                                          </Link>
                                        }
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                  <a
                                    href={message.url}
                                    className={messageStyles.externalLink}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <LinkIcon />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className={messageStyles.messageContainer}>
                            <div
                              className={messageStyles.messageMetricsWrapper}
                            >
                              <div className={messageStyles.messageMetrics}>
                                {message.sentiment_score !== null ? (
                                  <Tooltip
                                    content={t(
                                      getSentimentName(message.sentiment_score),
                                    )}
                                    position="bottom"
                                  >
                                    {getSentimentIcon(message.sentiment_score)}
                                  </Tooltip>
                                ) : (
                                  ''
                                )}
                                <span className={messageStyles.anchor}>
                                  <Tooltip
                                    content={t('Views')}
                                    position="bottom"
                                  >
                                    <ViewsIcon />
                                  </Tooltip>
                                  {formatNumberSignificant(message.impressions)}
                                </span>
                                <span className={messageStyles.anchor}>
                                  <Tooltip
                                    content={t('Reactions')}
                                    position="bottom"
                                  >
                                    <EngagementIcon />
                                  </Tooltip>
                                  {formatNumberSignificant(message.engagement)}
                                </span>
                                {showMessageManipulationIndex ? (
                                  <span className={messageStyles.anchor}>
                                    <Tooltip
                                      content={t('Manipulation')}
                                      position="bottom"
                                    >
                                      <ManipulationIcon />
                                    </Tooltip>

                                    {message.manipulation_index
                                      ? message.manipulation_index.toFixed(2)
                                      : '0'}
                                  </span>
                                ) : (
                                  ''
                                )}
                                {message.similarity_score ? (
                                  <span className={messageStyles.anchor}>
                                    <Tooltip
                                      content={t('Similarity1')}
                                      position="bottom"
                                    >
                                      <SimilarityIcon />
                                    </Tooltip>
                                    {(message.similarity_score + '').slice(
                                      0,
                                      4,
                                    )}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            {message.content_type === 'COMMENT' &&
                            message.parent_message ? (
                              <div className={styles.originalTextBlock}>
                                {message.isTranslation === true &&
                                !message.parent_message?.translated ? (
                                  <div
                                    className={styles.loaderContainer}
                                  >
                                    <LoaderSmall />
                                  </div>
                                ) : (
                                  <>
                                    <Tooltip
                                      content={decodeSourceName(
                                        message.source?.name,
                                      )}
                                      position="bottom"
                                    >
                                      <span
                                        className={messageStyles.sourceLink}
                                      >
                                        {isShare ? (
                                          decodeSourceName(message.source?.name)
                                        ) : message.source ? (
                                          <Link
                                            to={`/sources/${message.source?.id}`}
                                            onClick={() => {
                                              ampli.track({
                                                event_type: 'Open source profile',
                                                event_properties: {
                                                  user_id: currentUser?.id,
                                                  workspace_id:
                                                    currentUser?.workspace_id,
                                                  source_id: message.source?.id,
                                                },
                                              });
                                            }}
                                          >
                                            {decodeSourceName(
                                              message.source.name,
                                            )}
                                          </Link>
                                        ) : (
                                          t('N/A')
                                        )}
                                      </span>
                                    </Tooltip>

                                    {message?.parent_message?.images && message?.parent_message?.images?.length > 0 ? <ImagesContainer images={message?.parent_message?.images} showCount={3} /> : ''}

                                    {message.parent_message?.text ? (
                                      <ExpandableText
                                        length={100}
                                        text={
                                          message.isTranslation
                                            ? message.parent_message?.translated
                                            : message.parent_message?.text
                                        }
                                        textClassName={textClassParent}
                                        onExpand={(expanded) => {
                                          ampli.track({
                                            event_type: expanded
                                              ? 'Expand original post in-place'
                                              : 'Collapse original post in-place',
                                            event_properties: {
                                              user_id: currentUser?.id,
                                              workspace_id: currentUser?.workspace_id,
                                              narrative_id: narrative?.id,
                                            },
                                          });
                                        }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              ''
                            )}

                            {message?.images && message?.images?.length > 0 ? <ImagesContainer images={message?.images} showCount={3} className={styles.imagesContainer}/> : ''}

                            <div className={messageStyles.textBlock}>
                              {message.isTranslation === true &&
                              !message.translated ? (
                                <div className={messageStyles.loaderContainer}>
                                  <LoaderSmall />
                                </div>
                              ) : (
                                <ExpandableText
                                  length={100}
                                  highlights={highlights}
                                  text={
                                    message.isTranslation
                                      ? message.translated
                                      : message.highlighted_text || message.text
                                  }
                                  textClassName={textClass}
                                  onExpand={(expanded) => {
                                    ampli.track({
                                      event_type: expanded
                                        ? 'Expand original post in-place'
                                        : 'Collapse original post in-place',
                                      event_properties: {
                                        user_id: currentUser?.id,
                                        workspace_id: currentUser?.workspace_id,
                                        narrative_id: narrative?.id,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <div
                                className={messageStyles.similarMessagesSearch}
                              >
                                <Tooltip
                                  content={t('Find similar messages')}
                                  position="bottom"
                                >
                                  <span className={messageStyles.cutText}>
                                    <Link
                                      to={`/search?similar_to=${message.composite_id}`}
                                      onClick={() =>
                                        (window.location.href = `/search?similar_to=${message.composite_id}`)
                                      }
                                    >
                                      <SearchIcon />
                                    </Link>
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="message-controlls">
                          <div className={messageStyles.controls}>
                            <Feedback
                              context={feedbackContexts.STORIES_MESSAGE}
                              feedbackParams={{
                                narrative_id: narrative.id,
                                story_id: infoModal.id,
                                selected_message_id: message.id,
                              }}
                              negativeFeedbackCallback={() => {
                                deleteStoryMessage(
                                  narrative.id,
                                  infoModal.id,
                                  message.id,
                                );
                              }}
                            />
                          </div>
                          <div className={messageStyles.translationWrapper}>
                            <span
                              className={
                                message?.isTranslation
                                  ? ''
                                  : messageStyles.active
                              }
                              onClick={() =>
                                handleTranslation(
                                  message.id,
                                  textForTranslation,
                                  false,
                                )
                              }
                            >
                              <OriginalTextIcon />
                            </span>
                            <span
                              className={
                                message?.isTranslation
                                  ? messageStyles.active
                                  : ''
                              }
                              onClick={() =>
                                handleTranslation(
                                  message.id,
                                  textForTranslation,
                                  true,
                                )
                              }
                            >
                              <TranslationIcon />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.noData}>
              {t('There is no data to display')}
            </div>
          )}
        </>
      }
      footer={
        <div className={styles.modalControls}>
          <Paginator
            size={10}
            page={messages?.page}
            total={messages?.total}
            onPageChange={(page) => {
              setPage(page);
            }}
            id={'storiesModal'}
          />
          {isShare === true ? "" : <div className={styles.modalActions}>
            <Tooltip content={t('Add story to case')} position="top">
              <button
                className="new-button"
                onClick={() => {
                  onAddToCase({ [infoModal.id]: true });
                }}
              >
                <AddToIcon />
              </button>
            </Tooltip>

            <Tooltip content={t('Add story to threat')} position="left">
              <button
                className="new-button"
                onClick={() => {
                  onAddToThreat({ [infoModal.id]: true });
                }}
              >
                <ShareThreatIcon />
              </button>
            </Tooltip>
          </div>}
        </div>
      }
      onClose={() => {
        onChange(false);
      }}
      className={`${styles.chartTreeModal} ${styles.storiesModal}`}
    />
  );
};
