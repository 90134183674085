import { useState } from 'react';
import { ImageComponent } from './ImageComponent';
import { ImageCarousel } from './ImageCarousel';
import styles from './ImagesContainer.module.scss';

export const ImagesContainer = ({ images, showCount, className }) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);

  const handleImageClick = (index) => {
    setInitialIndex(index);
    setIsCarouselOpen(true);
  };

  return (
    <div className={className ? `${styles.imagesContainer} ${className}` : styles.imagesContainer}>
      {images?.slice(0, showCount).map((url, index) => (
        <ImageComponent key={index} url={url} onClick={() => handleImageClick(index)} />
      ))}
      {images.length > showCount && (
        <div
          className={`${styles.moreImages} ${showCount === 1 ? styles.small : ''}`}
          onClick={() => handleImageClick(showCount)}
        >
          <div className={styles.moreImagesText}>+{images.length - showCount} images</div>
        </div>
      )}
      {isCarouselOpen ? <ImageCarousel
        images={images}
        initialIndex={initialIndex}
        isOpen={isCarouselOpen}
        onClose={(bool) => {
          setIsCarouselOpen(bool);
          setInitialIndex(0);
        }}
      /> : ''}
    </div>
  );
};

