import cn from 'classnames';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { format, parseISO, set } from 'date-fns';
import {
  useNavigate,
  Link,
} from 'react-router-dom';
import { MessagesContext } from '../../pages/NarrativePage/contexts/MessagesContext';
import { useContext, useEffect, useState} from 'react';
import { decodeSourceName } from '../../utils/decodeURI';

import { ampli } from "../../ampli";
import { CheckboxField } from '../Checkbox/CheckboxField';
import { ExpandableText } from '../CollapsibleText/ExpandableText';
import { Paginator } from '../Paginator/Paginator';
import { Loader } from '../Loader/Loader';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { Multichoice } from '../DropdownMenu/Multichoice';
import { ReactComponent as ApproveIcon } from '../../pages/NarrativePage/assets/approve.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as RecoverIcon } from '../../assets/recover.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/envelope.svg';
import { ReactComponent as EnvelopeOpenIcon } from '../../assets/envelopeOpen.svg';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../assets/originalText.svg';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';
import { ReactComponent as ChevronIcon } from '../../pages/SearchPage/assets/chevron-down.svg';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';
import { ReactComponent as HeartSolidIcon } from '../../assets/heart-solid.svg';
import { ReactComponent as HeartRegularIcon } from '../../assets/heart-regular.svg';
import { ReactComponent as SearchIcon } from '../../pages/SearchPage/assets/search.svg';
import { ReactComponent as ImageIcon } from '../../assets/image.svg';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import { MessageModal } from './MessageModal';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { areAllIdsSelected } from '../../pages/NarrativePage/contexts/MessagesContext';
import { Sentiment } from '../Sentiment/Sentiment';
import { replaceNewlinesWithBreaks } from '../../utils/replaceNewlinesWithBreaks';
import { ImagesContainer} from '../Image/ImagesContainer';

import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import Tooltip from '../Tooltip/Tooltip';

import styles from './ExtendedMessageView.module.scss';
import messageStyles from "./ExtendedMessageView.module.scss";


const statusMapping = {
  NEW: {class: styles.new, name: 'New'},
  READ: {class: styles.approved, name: 'Approved'},
  DELETED: {class: styles.deleted, name: 'Deleted'}
}

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const ExtendedMessageView = ({
  narrative,
  searchQuery,
  sorting,
  statuses,
  showOnlyFavorites,
  page,
  searchPanel,
  start,
  end,
  platforms,
  sourceGroups,
  manipulation,
  isValid,
  languages,
  sources,
  countries,
  sentiment,
  contentTypes,
  cursor,
  setCursor,
  setPage,
  isShare = false,
  showOnlyBots,
  discreditations,
  affiliationCountries,
  feeds,
  languagesExclude,
  countriesExclude,
  sourceGroupsExclude,
  sourcesExclude,
  affiliationCountriesExclude,
  feedsExclude,
}) => {
  const {
    messages,
    selectedMessages,
    getMessages,
    toggleAll,
    toggleSelected,
    deduplication,
    patchMessages,
    toggleFavotireMessage,
    deleteMessages,
    translateMessage,
    toggleTranslate,
    error,
    toggleTab,
    isSearchApplied,
    isLoading,
    dispatch,
    cancelRequest,
    areAllMessagesSelected,
    toggleSelectAllMessagesInCase,
    clearAllSelectedMessages,
  } = useContext(MessagesContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  const [messageModal, setMessageModal] = useState({message: null, isOpen: false, narrartiveMessage: null});
  const [selectAllNarrativeMessages, setSelectAllNarrativeMessages] = useState(false);

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  useEffect(() => {
    setPage(1);
    setCursor(null);
    toggleTab();
  }, [
    searchQuery,
    sorting,
    statuses,
    showOnlyFavorites,
    start,
    end,
    platforms,
    sourceGroups,
    manipulation,
    languages,
    sources,
    countries,
    sentiment,
    contentTypes,
    showOnlyBots,
    discreditations,
    affiliationCountries,
    feeds,
    languagesExclude,
    countriesExclude,
    sourceGroupsExclude,
    sourcesExclude,
    affiliationCountriesExclude,
    feedsExclude,
  ]);

  let size = 100;


  useEffect(() => {
    if (isSearchApplied) {
      dispatch({ type: 'SET_LOADING', isLoading: true });
    } else dispatch({ type: 'TOGGLE_TAB' });

    if (
      (searchQuery === null || searchQuery?.trim().length === 0) &&
      messages
    ) {
      dispatch({ type: 'TOGGLE_TAB' });
    }
    getMessages(
      narrative,
      searchQuery,
      sorting,
      statuses,
      deduplication,
      page,
      showOnlyFavorites,
      start,
      end,
      platforms,
      sourceGroups,
      manipulation,
      isValid,
      languages,
      sources,
      countries,
      sentiment,
      contentTypes,
      showOnlyBots,
      discreditations,
      affiliationCountries,
      feeds,
      languagesExclude,
      countriesExclude,
      sourceGroupsExclude,
      sourcesExclude,
      affiliationCountriesExclude,
      feedsExclude,
      cursor,
      isLoading,
      isSearchApplied,
    );
  }, [
    searchQuery,
    sorting,
    statuses,
    showOnlyFavorites,
    start,
    end,
    platforms,
    sourceGroups,
    manipulation,
    isValid,
    languages,
    sources,
    countries,
    sentiment,
    contentTypes,
    showOnlyBots,
    discreditations,
    affiliationCountries,
    feeds,
    languagesExclude,
    countriesExclude,
    sourceGroupsExclude,
    sourcesExclude,
    affiliationCountriesExclude,
    feedsExclude,
  ]);

  useEffect(() => {
    ampli.track({
      event_type: 'Open Messages tab',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrative.id,
      }
    });

    return () => {
      cancelRequest()
    }
  }, []);

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find((message) => message.id === messageId);
    const originalPostText =
      target?.content_type === 'COMMENT' ? target?.parent_message?.text : '';
    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      const nativeLanguage = currentUser?.translation_language
        ? currentUser?.translation_language
        : narrative.parameters?.translate_keywords_query
        ? narrative.parameters.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];

      toggleTranslate(messageId, true);

      if (originalPostText) {
        Promise.allSettled([
          translateMessage(messageId, originalPostText, nativeLanguage),
          translateMessage(messageId, text, nativeLanguage),
        ]).then((results) => {
          if (results[0].status === 'fulfilled') {
            const originalPostData = results[0].value;
            dispatch({
              type: 'TRANSLATE_ORIGINAL_POST',
              payload: { data: originalPostData, messageId: messageId },
            });
          } else {
            dispatch({
              type: 'TRANSLATION_ERROR_ORIGINAL_POST',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          }

          if (results[1].status === 'fulfilled') {
            const messageData = results[1].value;
            dispatch({
              type: 'TRANSLATE_MESSAGE',
              payload: { data: messageData, messageId: messageId },
            });
          } else {
            dispatch({
              type: 'TRANSLATION_ERROR',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          }
        });
      } else {
        translateMessage(messageId, text, nativeLanguage)
          .then((data) => {
            dispatch({
              type: 'TRANSLATE_MESSAGE',
              payload: { data: data, messageId: messageId },
            });
          })
          .catch((e) => {
            dispatch({
              type: 'TRANSLATION_ERROR',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          });
      }

      ampli.track({
        event_type: 'Translate message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative?.id,
        },
      });
    }
  };

  const nerTypesList = [
    {value: 'KEYWORDS', label: t('Matched keywords')},
  ]

  if(showNer) {
    nerTypesList.push(
      {value: 'PERSON', label: t('Persons')},
      {value: 'ORGANIZATION', label: t('Organizations')},
      {value: 'LOCATION', label: t('Locations')}
    )
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);
  const [modalHighlights, setModalHighlights] = useState([]);
  const [highlightsMenu, setHighlightsMenu] = useState(false);

  if (!narrative) {
    return <Loader />;
  }

  const pagination_block = isSearchApplied ? (
      messages?.cursor !== null && messages?.objects.length < messages?.total ?
        <button
            className={styles.loadMore}
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              setCursor(messages?.cursor)
              dispatch({type: 'SET_LOADING', isLoading: true});

              getMessages(
                narrative,
                searchQuery,
                sorting,
                statuses,
                deduplication,
                page,
                showOnlyFavorites,
                start,
                end,
                platforms,
                sourceGroups,
                manipulation,
                isValid,
                languages,
                sources,
                countries,
                sentiment,
                contentTypes,
                showOnlyBots,
                discreditations,
                affiliationCountries,
                feeds,
                languagesExclude,
                countriesExclude,
                sourceGroupsExclude,
                sourcesExclude,
                affiliationCountriesExclude,
                feedsExclude,
                messages?.cursor,
                isLoading,
                isSearchApplied,
              )
            }}
        >{ isLoading ? <LoaderSmall /> : t('Load More') }</button> : ''
      ) : (!isLoading ? <Paginator
        size={messages?.size}
        page={messages?.page}
        total={messages?.total}
        onPageChange={(newPage) => {
          setPage(newPage);
          dispatch({type: 'TOGGLE_TAB'});
          getMessages(
            narrative,
            searchQuery,
            sorting,
            statuses,
            deduplication,
            newPage,
            showOnlyFavorites,
            start,
            end,
            platforms,
            sourceGroups,
            manipulation,
            isValid,
            languages,
            sources,
            countries,
            sentiment,
            contentTypes,
            showOnlyBots,
            discreditations,
            affiliationCountries,
            feeds,
            languagesExclude,
            countriesExclude,
            sourceGroupsExclude,
            sourcesExclude,
            affiliationCountriesExclude,
            feedsExclude,
            cursor,
            isLoading,
            isSearchApplied,
          )
        }}
      /> : '')


  return (
    <>
      {searchPanel ? searchPanel : ''}
      {error ? (
        <div className={styles.noData}>{t(error)}</div>
      ) : !messages ? (
        <Loader />
      ) : messages?.objects?.length > 0 ? (
        <table className={styles.extendedMessages}>
          <thead>
            <tr>
              <td className="message-controlls">
                {currentUser?.is_super_admin === true ||
                (!isShare &&
                  ((narrative.is_threat &&
                    narrative.review_status === 'PENDING') ||
                    !narrative.is_threat)) ? (
                  <CheckboxField
                    onChange={() => {
                      toggleAll(messages, selectedMessages); 
                      if(areAllMessagesSelected) {
                        toggleSelectAllMessagesInCase(false)
                        clearAllSelectedMessages()
                      }
                    }}
                    checked={
                      areAllMessagesSelected || areAllIdsSelected(messages.objects, selectedMessages)
                    }
                  />
                ) : (
                  ''
                )}
              </td>
              <td className={styles.headerInfo}>
                {!areAllIdsSelected(messages.objects, selectedMessages) &&
                !areAllMessagesSelected ? (
                  <span>
                    {t('Total messages')}: {formatNumber(messages.total)}
                  </span>
                ) : !areAllMessagesSelected ? (
                  <div className={styles.selectAllContainer}>
                    <span>
                      {t(
                        'allPageMessagesSelected',
                        { count: messages?.objects?.length },
                      )}
                    </span>
                    <span
                      className={styles.selectAllMessages}
                      onClick={() => {
                        toggleSelectAllMessagesInCase(true);

                      }}
                    >
                      {t('selectAllMessages', {
                        count: messages?.total,
                      })}
                    </span>
                  </div>
                ) : (
                  <div className={styles.selectAllContainer}>
                    <span>
                      {t('allMessagesSelected', {
                        count: messages.total,
                      })}
                    </span>
                    <span
                      className={styles.selectAllMessages}
                      onClick={() => {
                        toggleSelectAllMessagesInCase(false);
                        toggleAll(messages, selectedMessages);
                      }}
                    >
                      {t('Deselect all')}
                    </span>
                  </div>
                )}
              </td>
              <td className="message-controlls">
                <DropdownMenu
                  buttonName={t('Highlights')}
                  isOpen={highlightsMenu}
                  onClick={() => setHighlightsMenu(!highlightsMenu)}
                  menuClassName="highlights"
                  icon={<ChevronIcon />}
                >
                  <Multichoice
                    itemsList={nerTypesList}
                    selected={highlights}
                    onChange={(selectedList) => {
                      setHighlights(selectedList);

                      ampli.track({
                        event_type: 'Change highlights settings',
                        event_properties: {
                          user_id: currentUser?.id,
                          workspace_id: currentUser?.workspace_id,
                          narrative_id: narrative.id,
                          highlights: selectedList,
                        },
                      });
                    }}
                  />
                </DropdownMenu>
              </td>
            </tr>
          </thead>
          <tbody>
            {messages.objects.map((message) => {
              let followers_cnt = message?.source?.audience;

              let textClass = styles.extendedMessage;
              let textClassParent = messageStyles.extendedMessage;

              if (message.parent_message?.error && message.parent_message?.isTranslation) {
                textClassParent = `${messageStyles.extendedMessage} ${messageStyles.error}`
              } else if (message.error && !message.isTranslation) {
                textClassParent = messageStyles.extendedMessage;
              }

              if (message.error && message.isTranslation) {
                textClass = `${styles.extendedMessage} ${styles.error}`;
              } else if (message.error && !message.isTranslation) {
                textClass = styles.extendedMessage;
              }

              const narrartiveMessage = {
                narrative: narrative,
                page: page,
                searchQuery: searchQuery,
                sorting: sorting,
                statuses: statuses,
              };

              const textForTranslation = message.highlighted_text
                ? replaceNewlinesWithBreaks(message.highlighted_text)
                : replaceNewlinesWithBreaks(message.text);

              return (
                <tr key={message.id}>
                  <td className="message-controlls">
                    {currentUser?.is_super_admin === true ||
                    (!isShare &&
                      ((narrative.is_threat &&
                        narrative.review_status === 'PENDING') ||
                        !narrative.is_threat)) ? (
                      <CheckboxField
                        checked={
                          areAllMessagesSelected
                            ? true
                            : message.id in selectedMessages
                        }
                        onChange={() => toggleSelected(message.id)}
                        isDisabled={areAllMessagesSelected}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <div className={styles.messageInfoWrapper}>
                      <div className={styles.messageInfo}>
                        <div
                          className={`${styles.status} ${
                            statusMapping[message.status].class
                          }`}
                        >
                          {t(statusMapping[message.status].name)}
                        </div>
                        {message.content_type ? (
                          <div className={styles.contentType}>
                            <Tooltip
                              content={t(
                                contentTypesMapping[message.content_type],
                              )}
                              position="bottom"
                            >
                              {contentTypesIconsMapping[message.content_type]}
                            </Tooltip>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className={styles.date}>
                          <span className={styles.anchor}>
                            <Tooltip
                              content={t('Publication date')}
                              position="bottom"
                            >
                              <CalendarIcon />
                            </Tooltip>
                          </span>
                          {format(
                            parseISO(message.date_publicated + 'Z'),
                            'dd LLL yyyy HH:mm',
                          )}
                        </div>
                        <div className={styles.platform}>
                          <span className={styles.anchor}>
                            <Tooltip
                              content={
                                platformNamesMapping[message.source.source_type]
                              }
                              position="bottom"
                            >
                              {platformIconsMapping[message.source.source_type]}
                            </Tooltip>
                          </span>
                          {followers_cnt ? (
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Followers')}
                                position="bottom"
                              >
                                <FollowersIcon />
                              </Tooltip>
                              <span>
                                {formatNumberSignificant(followers_cnt)}
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className={styles.sourceActorWrapper}>
                          <span className={styles.anchor}>
                            <Tooltip
                              content={decodeSourceName(message.source.name)}
                              position="bottom"
                            >
                              <ActorIcon />
                              <span className={styles.cutText}>
                                {isShare ? (
                                  decodeSourceName(message.source.name)
                                ) : message.source ? (
                                  <Link
                                    to={`/sources/${message.source?.id}`}
                                    onClick={() => {
                                      ampli.track({
                                        event_type: 'Open source profile',
                                        event_properties: {
                                          user_id: currentUser?.id,
                                          workspace_id:
                                            currentUser?.workspace_id,
                                          source_id: message.source.id,
                                        },
                                      });
                                    }}
                                  >
                                    {decodeSourceName(message.source.name)}
                                  </Link>
                                ) : (
                                  t('N/A')
                                )}
                              </span>
                            </Tooltip>
                            {message.actor &&
                            message.actor.id !== message.source?.id ? (
                              <Tooltip
                                content={decodeSourceName(message.actor?.name)}
                                position="bottom"
                              >
                                <SourceIcon />
                                <span className={messageStyles.cutText}>
                                  {isShare ? (
                                    decodeSourceName(message.actor?.name)
                                  ) : (
                                    <Link to={`/sources/${message.actor?.id}`}>
                                      {decodeSourceName(message.actor?.name)}
                                    </Link>
                                  )}
                                </span>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            <a
                              href={message.url}
                              className={styles.externalLink}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                ampli.track({
                                  event_type: 'Open external message link',
                                  event_properties: {
                                    user_id: currentUser?.id,
                                    workspace_id: currentUser?.workspace_id,
                                  },
                                });
                              }}
                            >
                              <LinkIcon />
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className={'open-message-popup ' + styles.modalIcon}>
                        <ExpandIcon
                          onClick={() => {
                            ampli.track({
                              event_type: 'Open message in a pop-up',
                              event_properties: {
                                user_id: currentUser?.id,
                                workspace_id: currentUser?.workspace_id,
                                narrative_id: narrative.id,
                              },
                            });
                            setMessageModal({
                              message: message,
                              isOpen: true,
                              narrartiveMessage: narrartiveMessage,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.messageContainer}>
                      <div className={styles.messageMetricsWrapper}>
                        <div className={styles.messageMetrics}>
                          {message.sentiment_score !== null ? (
                            <Sentiment
                              sentimentScore={message.sentiment_score}
                              messageId={message.id}
                              narrative={narrative}
                              isShare={isShare}
                            />
                          ) : (
                            ''
                          )}
                          <span className={styles.anchor}>
                            <Tooltip content={t('Views')} position="bottom">
                              <ViewsIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.impressions)}
                          </span>
                          <span className={styles.anchor}>
                            <Tooltip content={t('Reactions')} position="bottom">
                              <EngagementIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.engagement)}
                          </span>
                          {showMessageManipulationIndex ? (
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Manipulation')}
                                position="bottom"
                              >
                                <ManipulationIcon />
                              </Tooltip>

                              {message.manipulation_index
                                ? message.manipulation_index.toFixed(2)
                                : '0'}
                            </span>
                          ) : (
                            ''
                          )}
                          {message.similarity_score ? (
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Similarity1')}
                                position="bottom"
                              >
                                <SimilarityIcon />
                              </Tooltip>
                              {(message.similarity_score + '').slice(0, 4)}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        {narrative.is_threat || isShare ? (
                          ''
                        ) : (
                          <div className={styles.favorites}>
                            <span
                              className="open-message-popup"
                              onClick={() => {
                                toggleFavotireMessage(
                                  narrative,
                                  !message.favorite,
                                  [message.id],
                                  deduplication,
                                ).then(() => {
                                  if (!showOnlyFavorites) {
                                    // There is no need to fetch messages if the showOnlyFavorites is not applied
                                    return;
                                  }
                                  getMessages(
                                    narrative,
                                    searchQuery,
                                    sorting,
                                    statuses,
                                    deduplication,
                                    page,
                                    showOnlyFavorites,
                                    start,
                                    end,
                                    platforms,
                                    sourceGroups,
                                    manipulation,
                                    isValid,
                                    languages,
                                    sources,
                                    countries,
                                    sentiment,
                                    contentTypes,
                                    showOnlyBots,
                                    discreditations,
                                    affiliationCountries,
                                    feeds,
                                    languagesExclude,
                                    countriesExclude,
                                    sourceGroupsExclude,
                                    sourcesExclude,
                                    affiliationCountriesExclude,
                                    feedsExclude,
                                    cursor,
                                  );
                                });
                              }}
                            >
                              {message.favorite ? (
                                <Tooltip
                                  content={t('Delete from favorites')}
                                  position="bottom"
                                >
                                  <HeartSolidIcon />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  content={t('Add to favorites')}
                                  position="bottom"
                                >
                                  <HeartRegularIcon />
                                </Tooltip>
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      {/* <div className={styles.imagesContainer}>
                        {imagesList.map((image, index) => (
                          <div key={index} className={styles.imageWrapper}>
                            <img src={image} alt="image" loading='lazy'/>
                            
                            <div className={styles.imageSkeletonWrapper}>
                              <ImageIcon style={{'width': '50px', 'color': 'gray'}}/>
                            </div>
                            
                           
                          </div>
                          <ImageComponent key={index} url={image} />
                        ))}
                      </div> */}

                      {message.content_type === 'COMMENT' && message.parent_message ? (
                        <div className={styles.originalTextBlock}>
                          {message.isTranslation === true &&
                          !message.parent_message?.translated ? (
                            <div className={styles.loaderContainer}>
                              <LoaderSmall />
                            </div>
                          ) : (
                            <>
                              <Tooltip
                                content={decodeSourceName(message.source?.name)}
                                position="bottom"
                              >
                                <span className={styles.sourceLink}>
                                  {isShare ? (
                                    decodeSourceName(message.source?.name)
                                  ) : message.source ? (
                                    <Link
                                      to={`/sources/${message.source?.id}`}
                                      onClick={() => {
                                        ampli.track({
                                          event_type: 'Open source profile',
                                          event_properties: {
                                            user_id: currentUser?.id,
                                            workspace_id:
                                              currentUser?.workspace_id,
                                            source_id: message.source?.id,
                                          },
                                        });
                                      }}
                                    >
                                      {decodeSourceName(message.source.name)}
                                    </Link>
                                  ) : (
                                    t('N/A')
                                  )}
                                </span>
                              </Tooltip>

                              {message?.parent_message?.images && message?.parent_message?.images?.length > 0 ? <ImagesContainer images={message?.parent_message?.images} showCount={3} /> : ''}

                              {message.parent_message?.text ? (
                                <ExpandableText
                                  length={100}
                                  text={
                                    message.isTranslation
                                    ? message.parent_message?.translated
                                    : message.parent_message?.text
                                  }
                                  textClassName={textClassParent}
                                  onExpand={(expanded) => {
                                    ampli.track({
                                      event_type: expanded
                                        ? 'Expand original post in-place'
                                        : 'Collapse original post in-place',
                                      event_properties: {
                                        user_id: currentUser?.id,
                                        workspace_id: currentUser?.workspace_id,
                                        narrative_id: narrative?.id,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        ''
                      )}

                      {message?.images && message?.images?.length > 0 ? <ImagesContainer images={message?.images} showCount={3} /> : ''}

                      <div className={styles.textBlock}>
                        {message.isTranslation === true &&
                        !message.translated ? (
                          <div className={styles.loaderContainer}>
                            <LoaderSmall />
                          </div>
                        ) : (
                          <ExpandableText
                            length={100}
                            highlights={highlights}
                            text={
                              message.isTranslation
                                ? message.translated
                                : message.highlighted_text || message.text
                            }
                            textClassName={textClass}
                            onExpand={(expanded) => {
                              ampli.track({
                                event_type: expanded
                                  ? 'Expand message in-place'
                                  : 'Collapse message in-place',
                                event_properties: {
                                  user_id: currentUser?.id,
                                  workspace_id: currentUser?.workspace_id,
                                  narrative_id: narrative.id,
                                },
                              });
                            }}
                          />
                        )}
                        <div className={styles.similarMessagesSearch}>
                          <Tooltip
                            content={t('Find similar messages')}
                            position="bottom"
                          >
                            <span className={messageStyles.cutText}>
                              <Link
                                to={`/search?similar_to=${message.composite_id}`}
                                onClick={() =>
                                  (window.location.href = `/search?similar_to=${message.composite_id}`)
                                }
                              >
                                <SearchIcon />
                              </Link>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="message-controlls">
                    {isShare ? (
                      ''
                    ) : (
                      <>
                        {currentUser?.is_super_admin === true ||
                        (!isShare &&
                          ((narrative.is_threat &&
                            narrative.review_status === 'PENDING') ||
                            !narrative.is_threat)) ? (
                          <div className={styles.controls}>
                            <button
                              className={`${styles.approve} btn-primary`}
                              disabled={
                                message.status === 'DELETED' ? true : false
                              }
                              onClick={() => {
                                let status =
                                  message.status === 'NEW' ? 'READ' : 'NEW';
                                let messageIds = [message.id];
                                patchMessages(
                                  narrative,
                                  status,
                                  messageIds,
                                  deduplication,
                                ).then(() => {
                                  dispatch({ type: 'PATCH_MESSAGES' });
                                  getMessages(
                                    narrative,
                                    searchQuery,
                                    sorting,
                                    statuses,
                                    deduplication,
                                    page,
                                    showOnlyFavorites,
                                    start,
                                    end,
                                    platforms,
                                    sourceGroups,
                                    manipulation,
                                    isValid,
                                    languages,
                                    sources,
                                    countries,
                                    sentiment,
                                    contentTypes,
                                    showOnlyBots,
                                    discreditations,
                                    affiliationCountries,
                                    feeds,
                                    languagesExclude,
                                    countriesExclude,
                                    sourceGroupsExclude,
                                    sourcesExclude,
                                    affiliationCountriesExclude,
                                    feedsExclude,
                                  );
                                });
                              }}
                            >
                              {message.status === 'NEW' ? (
                                <ApproveIcon />
                              ) : (
                                <EnvelopeOpenIcon />
                              )}
                            </button>
                            <button
                              className={`${styles.delete} btn-danger`}
                              onClick={() => {
                                let messageIds = [message.id];
                                if (message.status === 'DELETED') {
                                  patchMessages(
                                    narrative,
                                    'NEW',
                                    messageIds,
                                    deduplication,
                                  ).then(() => {
                                    dispatch({ type: 'PATCH_MESSAGES' });
                                    getMessages(
                                      narrative,
                                      searchQuery,
                                      sorting,
                                      statuses,
                                      deduplication,
                                      page,
                                      showOnlyFavorites,
                                      start,
                                      end,
                                      platforms,
                                      sourceGroups,
                                      manipulation,
                                      isValid,
                                      languages,
                                      sources,
                                      countries,
                                      sentiment,
                                      contentTypes,
                                      showOnlyBots,
                                      discreditations,
                                      affiliationCountries,
                                      feeds,
                                      languagesExclude,
                                      countriesExclude,
                                      sourceGroupsExclude,
                                      sourcesExclude,
                                      affiliationCountriesExclude,
                                      feedsExclude,
                                    );
                                  });
                                } else {
                                  deleteMessages(
                                    narrative,
                                    messageIds,
                                    deduplication,
                                  ).then(() => {
                                    dispatch({ type: 'DELETE_MESSAGES' });
                                    getMessages(
                                      narrative,
                                      searchQuery,
                                      sorting,
                                      statuses,
                                      deduplication,
                                      page,
                                      showOnlyFavorites,
                                      start,
                                      end,
                                      platforms,
                                      sourceGroups,
                                      manipulation,
                                      isValid,
                                      languages,
                                      sources,
                                      countries,
                                      sentiment,
                                      contentTypes,
                                      showOnlyBots,
                                      discreditations,
                                      affiliationCountries,
                                      feeds,
                                      languagesExclude,
                                      countriesExclude,
                                      sourceGroupsExclude,
                                      sourcesExclude,
                                      affiliationCountriesExclude,
                                      feedsExclude,
                                    );
                                  });
                                }
                              }}
                            >
                              {message.status === 'DELETED' ? (
                                <RecoverIcon />
                              ) : (
                                <DeleteIcon />
                              )}
                            </button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}

                    <div className={styles.translationWrapper}>
                      <span
                        className={message?.isTranslation ? '' : styles.active}
                        onClick={() =>
                          handleTranslation(
                            message.id,
                            textForTranslation,
                            false,
                          )
                        }
                      >
                        <OriginalTextIcon />
                      </span>
                      <span
                        className={message?.isTranslation ? styles.active : ''}
                        onClick={() =>
                          handleTranslation(
                            message.id,
                            textForTranslation,
                            true,
                          )
                        }
                      >
                        <TranslationIcon />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className={styles.noData}>{t('There is no data to display')}</div>
      )}

      {messages?.objects?.length > 0 ? pagination_block : ''}

      {messageModal.isOpen ? (
        <MessageModal
          message={messageModal.message}
          isOpen={messageModal.isOpen}
          onChange={(bool, selected) => {
            setMessageModal({
              message: null,
              isOpen: bool,
              narrartiveMessage: null,
            });
            setModalHighlights(selected);
          }}
          narrativeMessage={messageModal.narrartiveMessage}
          selected={
            modalHighlights?.length === 0 ? highlights : modalHighlights
          }
          narrative={narrative}
          isShare={isShare}
        />
      ) : (
        ''
      )}
    </>
  );
}
