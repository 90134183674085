import AsyncCreatableSelect from 'react-select/async-creatable';
import { useCallback, useEffect, useState,} from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import Modal from "../Modal";
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { languageMapping } from '../../utils/languages';
import { format } from 'date-fns';
import {toast} from "react-toastify";

import styles from './AddToSourceGroupModal.module.scss';


export const AddToSourceGroupModal = ({
  isOpen,
  areAllMessagesSelected, 
  allMessages=null,
  messages=null,
  sources,
  onChange,
  isSearch=false,
  deduplication=false,
  searchQuery,
  messagesStatus,
  showOnlyFavorites,
  startDate,
  endDate,
  platforms,
  sourceGroups,
  manipulationIndex,
  languages,
  sourcesFilter,
  countries,
  sentiment,
  contentTypes,
  showOnlyBots,
  discreditations,
  affiliationCountries,
  feeds,
  languagesExclude,
  countriesExclude,
  sourceGroupsExclude,
  sourcesExclude,
  affiliationCountriesExclude,
  feedsExclude,
  narrative
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const [options, setOptions] = useState(null);
  const [error, setError] = useState();
  const [narrativeSelect, setNarrativeSelect] = useState(false);
  const [sourcesList, setSourcesList] = useState(null);
  
  const messagesList = messages ? Object?.keys(messages) : null;

  const selectAllModalMessage = () => {
    toast.info(t('This process may take a few minutes. Please wait before checking the changes.'));
  }
  
  const userInstruction = <div className={styles.userNote}>
    {t(`Start typing an actor group name to add actors to an existing one or create a new group by typing its name and pressing the 'Create New Group' button.`)}
  </div>

  const getSources = useCallback(() => {
    if (messages === null && !areAllMessagesSelected) {
      return
    }

    if (isSearch === true) {
      const messagesSet = new Set(messagesList);
      let sourcesIds = [];
      allMessages.forEach(message => {
        if (messagesSet.has(message.id)) {
          sourcesIds.push(message.source.id);
        }
    });
      setSourcesList(sourcesIds)
    } else {

      const urlParams = new URLSearchParams();
      messagesList?.forEach(message => urlParams.append('ids', message))

      API.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then((data) => {
        let sources = data?.objects?.map(message => message.source_id);
        setSourcesList(sources)
      })

    }
    

  }, [])

  useEffect(getSources, [getSources]);

  useEffect(() => {
    if (sources) {
      let sourcesIds = sources.map(source => source.id);
      setSourcesList(sourcesIds)
    }
  }, [])

  const getGroupsOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '20');
    urlParams.set('page', 1);

    API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then(data => {
      let newOptions = data.objects.map(group => {return {label: group.name, value: group.id}});
      setOptions(newOptions)
    })
  }, [])

  useEffect(getGroupsOptions, [getGroupsOptions]);

  const getGroups = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);

    return API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((group) => {
            return {value: group.id, label: group.name};
          })
      );
    });
  };

  const handleCreate = (inputvalue) => {
      setIsLoading(true);
      API.fetch('POST', `/API/v1/groups`, null, {
        name: inputvalue,
      }).then(data => {
        let newOption = {value: data.id, label: data.name};
        setIsLoading(false);
        setOptions(prev => [...prev, newOption])
        setGroup(newOption)
      })
      .catch(e => {
        // setError(e.message);
        setError(t('Something went wrong. Please, try again...'));
        setIsLoading(false);
      });
  }

  const handleAddToSourceGroup = () => {
    if(!messages && !sources && !areAllMessagesSelected) {
      setError('There are no selected items to add to the group');
      return
    }

    const urlParams = new URLSearchParams();

    
    
    let body = {};

    if (areAllMessagesSelected) {
      
      const keyword = searchQuery ? searchQuery.replace(/(\r\n|\n|\r)/gm, "") : "";

      if (keyword) {
          body['keywords'] = [keyword]
      }

      if (startDate) {
        body['start_date'] = format(startDate, 'yyyy-LL-dd')
      }

      if (endDate) {
          body['end_date'] = format(endDate, 'yyyy-LL-dd')
      }


      if (platforms && platforms.length > 0) {
        body.platforms = platforms;
      }

      if (sentiment && sentiment.length > 0) {
        body.sentiment = sentiment;
      }

      if (sourceGroups && sourceGroups.length > 0) {
        body.source_group_ids = sourceGroups;
      }

      if (manipulationIndex) {
        body.manipulation_index_lte = manipulationIndex[1];
        body.manipulation_index_gte = manipulationIndex[0];
      }

      if(languages && languages.length > 0) {
        body.languages = languages.map(lang => languageMapping[lang]);
      }

      if (contentTypes && contentTypes.length > 0) {
        body.content_types = contentTypes;
      }

      if(sourcesFilter && sourcesFilter.length > 0) {
        body.source_ids = sourcesFilter;
      }

      if (countries && countries.length > 0) {
        body.source_origin_country_ids = countries;
      }

      if (showOnlyBots === true) {
        body.source_bots = showOnlyBots;
      }

      if (discreditations && discreditations?.length > 0) {
        body.source_discreditated_entity_types = discreditations;
      }

      if(affiliationCountries?.length > 0 && affiliationCountries[0]?.value === 'all') {
        body.source_state_affiliated_all_countries = true;
      } else {
        body.source_state_affiliated_country_ids = affiliationCountries?.map(v => v.value);
      }

      if (feeds && feeds.length > 0) {
        body.feed_ids = feeds?.map(feed => feed.value);
      }

      let excludedFilters = [];

      if(languagesExclude) {
        excludedFilters.push('languages');
      }
      
      if(countriesExclude) {
        excludedFilters.push('source_origin_country_ids');
      }

      if(sourceGroupsExclude) {
        excludedFilters.push('source_group_ids');
      }

      if(sourcesExclude) {
        excludedFilters.push('source_ids');
      }

      if(affiliationCountriesExclude) {
        excludedFilters.push('source_state_affiliated_country_ids');
      }

      if(feedsExclude) {
        excludedFilters.push('feed_ids');
      }

      if(excludedFilters.length > 0) {
        body.exclusive_filters = excludedFilters;
      }

      urlParams.set('narrative_id', narrative.id);
  

      if(messagesStatus) {
        messagesStatus.forEach(status => urlParams.append('message_status', status));
      }

      if(showOnlyFavorites) {
        urlParams.set('favorite', showOnlyFavorites);
      }

    } else {
      body = {
        source_ids: sourcesList,
      };
    }

    let link = `/API/v1/groups/${group?.value}/sources`;

    if(areAllMessagesSelected) {
      link = `/API/v1/groups/${group?.value}/sources/from_search?${urlParams.toString()}`;
    }

    
    API.fetch('POST', link, null, body).then(() => {
      if(areAllMessagesSelected) {
        selectAllModalMessage()
      }
    }).catch(e => {
      setError(t('Something went wrong. Please, try again...'));
    });
  }

  useEffect(() => {
    if(options) {
      const container = document.querySelector('#message-to-narrative-modal');
      if(narrativeSelect === true ) {
        let content = document.querySelector(".addToNarrativeModal__menu");
        if(container.offsetHeight < content.offsetHeight) {
          container.style.height=container.offsetHeight + content.offsetHeight + "px";
        }
      } else {
        container.style.height = ' ';
      }
    }
 }, [narrativeSelect])


   
  return (
      <Modal
        isVisible={isOpen}
        title={t('Save actors to an actor group')}
        className={styles.addToNarrativeModal}
        doNotCloseOnOutsideClick={true}
        content={
          options ? <div id='message-to-narrative-modal'>
            {userInstruction}
            <label>{t('Actor group name')}</label>
            <AsyncCreatableSelect
              cacheOptions
              defaultOptions={options}
              value={group}
              loadOptions={getGroups}
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading}
              loadingMessage={() => t('Loading...')}
              placeholder={t('Start typing an existing group name or create a new one')}
              onChange={(newValue) => setGroup(newValue)}
              onCreateOption={handleCreate}
              onMenuOpen={() => setNarrativeSelect(true)}
              onMenuClose={() => setNarrativeSelect(false)}
              menuIsOpen={narrativeSelect}
              className='addToNarrativeModal'
              classNamePrefix="addToNarrativeModal"
              formatCreateLabel={(inputValue) => <span className={styles.create}><PlusIcon/>{t('Create a new actor group')} "{inputValue}"</span>}
              createOptionPosition='first'
            />
            {error ? <div className={styles.error}>{error}</div> : ''}
          </div> : <LoaderSmall/>
        }
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                handleAddToSourceGroup();
                setGroup(null);
                setSourcesList(null);
                setError(null);
                onChange(false);
              }}
              
            >
              {t('Save')}
            </button>
            <button
              className="btn-reset"
              onClick={() => {
                setGroup(null);
                setSourcesList(null);
                setError(null);
                onChange(false);
              }}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => {
          onChange(false)
          setSourcesList(null);
          setGroup(null);
          setError(null);
        }
        }
      />
  )
}


