import {useState, useRef, useEffect } from 'react';
import {useClickOutside} from '../../utils/useClickOutside.jsx'
import './DropdownMenu.scss';


export const DropdownMenu = ({isOpen=false, children, buttonName, icon, header, onClick, menuClassName='', disabled}) => {
  const clickRef = useRef();
  let content = null;

  if (isOpen) {
    content = (
      <>
        {children}
      </>
    )
  }

  useClickOutside(clickRef, () => {
    if(isOpen) {
      onClick()
    }
  })

  return (
    <div className={menuClassName ? `dropdown-menu-wrapper ${menuClassName}` : 'dropdown-menu-wrapper'} ref={clickRef}>
      <button
        className={isOpen ? `sorting active` : `sorting`}
        type='button'
        onClick={() => onClick()}
        disabled={disabled}
        >
        {buttonName ? <span className='dropdown-menu-button-name'>{buttonName}</span> : ''}
        {icon}
      </button>
      {isOpen ? (<div className='dropdown-menu-container'>

        {header ? <div className='dropdown-menu-header'>{header}</div> : ''}

        {content}
      </div>) : ''}
    </div>
    
  )
}