import cn from 'classnames';
import * as d3 from 'd3';
import { useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import Modal from '../../components/Modal';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon} from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon} from '../../assets/sort.svg';
import { GroupEditModal } from './GroupEditModal';
import { Search } from '../../components/Search/Search';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './GroupsPage.module.scss';

const formatNumber = d3.format(",d");

export function GroupsPage() {
  const { t } = useTranslation();
  const [deleteIds, setDeleteIds] = useState([]);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [groups, setGroups] = useState(null);
  const [editModal, setEditModal] = useState({isOpen: false, groupId: null});
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'id'});

  const sortingQuery = sorting
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  
  const page = searchParams.get('page') ?? 1;
  const searchQuery = searchParams.get('q');


  const fetchGroups = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '100');
    urlParams.set('page', page);
    urlParams.set('sorting', sortingQuery);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then(setGroups);
  }, [page, searchQuery, sortingQuery]);

  const clearDelete = useCallback(() => {
    setDeleteIds([]);
  }, []);

  const handleDelete = useCallback(() => {
    if (deleteIds.length === 0) {
      return;
    }

    clearDelete();

    Promise.all(
      deleteIds.map((id) => API.fetch('DELETE', `/API/v1/groups/${id}`)),
    ).finally(fetchGroups);
  }, [deleteIds, fetchGroups, clearDelete]);

  useEffect(fetchGroups, [fetchGroups]);

  const handleEdit = (name, group, privacySettings, workspaces) => {
    API.fetch('PATCH', `/API/v1/groups/${group.id}`, null, {
      name: name,
      visibility: privacySettings,
      workspace_ids: workspaces?.map((workspace) => workspace.value),
    }).then(() => {
      fetchGroups();
    });
  };

  if (!groups) {
    return <Loader />;
  }

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id',
    },
    {
      name: t('Name'),
      value: 'name',
    },
    {
      name: t('Sources count'),
      value: 'number_of_sources',
    },
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    }
  ]

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>{t('Actor groups')}</span>
        </div>
        <div className="controls">
          <Link
            to={`/groups/new`}
            className={cn(styles.buttonText, 'button', 'new-button')}
          >
            <PlusIcon />
            {t('Create group')}
          </Link>
        </div>
      </div>
      <div className="next-card-header">
        <div className={styles.searchWrapper}>
          <Search
            className={styles.searchInput} 
            onChange={value => {
              setSearchParams({q: value, page: 1})
            }}
          />

          <DropdownMenu  
              isOpen={dropdownMenu}
              header={t('Sort by')}
              onClick={() => setDropdownMenu(!dropdownMenu)}
              buttonName={t('Sort')}
              icon={<SortIcon/>}
            >
              <Radiobutton 
                itemsList={sortingMenu}
                current={sorting.fieldName}
                onChange={(value) => setSorting({...sorting, fieldName: value})}
              />

              <SortingOrder 
                itemsList={sortingOrder}
                onClick={value => setSorting({...sorting, isAscending: value})}
                current={sorting.isAscending}
              />
            </DropdownMenu>
        </div>
        
      </div>
      
      {groups?.objects?.length > 0 ? <div className={styles.tableWrapper}>
        <table className={styles.actorGroups}>
          <thead>
            <tr>
              <td>{t('Group')}</td>
              <td>{t('Actors')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {groups?.objects?.map((group) => {
              return (
                <tr key={group.id}>
                  <td>
                    <div className={styles.gropNameWrapper}>
                      <div className={styles.groupNameContainer}>
                        <span>
                          <Link to={`/groups/${group.id}`}>{group.name}</Link>
                        </span>
                        <div className={styles.groupInfo}>
                          <span>#{group.id}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{formatNumber(group.number_of_sources) ?? 0}</td>
                  <td>
                    <div className={styles.controls}>
                      {group.owned_by_workspace ? <Tooltip content={t('Edit')} position="bottom">
                        {/* <Link
                          to={`/users/${user.id}/edit`}
                          className="new-button"
                        >
                          <i className="lar la-edit"></i>
                        </Link> */}
                        <button
                          className='new-button'
                          onClick={() => setEditModal({isOpen: true, group: group})}
                        >
                          <i className="lar la-edit"></i>
                        </button>
                      </Tooltip>: ''}

                      {group.owned_by_workspace ? <Tooltip content={t('Delete')} position="bottom">
                        <button
                          className="btn-danger"
                          onClick={() =>
                            setDeleteIds(() => [group.id])
                          }
                        >
                          <i className="las la-trash"></i>
                        </button>
                      </Tooltip> : ''}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> : <div className='noData'>{t('There is no data to display')}</div>}

      <Paginator
        size={groups.size}
        page={groups.page}
        total={groups.total}
        onPageChange={(page) => {
          const params = new URLSearchParams();

          if (searchQuery) {
            params.set('q', searchQuery);
          }

          params.set('page', page);
          navigate(`?${params.toString()}`);
        }}
      />

      <Modal
        isVisible={deleteIds.length > 0}
        title={t('Delete')}
        content={
          <p>
            {deleteIds.ids && deleteIds.ids.length > 1
              ? t('Are you sure you want to delete these groups?')
              : t('Are you sure you want to delete this group?')}
          </p>
        }
        footer={
          <>
            <button className="btn-danger" onClick={handleDelete}>
              {t('Delete')}
            </button>
            <button className="btn-reset" onClick={clearDelete}>
              {t('Cancel')}
            </button>
          </>
        }
        onClose={clearDelete}
      />

      {editModal.isOpen 
        ? <GroupEditModal
            editModal={editModal}
            handleEdit={(name, group, privacySettings, workspaces) => {
              handleEdit(name, group, privacySettings, workspaces)
              setEditModal({isOpen: false, group: null})
            }}
            clearEdit = {() => setEditModal({isOpen: false, group: null})}
          /> 
        : ''}

    </div>
  );
}
