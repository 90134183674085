import { useTranslation } from 'react-i18next';
import { useState, useRef, useCallback, useEffect, startTransition } from 'react';
import { API } from '../../../API';

import { Collapsible } from './Collapsible';
import { PlatformChooser } from './PlatformChooser';
import { ReactComponent as FilterIcon } from '../assets/filter.svg';
import { DoubleRangeSlider } from './DoubleRangeSlider/DoubleRangeSlider';
import { CountryChooser } from "./CountryChooser";
import { StatusChooser } from './StatusChooser';
import { IoCChooser } from './IoCChooser';
import { BotsChooser } from './BotsChooser';
import { TagsChooser } from './TagsChooser';
import {useClickOutside} from '../../../utils/useClickOutside.jsx'
import {getSelectedCountries} from '../../../utils/countries';
import { AffiliationCountries } from './AffiliationCountries';
import { ExcludeSwitch } from '../../SearchPage/Filter/ExcludeSwitch';
import './Filter.scss';
import styles from './Filter.module.scss';
import {useCurrentUser} from "../../../contexts/CurrentUser";


export const Filter = ({onChange, state}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [platforms, setPlatforms] = useState(state.platforms || []);
  const [countries, setCountries] = useState(state.countries || []);
  const [statuses, setStatuses] = useState(state.status || []);
  const [audienceRange, setAudienceRange] = useState([state.audience_gte, state.audience_lte]);
  const [affiliationCountries, setAffiliationCountries] = useState(state.affiliationCountries || []);
  const [IoCs, setIoCs] = useState(state.IoCs || []);
  const [showOnlyBots, setShowOnlyBots] = useState(state.showOnlyBots || false);
  const [sourceTags, setSourceTags] = useState(state.sourceTags || []);
  const [isPlatformsOpen, setIsPlatformsOpen] = useState(false);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isAudienceOpen, setIsAudienceOpen] = useState(false);
  const [isAffiliationCountriesOpen, setIsAffiliationCountriesOpen] = useState(false);
  const [isIoCsOpen, setIsIoCsOpen] = useState(false);
  const [isShowOnlyBotsOpen, setIsShowOnlyBotsOpen] = useState(false);
  const [isSourceTagsOpen, setIsSourceTagsOpen] = useState(false);

  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showTagsFilter = featureFlags?.includes('show_tags_filter');
  const showExclusiveFilters = featureFlags?.includes('exclusive_filters');
  const [countriesExclude, setCountriesExclude] = useState(state.countriesExclude || false);
  const [affiliationCountriesExclude, setAffiliationCountriesExclude] = useState(state.affiliationCountriesExclude || false);
  const [audienceExclude, setAudienceExclude] = useState(state.audienceExclude || false);
  
  let filtersSumm = platforms.length + countries.length + statuses.length + affiliationCountries.length + IoCs.length + (showOnlyBots ? 1 : 0);

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let selectedCountries = getSelectedCountries(countries, data.objects);
      setCountries(selectedCountries)

      let selectedAffiliationCountries = getSelectedCountries(affiliationCountries, data.objects);
      setAffiliationCountries(selectedAffiliationCountries)
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        type='button'
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isPlatformsOpen || !isCountriesOpen || !isAudienceOpen || !isStatusOpen || !isAffiliationCountriesOpen || !isIoCsOpen || !isShowOnlyBotsOpen || !isSourceTagsOpen) {
              setIsPlatformsOpen(true);
              setIsCountriesOpen(true);
              setIsAudienceOpen(true);
              setIsStatusOpen(true);
              setIsAffiliationCountriesOpen(true);
              setIsIoCsOpen(true);
              setIsShowOnlyBotsOpen(true);
              setIsSourceTagsOpen(true);
            } else {
              setIsPlatformsOpen(false);
              setIsCountriesOpen(false);
              setIsAudienceOpen(false);
              setIsStatusOpen(false);
              setIsAffiliationCountriesOpen(false);
              setIsIoCsOpen(false);
              setIsShowOnlyBotsOpen(false);
              setIsSourceTagsOpen(false);
            }
          }}>
            {isPlatformsOpen && isCountriesOpen && isAudienceOpen && isStatusOpen && isAffiliationCountriesOpen && isIoCsOpen && isShowOnlyBotsOpen && isSourceTagsOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>

          <Collapsible 
            name='Platform'
            summ={platforms.length}
            isOpen={isPlatformsOpen}
            onChange={(open) => setIsPlatformsOpen(open)}
          >
            <PlatformChooser 
              selected={platforms}
              onChange={(checklist) => setPlatforms(checklist)}
            />
          </Collapsible>

          <Collapsible
            name='Country'
            summ={countries.length}
            isOpen={isCountriesOpen}
            onChange={(open) => setIsCountriesOpen(open)}
            exclude={countriesExclude}
          >
           {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='country-switch'
                value={countriesExclude}
                onChange={setCountriesExclude}
                color='red'
              />
            </div> : ''}
            <CountryChooser
              selected={countries}
              onChange={(checklist) => setCountries(checklist)}
              onClose={() => startTransition(() => setIsActive(true))}
            />
          </Collapsible>

          <Collapsible 
            name='Audience'
            isOpen={isAudienceOpen}
            onChange={(open) => setIsAudienceOpen(open)}
            exclude={audienceExclude}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='audience-switch'
                value={audienceExclude}
                onChange={setAudienceExclude}
                color='red'
              />
            </div> : ''}
            <DoubleRangeSlider
              min={0}
              max={1000000000}
              step={1}
              audienceRange={audienceRange}
              onChange={(range) => setAudienceRange(range)}

            />
          </Collapsible>

          <Collapsible 
            name='Status'
            summ={statuses.length}
            isOpen={isStatusOpen}
            onChange={(open) => setIsStatusOpen(open)}
          >
            <StatusChooser
              selected={statuses}
              onChange={(checklist) => setStatuses(checklist)}
              onClose={() => setIsActive(true)}
            />
          </Collapsible>

          <Collapsible
            name='State-affiliated'
            summ={affiliationCountries.length}
            isOpen={isAffiliationCountriesOpen}
            onChange={(open) => setIsAffiliationCountriesOpen(open)}
            exclude={affiliationCountriesExclude}
          >
            <AffiliationCountries
              selected={affiliationCountries}
              onChange={(value) => setAffiliationCountries(value)}
              onClose={() => startTransition(() => setIsActive(true))}
              onExcludeChange={(bool) => setAffiliationCountriesExclude(bool)}
              excludeValue={affiliationCountriesExclude}
            />

          </Collapsible>

          <Collapsible
            name='Compromised accounts'
            summ={IoCs.length}
            isOpen={isIoCsOpen}
            onChange={(open) => setIsIoCsOpen(open)}
          >
            <IoCChooser
              selected={IoCs}
              onChange={(checklist) => setIoCs(checklist)}
            />
          </Collapsible>

          <Collapsible
            name='Inauthentic behavior'
            isOpen={isShowOnlyBotsOpen}
            onChange={(open) => setIsShowOnlyBotsOpen(open)}
          >
            <BotsChooser
              checked={showOnlyBots}
              onChange={(value) => setShowOnlyBots(value)}
            />
          </Collapsible>

          { showTagsFilter ? <Collapsible
            name='Special tags'
            isOpen={isSourceTagsOpen}
            onChange={(open) => setIsSourceTagsOpen(open)}
          >
            <TagsChooser
              selected={sourceTags}
              onChange={(value) => setSourceTags(value)}
            />
          </Collapsible> : '' }
        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            type='button'
            onClick={() => {
              onChange(
                platforms,
                countries.map(country => country.value),
                audienceRange,
                statuses,
                affiliationCountries.map(country => country.value),
                IoCs,
                showOnlyBots,
                sourceTags,
                affiliationCountriesExclude,
                countriesExclude,
                audienceExclude
              )
              setIsActive(false)
            }}
          >
            {t('Apply')}
          </button>

          <button 
            type='button'
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setPlatforms([]);
              setStatuses([]);
              setAudienceRange([0, 1000000000]);
              setCountries([]);
              setAffiliationCountries([]);
              setIoCs([]);
              setShowOnlyBots(false);
              setSourceTags([]);
              setAffiliationCountriesExclude(false);
              setCountriesExclude(false);
              setAudienceExclude(false);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>


    </div>
  )
}