import { useState, useEffect } from 'react';
import styles from './ImageCarousel.module.scss';
import { ReactComponent as CloseIcon } from '../../pages/NarrativePage/assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/chevronLeft.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/chevronRight.svg';

export const ImageCarousel = ({ images, initialIndex = 0, isOpen, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  const handleClickInside = (event) => {
    if (
      event.button !== 2 && // Check if the right mouse button is not clicked
      !event.target.closest(`.${styles.prevButton}`) &&
      !event.target.closest(`.${styles.nextButton}`) &&
      !event.target.closest(`.${styles.closeButton}`)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      document.addEventListener('mousedown', handleClickInside);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickInside);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickInside);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.carousel}>
      <button className={styles.closeButton} onClick={handleClose}><CloseIcon/></button>
      <button className={styles.prevButton} onClick={handlePrev}><LeftArrowIcon/></button>
      <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className={styles.image} />
      <button className={styles.nextButton} onClick={handleNext}><RightArrowIcon/></button>
    </div>
  );
};

