import React, { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { noop } from '../utils/common';
import { ampli } from "../ampli";
import websocketConnection from '../websocket';


export const authProvider = {
  async getToken(userCredentials: UserCredentials) {
    const response = await fetch('/API/v1/token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userCredentials),
    });

    if (!response.ok) {
      const data = await response.json();
      if (response.status === 403) {
        return data;
        // throw new Error(data?.detail);
      }
      throw new Error(data?.detail);
    }

    return await response.json();
  },

  async removeToken() {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    const token = localStorage.getItem('token');

    if (token !== null) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      const { ok, status } = await fetch('/API/v1/token', {
        method: 'DELETE',
        headers: headers,
      });

      if (!ok) {
        console.log('Failed to remove token. Got status: ', status);
      }

      return true;
    } catch (error) {
      return false;
    }
  },
};

interface UserCredentials {
  email: string;
  password: string;
  code: string;
}

export interface AuthContextProps {
  userCredentials: UserCredentials | null;
  token: string | null;
  signIn: (userCredentials: UserCredentials) => void;
  signOut: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  userCredentials: null,
  token: null,
  signIn: noop,
  signOut: noop,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userCredentials, setUserCredentials] =
    React.useState<UserCredentials | null>(null);
  const [token, setToken] = React.useState<string | null>(
    localStorage.getItem('token'),
  );

  const signIn = useCallback(async (userCredentials: UserCredentials) => {
    const data = await authProvider.getToken(userCredentials);

    if (data?.access_token) {
      const token = data.access_token;
      localStorage.setItem('token', token);
      setToken(token);
      setUserCredentials(userCredentials);
      websocketConnection.token = token;
      websocketConnection.connect();

      ampli.client.setGroup('workspace_id', `${data.workspace_id}`);
      ampli.identify(`user:${data.user_id}`, {})

      ampli.track({
        event_type: 'Sign In',
        event_properties: {
          user_id: data.user_id,
          name: 'Sign In'
        },
      });
      return data;
    } else if (data?.detail) {
      return data;
    }
  }, []);

  const signOut = useCallback(async () => {
    const loggedOut = await authProvider.removeToken();

    if (loggedOut) {
      localStorage.removeItem('token');
      localStorage.removeItem('workspace_id');
      localStorage.removeItem('workspace_name');
      setUserCredentials(null);
      setToken(null);
      websocketConnection.close();
      websocketConnection.token = null;

      ampli.track({event_type: 'Sign Out'})
    }
  }, []);

  return (
    <AuthContext.Provider value={{ userCredentials, token, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const RequireAuth: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth?.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
