import { ReactComponent as WebIcon } from '../assets/web.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import { ReactComponent as YoutubeIcon } from '../assets/youtube.svg';
import { ReactComponent as VkontakteIcon } from '../assets/vkontakte.svg';
import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as TelegramIcon } from '../assets/telegram.svg';
import { ReactComponent as OkIcon } from '../assets/odnoklassniki.svg';
import { ReactComponent as TikTokIcon } from '../assets/tiktok.svg';

export const platformIconsMapping = {
  TELEGRAM: <TelegramIcon />,
  VK: <VkontakteIcon />,
  TWITTER: <TwitterIcon />,
  WEB: <WebIcon />,
  FACEBOOK: <FacebookIcon/>,
  ADMIXER: <WebIcon />,
  OK: <OkIcon/>,
  YOUTUBE: <YoutubeIcon/>,
  TIKTOK: <TikTokIcon/>,
}

export const platformNamesMapping = {
  TELEGRAM: 'Telegram',
  VK: 'VK',
  TWITTER: 'Twitter',
  WEB: 'Web',
  FACEBOOK: 'Facebook',
  YOUTUBE: 'Youtube',
  OK: 'OK',
  TIKTOK: 'TikTok',
}

export const platformOptions = [
  { value: 'TELEGRAM', label: platformNamesMapping['TELEGRAM'] },
  { value: 'TIKTOK', label: platformNamesMapping['TIKTOK']},
  { value: 'VK', label: platformNamesMapping['VK'] },
  { value: 'TWITTER', label: platformNamesMapping['TWITTER'] },
  { value: 'WEB', label: platformNamesMapping['WEB'] },
  { value: 'FACEBOOK', label: platformNamesMapping['FACEBOOK']},
  { value: 'YOUTUBE', label: platformNamesMapping['YOUTUBE']},
];

export const platformMapping = {
  'TELEGRAM': 1,
  'FACEBOOK': 2,
  'VK': 4,
  'TWITTER': 3,
  'WEB': 5,
  'YOUTUBE': 7,
  'TIKTOK': 8,
}