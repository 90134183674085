import { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { ampli } from '../../ampli';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { WidgetContentModal } from '../WidgetContentModal/WidgetContentModal';
import { Tooltip } from 'react-tooltip';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SentimentBar = ({
  data,
  tabList = ['messages', 'views', 'likes'],
  entity,
  entityType,
  sourceGroups,
  countries,
  startDate,
  endDate,
  report,
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [sentimentTabs, setSentimentTabs] = useState(tabList);
  const [sentimentBar, setSentimentBar] = useState(sentimentTabs[0]);
  const [modal, setModal] = useState({ isVisible: false, sentiment: null, contentType: null });

  const sentimentTabsMap = {
    messages: 'Messages',
    views: 'Views',
    likes: 'Reactions',
    reactions: 'Reactions',
    posts: 'Posts',
    comments: 'Comments',
  };

  const contentTypesMap = {
    posts: 'POST',
    comments: 'COMMENT',
    reactions: 'REACTION',
    messages: null,
    articles: 'ARTICLE',
    ads: 'AD',
    videos: 'VIDEO',
    reposts: 'REPOST',
  };


  useEffect(() => {
    setSentimentBar(sentimentTabs[0]);
  }, [sentimentTabs]);

  useEffect(() => {
    if(entityType === 'case' && entity?.type === 'IMPACT_ASSESSMENT') {
      setSentimentTabs(['messages', 'likes']);
    }
  
    if(entityType === 'story') {
      sentimentTabsMap['messages'] = 'All';
    }
  }, []);

  

  return (
    <div className="report-section">
      <h3 className="trend-chart-controlls">
        <div className="trend-chart-controlls__tabs">
          {t('Sentiments')}
          {sentimentTabs.map((tab) => {
            return (
              <button
                type="button"
                key={tab}
                className={sentimentBar === tab ? 'active' : ''}
                onClick={() => {
                  ampli.track({
                    event_type: 'Change metric in sentiment chart',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      entity_id: entity?.id,
                      entity_type: entityType,
                      metric: sentimentBar,
                    },
                  });
                  setSentimentBar(tab);
                }}
              >
                <span>{t(sentimentTabsMap[tab])}</span>
              </button>
            );
          })}
        </div>

        {entity?.sentiment_sampling_rate ? (
          <div
            className="sampling"
            data-tooltip-id="sampling-tooltip"
            data-tooltip-content={t('We used sampling to analyze this data. A sample is a statistically significant representation of the dataset, suitable for AI analysis. The sampling rate indicates the part of the total dataset included in the analysis.')}
            data-tooltip-place="bottom-start"
          >
            {t('Sampling')}:
            <span className='sampling__value'> {entity?.sentiment_sampling_rate.toPrecision(4)}%</span>
            <Tooltip id="sampling-tooltip" style={{ width: '200px' }} />
          </div>
        ) : (
          ''
        )}
      </h3>

      {data ? (
        <div className="card">
          <div className="card-body">
            <div className="sentiment-bar-container">
              {data?.positive[sentimentBar] === 0 ? (
                ''
              ) : (
                <span
                  className={
                    entityType !== 'story'
                      ? 'sentiment-bar-positive'
                      : 'sentiment-bar-positive clickable'
                  }
                  style={{
                    width: `${data?.positive[sentimentBar + '_share']}%`,
                  }}
                  onClick={() => {
                    if (entityType === 'story') {
                      setModal({
                        isVisible: true,
                        sentiment: 'POSITIVE',
                        contentType: contentTypesMap[sentimentBar],
                      });
                    }
                  }}
                >
                  {formatNumberSignificant(data?.positive[sentimentBar])}
                </span>
              )}
              {data?.neutral[sentimentBar] === 0 ? (
                ''
              ) : (
                <span
                  className={
                    entityType !== 'story'
                      ? 'sentiment-bar-neutral'
                      : 'sentiment-bar-neutral clickable'
                  }
                  style={{
                    width: `${data?.neutral[sentimentBar + '_share']}%`,
                  }}
                  onClick={() => {
                    if (entityType === 'story') {
                      setModal({
                        isVisible: true,
                        sentiment: 'NEUTRAL',
                        contentType: contentTypesMap[sentimentBar],
                      });
                    }
                  }}
                >
                  {formatNumberSignificant(data?.neutral[sentimentBar])}
                </span>
              )}
              {data?.negative[sentimentBar] === 0 ? (
                ''
              ) : (
                <span
                  className={
                    entityType !== 'story'
                      ? 'sentiment-bar-negative'
                      : 'sentiment-bar-negative clickable'
                  }
                  style={{
                    width: `${data?.negative[sentimentBar + '_share']}%`,
                  }}
                  onClick={() => {
                    if (entityType === 'story') {
                      setModal({
                        isVisible: true,
                        sentiment: 'NEGATIVE',
                        contentType: contentTypesMap[sentimentBar],
                      });
                    }
                  }}
                >
                  {formatNumberSignificant(data?.negative[sentimentBar])}
                </span>
              )}
            </div>
            <div className="sentiment-bar-stats">
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color positive"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Positive',
                )}: ${
                  data?.positive[sentimentBar + '_share'] === 0
                    ? '0'
                    : data?.positive[sentimentBar + '_share'].toPrecision(4)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color neutral"></span>
                <span className="sentiment-bar-stats-data">{`${t('Neutral')}: ${
                  data?.neutral[sentimentBar + '_share'] === 0
                    ? '0'
                    : data?.neutral[sentimentBar + '_share'].toPrecision(4)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color negative"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Negative',
                )}: ${
                  data?.negative[sentimentBar + '_share'] === 0
                    ? '0'
                    : data?.negative[sentimentBar + '_share'].toPrecision(4)
                }%`}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="widgetLoader">
          <LoaderSmall />
        </div>
      )}
      {modal.isVisible ? (
        <WidgetContentModal
          infoModal={modal.isVisible}
          tabIndex={1}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          // platformsOptions={platformOptions}
          audienceRange={null}
          narrative={report ? report : entity}
          sourceGroup={
            sourceGroups.length > 0
              ? sourceGroups?.map((sourceGroup) => sourceGroup.value)
              : null
          }
          source={null}
          sentimentOptions={[modal.sentiment]}
          eventIdsOptions={[entity.id]}
          startDate={startDate}
          endDate={endDate}
          contentTypeOptions={modal.contentType ? [modal.contentType] : null}
          // isShare={isShare}
          onChange={(bool) => {
            setModal({ isVisible: bool, country: null });
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
