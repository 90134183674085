import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from '../WorkspacesPage/Filter/Filter';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import './UserStatsPage.scss';
import { set } from 'date-fns';

export function UserStatsPage() {
  const { t } = useTranslation();
  const [stats, setStats] = useState(null);
  const [searchesStats, setSearchesStats] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

  const fetchData = useCallback(() => {
    const urlParams = new URLSearchParams();

    setStats(null);
    setSearchesStats(null);

    if (statuses?.length > 0) {
      statuses.forEach((status) => {
        urlParams.append('statuses', status);
      });
    }

    if (showOnlyFavorites) {
      urlParams.set('is_favorite_filter', 'true');
    }

    API.fetch('GET', `/API/v1/user_stats/narratives_per_workspace?${urlParams.toString()}`).then(
      (data) => {
        setStats(data);
      },
    );

    API.fetch('GET', `/API/v1/user_stats/searches_per_workspace?${urlParams.toString()}`).then(
      (data) => {
        setSearchesStats(data);
      },
    );
  }, [statuses, showOnlyFavorites]);

  useEffect(() => {
    fetchData();
  }, [statuses, showOnlyFavorites, fetchData]);


  if (!stats || !searchesStats) {
    return <Loader />;
  }

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>{t('User stats')}</span>
        </div>
      </div>
      <div className="next-card-header">
        <div className='user-stats-filter-container'>
          <Filter
            statusesFilter={statuses}
            showOnlyFavoritesFilter={showOnlyFavorites}
            onChange={(statuses, showOnlyFavorites) => {
              setStatuses(statuses);
              setShowOnlyFavorites(showOnlyFavorites);
            }}
          />
        </div>
        
      </div>
      <div className="card">
        <div className="card-header">
          <h2>{t('Narratives created per workspace')}</h2>
        </div>
        <div className="card-body">
          <table className="user_stats">
            <thead>
              <tr>
                <td>{t('Name')}</td>
                <td>{t('Status')}</td>
                <td>Last 1 day</td>
                <td>Last 7 days</td>
                <td>Last 30 days</td>
              </tr>
            </thead>
            <tbody>
              {stats.objects.map((statistic) => {
                return (
                  <tr key={statistic.workspace.id}>
                    <td className="name">{statistic.workspace.name}</td>
                    <td className="status">{statistic.workspace.status ?? '-'}</td>
                    <td className="statistic">{statistic.last_1_day}</td>
                    <td className="statistic">{statistic.last_7_days}</td>
                    <td className="statistic">{statistic.last_30_days}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h2>{t('Searches ran per workspace')}</h2>
        </div>
        <div className="card-body">
          <table className="user_stats">
            <thead>
              <tr>
                <td>{t('Name')}</td>
                <td>{t('Status')}</td>
                <td>Last 1 day</td>
                <td>Last 7 days</td>
                <td>Last 30 days</td>
              </tr>
            </thead>
            <tbody>
              {searchesStats.objects.map((statistic) => {
                return (
                  <tr key={statistic.workspace.id}>
                    <td className="name">{statistic.workspace.name}</td>
                    <td className="name">{statistic.workspace.status ?? '-'}</td>
                    <td className="statistic">{statistic.last_1_day}</td>
                    <td className="statistic">{statistic.last_7_days}</td>
                    <td className="statistic">{statistic.last_30_days}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
