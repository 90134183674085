import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const StatusChooser = ({ onChange, selected = []}) => {
  const { t } = useTranslation();

  const statusOptions = [
    { value: 'INTERNAL', label: t('Internal') },
    { value: 'DEACTIVATED', label: t('Deactivated') },
    { value: 'TRIAL', label: t('Trial') },
    { value: 'FREE_ACCESS', label: t('Free Access') },
    { value: 'PAID_CLIENT', label: t('Paid Client') },
    { value: 'PAID_CLIENT_UCBI', label: t('Paid Client UCBI') },
    { value: 'PAID_CLIENT_I3', label: t('Paid Client I3') },
  ];

  const handleSelect = () => {
    if (statusOptions.length !== selected.length) {
      onChange(statusOptions.map(status => status.value))
    } else {
      onChange([])
    }
  }

  return (
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{statusOptions.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {statusOptions.map(status => {
          return(
            <li key={status.value}>
              <input 
                id={`status_${status.value}`}
                type='checkbox'
                checked={selected.includes(status.value)}
                onChange={() => {
                  if (selected.includes(status.value)) {
                    onChange(selected.filter(v => v !== status.value)) 
                  } else {
                    onChange([...selected, status.value]) 
                  }
                }}
              />
              <label htmlFor={`status_${status.value}`}>{t(status.label)}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  );
};