import * as d3 from 'd3';
import cn from 'classnames';
import { format, parseISO, set } from 'date-fns';
import styles from './SourcePage.module.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../components/Tooltip/Tooltip.jsx';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../API';
import { ampli } from "../../ampli";
import { MessagesModal} from './MessagesModal.jsx';
import { Loader } from '../../components/Loader/Loader';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { Paginator } from '../../components/Paginator/Paginator';
import { ReactComponent as VerifiedTwitter } from './assets/verifiedTwitter.svg';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { ReactComponent as StatsIcon } from './assets/stats.svg';
import { ReactComponent as EngagementIcon } from './assets/stats.svg';
import { ReactComponent as AnchorIcon } from './assets/anchor.svg';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon1 } from '../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../assets/originalText.svg';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';
import { ReactComponent as SearchIcon } from '../../pages/SearchPage/assets/search.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { decodeSourceName } from '../../utils/decodeURI';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import { getSentimentName, getSentimentIcon } from "../../utils/sentiment";
import { ExpandableText } from '../../components/CollapsibleText/ExpandableText';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { cutString } from '../../utils/cutString';
import { MessageModal } from '../../components/MessagesTable/MessageModal';
import { replaceNewlinesWithBreaks } from '../../utils/replaceNewlinesWithBreaks';
import { ImagesContainer } from '../../components/Image/ImagesContainer';

import messageStyles from "../../components/MessagesTable/ExtendedMessageView.module.scss";


const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatRate = d3.format('.2f')

export const SourcePage = ({}) => {
  const { t } = useTranslation();
  const { sourceId } = useParams();
  const [messagesModal, setMessagesModal] = useState({isOpen: false, narrativeId: null});
  const [source, setSource] = useState(null);
  const [inbound, setInbound] = useState(null);
  const [outbound, setOutbound] = useState(null);
  const [narratives, setNarratives] = useState(null);
  const [threats, setThreats] = useState(null);
  const [stats, setStats] = useState(null);
  const [affiliations, setAffiliations] = useState(null);
  const [discreditations, setDiscreditations] = useState(null);
  const [inboundPage, setInboundPage] = useState(1);
  const [outboundPage, setOutboundPage] = useState(1);
  const [narrativesPage, setNarrativesPage] = useState(1);
  const [threatsPage, setThreatsPage] = useState(1);
  const [messages, setMessages] = useState(null);
  const [linkedActorsWidget, setLinkedActorsWidget] = useState(null);
  const [narrativesWidget, setNarrativesWidget] = useState(null);
  const [messageModal, setMessageModal] = useState({message: null, isOpen: false, narrartiveMessage: null});
  const [error, setError] = useState(null);


  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const nativeLang = currentUser?.translation_language ? currentUser?.translation_language : window.clientInformation.language.split('-', 1)[0].toUpperCase();

  const size = 10;

  const [highlights, setHighlights] = useState(['KEYWORDS']);

  const fetchSource = useCallback(() => {
    API.fetch('GET', `/API/v1/sources/${sourceId}`).then(setSource).catch(error => {
      setError(error.message)
    });
  }, [sourceId]);

  const fetchLinkedActorsData = useCallback(() => {
    Promise.all([
      API.fetch('GET', `/API/v1/sources/${sourceId}/connections/inbound?page=${inboundPage}&size=${size}`),
      API.fetch('GET', `/API/v1/sources/${sourceId}/connections/outbound?page=${outboundPage}&size=${size}`)
    ]).then(([inboundData, outboundData]) => {

      if(inboundData.objects?.length > 0) {
        setLinkedActorsWidget('inbound')
      } else if(outboundData.objects?.length > 0) {
        setLinkedActorsWidget('outbound')
      } else {
        setLinkedActorsWidget('inbound')
      }

      setInbound(inboundData);
      setOutbound(outboundData);
    }).catch(error => {
      // Handle error if any
      console.error("Error fetching data:", error);
    });
  }, [sourceId]);
  
  useEffect(() => {
    fetchLinkedActorsData();
  }, [fetchLinkedActorsData]);

  const fetchCasesAndThreatsData = useCallback(() => {
    Promise.all([
      API.fetch('GET', `/API/v1/narratives?source_id=${sourceId}&sorting=-date_created&is_threat=false&page=${narrativesPage}&size=${size}`),
      API.fetch('GET', `/API/v1/narratives?source_id=${sourceId}&sorting=-date_created&is_threat=true&page=${threatsPage}&size=${size}`)
    ]).then(([casesData, threatsData]) => {

      if(casesData.objects?.length > 0) {
        setNarrativesWidget('cases')
      } else if(threatsData.objects?.length > 0) {
        setNarrativesWidget('threats')
      } else {
        setNarrativesWidget('cases')
      }

      setNarratives(casesData);
      setThreats(threatsData);
    }).catch(error => {
      // Handle error if any
      console.error("Error fetching data:", error);
    });
  }, [sourceId]);
  
  useEffect(() => {
    fetchCasesAndThreatsData();
  }, [fetchCasesAndThreatsData]);


  const fetchInbound = (page) => {
    setInbound(null)
    API.fetch('GET', `/API/v1/sources/${sourceId}/connections/inbound?page=${page}&size=${size}`).then(setInbound)
  };

  const fetchOutbound = (page) => {
    setOutbound(null)
    API.fetch('GET', `/API/v1/sources/${sourceId}/connections/outbound?page=${page}&size=${size}`).then(setOutbound)
  };

  const fetchNarratives = (page) => {
    setNarratives(null)
    API.fetch('GET', `/API/v1/narratives?source_id=${sourceId}&sorting=-date_created&is_threat=false&page=${page}&size=${size}`).then(setNarratives);
  };

  const fetchThreats = (page) => {
    setThreats(null)
    API.fetch('GET', `/API/v1/narratives?source_id=${sourceId}&sorting=-date_created&is_threat=true&page=${page}&size=${size}`).then(setThreats);
  };

  const fetchMessages = useCallback(() => {
    setMessages(null)
    const urlParams = new URLSearchParams();
    urlParams.set('size', 5);
    urlParams.set('source_id', sourceId)

    if(source?.source_type === 'TELEGRAM') {
      urlParams.set('content_types', 'POST')
    }

    API.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then(data => {
      setMessages(data)
    })
  }, [sourceId])

  const fetchStatsAndAffiliations = useCallback(() => {
    setStats(null);
    setAffiliations(null);
    setDiscreditations(null);

    Promise.all([
      API.fetch('GET', `/API/v1/sources/${sourceId}/stats`),
      API.fetch('GET', `/API/v1/sources/${sourceId}/affiliations`),
      API.fetch('GET', `/API/v1/sources/${sourceId}/discreditations`)
    ]).then(([stats, affiliations, discreditations]) => {
      setStats(stats);
      setAffiliations(affiliations);
      setDiscreditations(discreditations);
    });
  }, [sourceId]);

  useEffect(() => {
    setSource(null)
    fetchSource()
  }, [sourceId]);

  
  useEffect(() => {
    fetchMessages(); 
  }, [fetchMessages])

  useEffect(() => {
    setInboundPage(1);
    setOutboundPage(1);
    setNarrativesPage(1);
    setThreatsPage(1);
  }, [sourceId]);

  useEffect(() => {
    fetchStatsAndAffiliations();
  }, [fetchStatsAndAffiliations])

  if(!source && error) {
    return <div className='noData'>{t(error)}</div>
  }

  if (!source || !sourceId) {
    return <Loader />;
  }

  // const sourceData = data[(source.id in data) ? source.id : 0];

  const entityTypes = {
    STATE_AGENCY: 'State agency',
    GOVERNMENT_OFFICIAL: 'Government official',
    STATE_OWNED_MEDIA: 'State owned media',
    REPORTEDLY_STATE_AFFILIATED: 'Reportedly state affiliated',
  };

  const statusMapping = {
    NEW: {class: messageStyles.new, name: 'New'},
    READ: {class: messageStyles.approved, name: 'Approved'},
    DELETED: {class: messageStyles.deleted, name: 'Deleted'}
  }

  const mapCompromisedEntityTypes = {
    INVOLVED_IN_INFLUENCE_OPERATIONS: {title: t('Influence operations'), tooltip: t('Account has been involved in influence operations')},
    HACKED_OR_STOLEN: {title: t('Hackers / Hacktivists'), tooltip: t('Engaged or claimed to engage in cyber attacks or similar activity')},
    SANCTIONS: {title: t('Sanctions'), tooltip: t('Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries')},
    DISINFORMATION: {title: t('Disinformation'), tooltip: t('Sources are frequently sharing disinformative content, which was autodetected by Osavul AI')}
  }

  const mapBotEntityTypes = {
    'suspended_by_platform': {
      title: 'Suspended by platform',
      tooltip: t('Account has been suspended by a platform')
    },
    'inauthentic_behavior': {
      title: t('Inauthentic behavior'),
      tooltip: t('Account was engaged in an inauthentic behavior'),
    },
    'immature_account': {
      title: t('Immature accounts'),
      tooltip: t('Recently created accounts or accounts with few followers'),
    },
  }

  let followers_cnt = source?.audience;
  let botType = null;
  if(source?.suspended_by_platform === true) {
    botType = mapBotEntityTypes['suspended_by_platform'];
  } else if(source?.inauthentic_behavior === true || source?.is_bot === true) {
    botType = mapBotEntityTypes['inauthentic_behavior'];
  }else if(source?.immature_account === true) {
    botType = mapBotEntityTypes['immature_account'];
  }

  let followersCountHeader = 'Audience';
  let averageReachHeader = 'Average post reach';
  let engagementHeader = 'Engagement rate';

  if(source.source_type === 'WEB') {
    followersCountHeader = 'Monthly audience';
    averageReachHeader = null;
    engagementHeader = null;
  }

  if(source.source_type === 'YOUTUBE') {
    averageReachHeader = 'Average video reach';
  }

  if(source.source_type === 'TELEGRAM') {
    engagementHeader = 'Engagement / views';
  }

  if(source.source_type === 'FACEBOOK') {
    engagementHeader = 'Engagement / followers';
    averageReachHeader = 'Average post engagement';
  }

  let affilliationProofName = null;
  let affiliation = affiliations?.length ? affiliations[0] : null;
  
  if(
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    affilliationProofName = affiliation?.proof_name_uk;
  } else {
    affilliationProofName = affiliation?.proof_name_en;
  }
  

  let discreditationProofName = null;
  let discreditation = discreditations?.length ? discreditations[0] : null;
  
  if(
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    discreditationProofName = discreditation?.proof_name_uk;
  } else {
    discreditationProofName = discreditation?.proof_name_en;
  }


  const makeAvatar = (sourceName) => {
    return sourceName.charAt(0).toUpperCase()
  }

  const translateMessage = (messageId, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    return API.fetch('POST', '/API/v1/translations/translate', null, body)
  }

  const toggleTranslate = (messageId, isTranslation) => {
    setMessages({...messages, objects: messages.objects.map(message => {
      if(message.id === messageId) {
        message.isTranslation = isTranslation;
        if (message.content_type === 'COMMENT' && message.parent_message) {
          message.parent_message.isTranslation = isTranslation;
        }
        return message;
      } else {
        return message;
      }
    })})
  }

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find(message => message.id === messageId);
    const originalPostText =
      target?.content_type === 'COMMENT' ? target?.parent_message?.text : '';
    if (target.translated) {
      toggleTranslate(messageId, isTranslation)
    } else {
      toggleTranslate(messageId, true)
      const nativeLanguage = currentUser?.translation_language
        ? currentUser?.translation_language
        : window.clientInformation.language.split('-', 1)[0];

      let newMessagesList = [...messages.objects];

      if (originalPostText) {
        Promise.allSettled([
          translateMessage(messageId, originalPostText, nativeLanguage),
          translateMessage(messageId, text, nativeLanguage),
        ]).then((results) => {
          if (results[0].status === 'fulfilled') {
            const originalPostData = results[0].value;

            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                let m = {
                  ...message,
                  parent_message: {
                    ...message.parent_message,
                    isTranslation: true,
                    translated: originalPostData.destination_text,
                  },
                };
                return m;
              } else {
                return message;
              }
            });
          } else {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  parent_message: {
                    ...message.parent_message,
                    isTranslation: true,
                    translated: t('We were not able to translate this text.'),
                    error: true,
                  },
                };
              } else {
                return message;
              }
            });
          }

          if (results[1].status === 'fulfilled') {
            const messageData = results[1].value;
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: messageData.destination_text,
                };
              } else {
                return message;
              }
            });
          } else {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: t('We were not able to translate this text.'),
                  error: true,
                };
              } else {
                return message;
              }
            });
          }

          setMessages({ ...messages, objects: newMessagesList });
        });
      } else {
        translateMessage(messageId, text, nativeLanguage)
          .then((data) => {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: data.destination_text,
                };
              } else {
                return message;
              }
            });

            setMessages({ ...messages, objects: newMessagesList });
          })
          .catch((e) => {
            newMessagesList = newMessagesList.map((message) => {
              if (message.id === messageId) {
                return {
                  ...message,
                  isTranslation: true,
                  translated: t('We were not able to translate this text.'),
                  error: true,
                };
              } else {
                return message;
              }
            });

            setMessages({ ...messages, objects: newMessagesList });
          });
      }
    }
  }

  let messagesLink = `/search?source_ids=${sourceId}&actor_search=${sourceId}`;
  if (source?.source_type === 'TELEGRAM') {
    messagesLink = `/search?source_ids=${sourceId}&content_types=POST&actor_search=${sourceId}`
  }

  const getThreatName = (threat) => {
    if (!threat?.is_public) {
      return threat?.name
    }

    let public_name;
    if(
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      public_name = threat?.public_name_uk;
    } else {
      public_name = threat?.public_name_en;
    }

    return public_name ?? threat?.name
  }

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>
              <Link to="/sources">{t('Actors')}</Link>
            </span>
            <span>{decodeSourceName(source.name)}</span>
          </div>
          
        </div>
        <div className="controls">
            {currentUser?.is_super_admin ? <Tooltip content={t('Edit')} position='bottom'>
                <Link
                  to={`/sources/${sourceId}/edit`}
                  className={cn(styles.buttonText, 'button', 'new-button')}
                >
                  <EditIcon />
                  {/* {t('Edit')} */}
                </Link>
              </Tooltip> : ''}
          </div>
      </div>

      <div className={styles.header} id='#top'>
        <div className={styles.avatarWrapper}>
          {/* <div className={styles.avatar}><img src={sourceData.avatar}></img></div> */}
          <div className={styles.avatar}>{makeAvatar(decodeSourceName(source.name))}</div>
        </div>
        <div className={styles.headerMain}>
          <div className={styles.headerName}>
            <span>{decodeSourceName(source.name)}</span>
            {source?.is_verified ? (
              <span className={styles.verified}>
                <VerifiedTwitter/>
              </span>
            ) : (
              ''
            )}
          </div>
          <div className={styles.sourceLink}>
            <a href={source?.url} target="_blank" rel="noreferrer">{decodeSourceName(source?.url)}</a>
          </div>
          <div className={styles.headerInfo}>
            <ul className={styles.headerList}>
              <li>
                <span>{t('Actor platform')}:</span> {source?.source_type}
              </li>
              <li>
                <span> {t('Actor type')}:</span>
                {source?.source_type === 'WEB' ? t('WEB') : t('Social media')}
              </li>
              <li>
                <span>{t('Actor origin country')}:</span>{source?.origin_country ? source.origin_country.name : t("N/A")}
              </li>
              <li>
                <span>{t('Actor main language')}:</span> {source.language ? source.language : t("N/A")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*
      {followers_cnt > 0 ? <div className={styles.metric}>
          <div className={styles.metricHeader}>
            <span className={`${styles.metricIcon} ${styles.blue}`}>
              <UserIcon />
            </span>
            <span>{t(followersCountHeader)}</span>
          </div>
          <div className={styles.metricData}>{formatNumberSignificant(followers_cnt)}</div>
        </div> : ''}
      */}

      {
        stats === null || affiliations === null || discreditations === null ? <LoaderSmall/> : <>
          <div className={styles.metrics}>
            {followers_cnt > 0 ? <div className={styles.metric}>
              <div className={styles.metricHeader}>
                <span className={`${styles.metricIcon} ${styles.blue}`}>
                  <UserIcon/>
                </span>
                <span>{t(followersCountHeader)}</span>
              </div>
              <div className={styles.metricData}>{formatNumberSignificant(followers_cnt)}</div>
            </div> : ''}
            {stats?.last_7d_post_reach > 0 ? <div className={styles.metric}>
              <div className={styles.metricHeader}>
                <span className={`${styles.metricIcon} ${styles.red}`}>
                  <StatsIcon/>
                </span>
                <span>{t(averageReachHeader)}</span>
              </div>
              <div
                className={styles.metricData}>{stats?.last_7d_post_reach >= 1 ? formatNumberSignificant(stats?.last_7d_post_reach) : formatRate(stats?.last_7d_post_reach)}</div>
            </div> : ''}
            {stats?.last_7d_engagement > 0 ? <div className={styles.metric}>
              <div className={styles.metricHeader}>
            <span className={`${styles.metricIcon} ${styles.green}`}>
              <EngagementIcon/>
            </span>
                <span>{t(engagementHeader)}</span>
              </div>
              <div
                className={styles.metricData}>{stats?.last_7d_engagement > 0 ? formatRate(stats?.last_7d_engagement) : 0}%
              </div>
            </div> : ''}
          </div>

          {affiliation !== null 
            ? <div className={styles.sources}>
                  <div className={styles.sourcesHeading}>
                    {t('Actor state affiliation')}
                  </div>
                  <div className={styles.affiliation}>
                  <div className={styles.affiliationWrapper}>
                    <div className={styles.item}>
                      <div className={styles.itemHeading}>{t('Country')}</div>
                      <p>{affiliation?.country?.name}</p>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemHeading}>{t('Type')}</div>
                      <p>{t(entityTypes[affiliation?.entity_type])}</p>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemHeading}>{t('Proof')}</div>
                      <p>
                        <a href={affiliation?.proof_link ? affiliation?.proof_link : ''} target="_blank" rel="noreferrer">{affilliationProofName ? affilliationProofName : ''}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            : ''}
            {discreditation !== null 
              ? <div className={styles.sources}>
                    <div className={styles.sourcesHeading}>
                      {t('Indicators of Compromise (IoC)1')}
                    </div>
                    <div className={styles.affiliation}>
                    <div className={styles.affiliationWrapper}>
                      <div className={styles.item}>
                        <div className={styles.itemHeading}>{t('Type')}</div>
                        <p>
                          {mapCompromisedEntityTypes[discreditation?.entity_type].title}
                          <span className={styles.tooltipControls}>
                            <Tooltip content={mapCompromisedEntityTypes[discreditation?.entity_type]?.tooltip} position='right'>
                              <AnchorIcon/>
                            </Tooltip>
                          </span>
                        </p>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemHeading}>{t('Proof')}</div>
                        <p>
                          <a href={discreditation?.proof_link ? discreditation?.proof_link : ''} target="_blank" rel="noreferrer">{discreditationProofName ? discreditationProofName : ''}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              : ''}
            {botType
              ?  <div className={styles.sources}>
                  <div className={styles.sourcesHeading}>
                    {t('Inauthentic behavior')}
                  </div>
                  <div className={styles.affiliation}>
                    <div className={styles.affiliationWrapper}>
                      <div className={styles.item}>
                        <div className={styles.itemHeading}>{t('Reason')}</div>
                        <p>
                          {botType?.title}
                          <span className={styles.tooltipControls}>
                            <Tooltip content={botType?.tooltip} position='right'>
                              <AnchorIcon/>
                            </Tooltip>
                          </span>
                        </p>
                        
                      </div>
                    </div>
                  </div>
                </div>
              : ''}
        </>
      }

      {/* <div className={styles.politics}>
        <ul className={styles.politicsList}>
          <li>
            <span className={styles.listHeading}>{t('Political compass')}:</span>
            <span
              className={styles.anchor}
              data-tooltip-id="my-tooltip"
              data-tooltip-content={t("The political compass is based on expert analysis of the actor content")}
            >
              <AnchorIcon />
            </span>
            <ReactTooltip
              id="my-tooltip"
              place="top"
              className={styles.tooltip}
            ></ReactTooltip>
            {sourceData.political_compass}
          </li>
         {sourceData.ownership !== null ? <li>
            <span className={styles.listHeading}>{t('Ownership / Holding')}:</span>
            <span
              className={styles.anchor}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="The political compass is based on expert analysis of the actor content"
            >
              <AnchorIcon />
            </span>
            <ReactTooltip
              id="my-tooltip"
              place="top"
              className={styles.tooltip}
            ></ReactTooltip>
            {sourceData.ownership}
          </li> : ''}
        </ul>
      </div> */}

      {/* {Object.keys(sourceData.disinformation).length > 0 ? <div className={styles.campaigns}>
        <div className={styles.campaignsHeading}>
          {t('Actor involvement in disinformation campaigns')}
        </div>
        <div className="list-content">
          <div className="card">
            <div className="card-body">
              <table className={styles.messages}>
                <thead>
                  <tr>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Name')}</span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Link')}</span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Message')}</span>
                      </div>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {sourceData.disinformation.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td className={styles.link}>
                          <a href={item.link} target="_blank" rel="noreferrer">
                            {item.link}
                          </a>
                        </td>
                        <td className="message">
                          <ExpandableText length={50} text={item.message} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> : ''} */}

      

      <div className="report-section">
        <h3 className="trend-chart-controlls">
          {t('Actor background')}
          {linkedActorsWidget ? <button
            type="button"
            className={narrativesWidget === 'cases' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in narratives widget',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  source_id: source.id,
                  metric: 'cases',
                }
              });
              setNarrativesWidget('cases');
            }}
          >
            <span>{t('Cases')}</span>
            <span className={styles.tooltipControls}>
              <Tooltip 
                content={t('Cases where the content from the current actor is tracked')}
                position='right'
                > 
                <AnchorIcon />
              </Tooltip>
            </span>
          </button> : ''}
          {narrativesWidget ? <button
            type="button"
            className={narrativesWidget === 'threats' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in narratives widget',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  source_id: source.id,
                  metric: 'threats',
                }
              });
              setNarrativesWidget('threats');
            }}
          >
            <span>{t('Threats')}</span>
            <span className={styles.tooltipControls}>
            <Tooltip 
              content={t('Threats where the content from the current actor is tracked')}
              position='right'
              > 
              <AnchorIcon />
            </Tooltip>
          </span>
          </button> : ''}
          
        </h3>

        <div className="card">
          <div className="">
            {!narratives || !threats 
              ? <div className={styles.card}><Loader/></div>
              : (narratives?.objects?.length > 0 || threats?.objects?.length > 0 ?
              <div className={styles.linkedActorsWrapper}>
                {narrativesWidget === 'cases' && narratives?.objects?.length > 0 ? <div className={styles.sources}>
                
                  <div className={styles.tableWrapper}>
                    <table className={styles.cases} id='narratives'>
                      <thead>
                        <tr>
                          <td>
                            <div className={styles.wrapper}>
                              <span>{t('Case')}</span>
                            </div>
                          </td>
                          <td>
                            <div className={styles.wrapper}>
                              <span>{t('Messages')}</span>
                            </div>
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {narratives.objects.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <span className={styles.modalLink} onClick={() => setMessagesModal({isOpen: true, narrativeId: item.id})}>{item.name}</span>
                              </td>
                              <td className="message">
                                {formatNumber(item.total_messages)}
                              </td>
                              {/* <td className="link">
                                <span onClick={() => setMessagesModal({isOpen: true, narrativeId: item.id})}><ExpandIcon /></span>
                            </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                      
                    
                  </div>
                  <div className={styles.paginator}>
                    <Paginator
                      size={size}
                      page={narrativesPage}
                      total={narratives?.total}
                      id='narratives'
                      onPageChange={(page) => {
                        setNarrativesPage(page);
                        fetchNarratives(page);
                      }}
                    />
                  </div>
                </div> : (
                  narrativesWidget === 'cases' && narratives?.objects?.length === 0 
                  ? (
                    <div className={styles.header}>
                      <div className={styles.noData}>{t('There is no data to display')}</div>
                    </div>
                  ) : '')}

                {narrativesWidget === 'threats' && threats?.objects?.length > 0 ? <div className={styles.sources}>
                  <div className={styles.tableWrapper}>
                    <table className={styles.cases} id='threats'>
                      <thead>
                        <tr>
                          <td>
                            <div className={styles.wrapper}>
                              <span>{t('Threat')}</span>
                            </div>
                          </td>
                          <td>
                            <div className={styles.wrapper}>
                              <span>{t('Messages')}</span>
                            </div>
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {threats.objects.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <span className={styles.modalLink} onClick={() => setMessagesModal({isOpen: true, narrativeId: item.id})}>{ getThreatName(item) }</span>
                              </td>
                              <td className="message">
                                {formatNumber(item.total_messages)}
                              </td>
                              {/* <td className="link">
                                <span onClick={() => setMessagesModal({isOpen: true, narrativeId: item.id})}><ExpandIcon /></span>
                            </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.paginator}>
                    <Paginator
                      size={size}
                      page={threatsPage}
                      total={threats?.total}
                      id='threats'
                      onPageChange={(page) => {
                        setThreatsPage(page);
                        fetchThreats(page);
                      }}
                    />
                  </div>
                </div> : (
                  narrativesWidget === 'threats' && threats?.objects?.length === 0 
                  ? (
                    <div className={styles.header}>
                      <div className={styles.noData}>{t('There is no data to display')}</div>
                    </div>
                  ) : '')}
              </div> : (
                <div className={styles.header}>
                  <div className={styles.noData}>{t('There is no data to display')}</div>
                </div>)
              )}
          </div>
        </div>
      </div>

      <div className="report-section">
        <h3 className="trend-chart-controlls">
          {t('Linked actors')}
          {linkedActorsWidget ? <button
            type="button"
            className={linkedActorsWidget === 'inbound' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in linked actors widget',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  source_id: source.id,
                  metric: 'inbound actors',
                }
              });
              setLinkedActorsWidget('inbound');
            }}
          >
            <span>{t('Inbound')}</span>
            <span className={styles.tooltipControls}>
              <Tooltip
                content={t('Actors that generated the most reposts, mentions and comments to the current actor')}
                position='right'
              >
                <AnchorIcon/>
              </Tooltip>
            </span>
          </button> : ''}
          {linkedActorsWidget ? <button
            type="button"
            className={linkedActorsWidget === 'outbound' ? 'active' : ''}
            onClick={() => {
              ampli.track({
                event_type: 'Change metric in linked actors widget',
                event_properties: {
                  user_id: currentUser?.id,
                  workspace_id: currentUser?.workspace_id,
                  source_id: source.id,
                  metric: 'outbound actors',
                }
              });
              setLinkedActorsWidget('outbound');
            }}
          >
            <span>{t('Outbound')}</span>
            <span className={styles.tooltipControls}>
            <Tooltip
              content={t('Actors which were the most reposted, mentioned and commented by the current actor')}
              position='right'
            >
              <AnchorIcon/>
            </Tooltip>
          </span>
          </button> : ''}

        </h3>

        <div className="card">
          <div className="">
            {!inbound || !outbound
              ? <div className={styles.card}><Loader/></div>
              : (inbound?.objects?.length > 0 || outbound?.objects?.length > 0 ?
                  <div className={styles.linkedActorsWrapper}>
                    {linkedActorsWidget === 'inbound' && inbound?.objects?.length > 0 ?
                      <div className={styles.sources}>

                        <div className={styles.tableWrapper}>
                          <table className={styles.linkedActors} id='inbound'>
                            <thead>
                            <tr>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Name')}</span>
                                </div>
                              </td>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Location')}</span>
                                </div>
                              </td>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Audience')}</span>
                                </div>
                              </td>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Links')}</span>
                                </div>
                              </td>
                            </tr>
                            </thead>

                            <tbody>
                            {inbound?.objects?.map((item) => {

                              let followers_cnt = item.audience;
                              let botType = null;
                              if(item?.suspended_by_platform === true) {
                                botType = mapBotEntityTypes['suspended_by_platform'];
                              } else if(item?.inauthentic_behavior === true || item?.is_bot === true) {
                                botType = mapBotEntityTypes['inauthentic_behavior'];
                              }else if(item?.immature_account === true) {
                                botType = mapBotEntityTypes['immature_account'];
                              }

                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className={styles.sourceNameWrapper}>
                                      <div
                                        className={styles.platformWrapper}>{platformIconsMapping[item.source_type]}</div>
                                      <div className={styles.sourceNameContainer}>
                                    <span>
                                      <Link to={`/sources/${item?.id}`}>{decodeSourceName(item?.name)}</Link>
                                      {item?.is_verified ? (
                                        <span className={styles.verifiedSource}>
                                            <VerifiedTwitter/>
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                        <div className={styles.sourceInfo}>
                                          <span>#{item.id}</span>
                                          <span>&#8226;</span>
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {cutString(decodeSourceName(item?.url))}
                                          </a>
                                          {/* <span>&#8226;</span>
                                      <span className={sourceStatusClasses[source.status]}>{statusMapping[source.status]}</span> */}
                                        </div>
                                        <div className={styles.tagsContainer}>
                                          {item?.affiliations?.length > 0 ? <div className={styles.dualTag}>
                                              <div className={styles.tagKey}>{t('State-affiliated')}</div>
                                              <div className={styles.tagValue}>{item?.affiliations[0]?.country?.code}</div>
                                            </div> : ''}
                                          {item?.discreditations?.length > 0
                                            ? <Tooltip content={mapCompromisedEntityTypes[item?.discreditations[0]?.entity_type]?.tooltip} position='right'>
                                                <div className={styles.tag}>
                                                  {mapCompromisedEntityTypes[item?.discreditations[0]?.entity_type]?.title}
                                                </div>
                                              </Tooltip>
                                            : ''}

                                          {botType === null
                                            ? ''
                                            : <div className={styles.tag}>
                                                {botType?.title}
                                              </div>}

                                          {source?.tags?.length > 0 
                                            ? <span>
                                                {source?.tags?.map(tag => {
                                                  let tagName = null;
                                                  let item = tag?.level_0 !== null ? tag?.level_0 : null;
                                                  
                                                  if(
                                                    localStorage.getItem('i18nextLng') === 'ua-UK' ||
                                                    localStorage.getItem('i18nextLng') === 'ru-RU' ||
                                                    localStorage.getItem('i18nextLng') === 'ru' ||
                                                    localStorage.getItem('i18nextLng') === 'uk'
                                                  ) {
                                                    tagName = item?.name_uk;
                                                  } else {
                                                    tagName = item?.name_en;
                                                  }
                                                  
                                                  return(
                                                    <div className={styles.tag}>
                                                      {tagName}
                                                    </div>
                                                  )
                                                })}
                                              </span> 
                                            : ''}

                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.origin_country ? item.origin_country.name : t('N/A')}</td>
                                  <td>{formatNumberSignificant(followers_cnt)}</td>
                                  <td className="message">
                                    {formatNumber(item.mentions_count)}
                                    {/* <ExpandableText length={50} text={item.message} /> */}
                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          </table>


                        </div>
                        <div className={styles.paginator}>
                          <Paginator
                            size={size}
                            page={inboundPage}
                            total={inbound.total}
                            id='inbound'
                            onPageChange={(page) => {
                              setInboundPage(page);
                              fetchInbound(page)
                            }}
                          />
                        </div>
                      </div> : (
                        linkedActorsWidget === 'inbound' && inbound?.objects?.length === 0
                          ? (
                            <div className={styles.header}>
                              <div className={styles.noData}>{t('There is no data to display')}</div>
                            </div>
                          ) : '')}

                    {linkedActorsWidget === 'outbound' && outbound?.objects?.length > 0 ?
                      <div className={styles.sources}>
                        <div className={styles.tableWrapper}>
                          <table className={styles.linkedActors} id='outbound'>
                            <thead>
                            <tr>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Name')}</span>
                                </div>
                              </td>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Location')}</span>
                                </div>
                              </td>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Audience')}</span>
                                </div>
                              </td>
                              <td>
                                <div className={styles.wrapper}>
                                  <span>{t('Links')}</span>
                                </div>
                              </td>
                            </tr>
                            </thead>

                            <tbody>
                            {outbound?.objects?.map((item) => {
                              let followers_cnt = item.audience;
                              let botType = null;
                              if(item?.suspended_by_platform === true) {
                                botType = mapBotEntityTypes['suspended_by_platform'];
                              } else if(item?.inauthentic_behavior === true || item?.is_bot === true) {
                                botType = mapBotEntityTypes['inauthentic_behavior'];
                              }else if(item?.immature_account === true) {
                                botType = mapBotEntityTypes['immature_account'];
                              }

                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className={styles.sourceNameWrapper}>
                                      <div
                                        className={styles.platformWrapper}>{platformIconsMapping[item.source_type]}</div>
                                      <div className={styles.sourceNameContainer}>
                                    <span>
                                      <Link to={`/sources/${item.id}`}>{decodeSourceName(item.name)}</Link>
                                      {item?.is_verified ? (
                                        <span className={styles.verifiedSource}>
                                            <VerifiedTwitter/>
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                   
                                        <div className={styles.sourceInfo}>
                                          <span>#{item.id}</span>
                                          <span>&#8226;</span>
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {cutString(decodeSourceName(item?.url))}
                                          </a>
                                          {/* <span>&#8226;</span>
                                      <span className={sourceStatusClasses[source.status]}>{statusMapping[source.status]}</span> */}
                                        </div>
                                        <div className={styles.tagsContainer}>
                                          {item?.affiliations?.length > 0 ? <div className={styles.dualTag}>
                                              <div className={`${styles.tagKey} ${styles.affiliationsKey}`}>{t('State-affiliated')}</div>
                                              <div className={`${styles.tagValue} ${styles.affiliationsValue}`}>{item?.affiliations[0]?.country?.code}</div>
                                            </div> : ''}
                                          {item?.discreditations?.length > 0
                                            ? <Tooltip content={mapCompromisedEntityTypes[item?.discreditations[0]?.entity_type]?.tooltip} position='right'>
                                                <div className={styles.tag}>{mapCompromisedEntityTypes[item?.discreditations[0]?.entity_type]?.title}</div>
                                              </Tooltip>
                                            : ''}

                                          {botType === null
                                            ? ''
                                            : <div className={styles.tag}>
                                                {botType?.title}
                                              </div>}
                                          
                                          {source?.tags?.length > 0 
                                            ? <span>
                                                {source?.tags?.map(tag => {
                                                  let tagName = null;
                                                  let item = tag?.level_0 !== null ? tag?.level_0 : null;
                                                  
                                                  if(
                                                    localStorage.getItem('i18nextLng') === 'ua-UK' ||
                                                    localStorage.getItem('i18nextLng') === 'ru-RU' ||
                                                    localStorage.getItem('i18nextLng') === 'ru' ||
                                                    localStorage.getItem('i18nextLng') === 'uk'
                                                  ) {
                                                    tagName = item?.name_uk;
                                                  } else {
                                                    tagName = item?.name_en;
                                                  }
                                                  
                                                  return(
                                                    <div className={styles.tag}>
                                                      {tagName}
                                                    </div>
                                                  )
                                                })}
                                              </span> 
                                            : ''}

                                        </div>

                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.origin_country ? item.origin_country.name : t('N/A')}</td>
                                  <td>{formatNumberSignificant(followers_cnt)}</td>
                                  <td className="message">
                                    {formatNumber(item.mentions_count)}
                                    {/* <ExpandableText length={50} text={item.message} /> */}
                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          </table>
                        </div>
                        <div className={styles.paginator}>
                          <Paginator
                            size={10}
                            page={outboundPage}
                            total={outbound.total}
                            id='outbound'
                            onPageChange={(page) => {
                              setOutboundPage(page);
                              fetchOutbound(page)
                            }}
                          />
                        </div>
                      </div> : (
                        linkedActorsWidget === 'outbound' && outbound?.objects?.length === 0
                          ? (
                            <div className={styles.header}>
                              <div className={styles.noData}>{t('There is no data to display')}</div>
                            </div>
                          ) : '')}
                  </div> : (
                    <div className={styles.header}>
                      <div className={styles.noData}>{t('There is no data to display')}</div>
                    </div>)
              )}
          </div>
        </div>
      </div>


      <div className={styles.sources}>
        <div className={styles.sourcesHeading}>
          {t('Last messages')}
        </div>

        {!messages ? <div className={styles.card}><Loader/></div> : messages.objects.length > 0 ? <div>
          <div className="list-content">
            <div className="card">
              {/* <div className="card-body"> */}
              <table className={`${messageStyles.extendedMessages} ${styles.extendedMessages}`}>
                {/* <thead>
                    <tr>
                      <td className={messageStyles.headerInfo}>
                        <span>{t('Total messages')}: {formatNumber(messages.total)}</span>
                      </td>
                      <td className='message-controlls'>
                      </td>
                    </tr>
                  </thead> */}
                <tbody>
                {messages.objects.map(message => {
                  let followers_cnt = message?.source?.audience;
                  let textClass = messageStyles.extendedMessage;
                  let textClassParent = messageStyles.extendedMessage;

                  if (message?.parent_message?.error && message?.parent_message?.isTranslation) {
                    textClassParent = `${messageStyles.extendedMessage} ${messageStyles.error}`
                  } else if (message.error && !message.isTranslation) {
                    textClassParent = messageStyles.extendedMessage;
                  }

                  if (message.error && message.isTranslation) {
                    textClass = `${messageStyles.extendedMessage} ${messageStyles.error}`
                  } else if (message.error && !message.isTranslation) {
                    textClass = messageStyles.extendedMessage;
                  }

                  const textForTranslation = message.highlighted_text ? replaceNewlinesWithBreaks(message.highlighted_text) : replaceNewlinesWithBreaks(message.text);

                  return (
                    <tr key={message.id}>
                      <td>
                        <div className={messageStyles.messageInfoWrapper}>
                          <div className={messageStyles.messageInfo}>
                            {/* <div className={`${messageStyles.status} ${statusMapping[message.status].class}`}>
                                  {t(statusMapping[message.status].name)}
                                </div> */}
                            {message.content_type ? <div className={messageStyles.contentType}>
                              <Tooltip content={t(contentTypesMapping[message.content_type])} position='bottom'>
                                {contentTypesIconsMapping[message.content_type]}
                              </Tooltip>
                            </div> : ''}
                            <div className={messageStyles.date}>
                                  <span className={messageStyles.anchor}>
                                    <Tooltip content={t('Publication date')}
                                             position='bottom'><CalendarIcon/></Tooltip>
                                  </span>
                              {format(parseISO(message.date_publicated + 'Z'), 'dd LLL yyyy HH:mm',)}
                            </div>
                            <div className={messageStyles.platform}>
                                  <span className={messageStyles.anchor}>
                                    <Tooltip content={platformNamesMapping[message.source.source_type]}
                                             position='bottom'>
                                      {platformIconsMapping[message.source.source_type]}
                                    </Tooltip>
                                  </span>
                              {followers_cnt ? <span className={messageStyles.anchor}>
                                      <Tooltip content={t('Followers')} position='bottom'>
                                        <FollowersIcon/>
                                      </Tooltip>
                                      <span>{formatNumberSignificant(followers_cnt)}</span>
                                    </span> : ''}
                            </div>
                            <div className={messageStyles.sourceActorWrapper}>
                                  <span className={messageStyles.anchor}>
                                    <Tooltip content={decodeSourceName(message.source.name)} position='bottom'>
                                      <ActorIcon/>
                                      <span className={messageStyles.cutText}>
                                        {message.source ? (
                                          <Link
                                            to={`/sources/${message.source?.id}`}
                                          >
                                            {decodeSourceName(message.source.name)}
                                          </Link>
                                        ) : t('N/A')}
                                      </span>
                                    </Tooltip>
                                    {message.actor && message.actor.id !== message.source?.id ?
                                      <Tooltip content={decodeSourceName(message.actor?.name)} position='bottom'>
                                        <SourceIcon/>
                                        <span className={messageStyles.cutText}>
                                        {<Link
                                          to={`/sources/${message.actor?.id}`}>{decodeSourceName(message.actor?.name)}</Link>}
                                        </span>
                                      </Tooltip> : ''}
                                    <a
                                      href={message.url}
                                      className={messageStyles.externalLink}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <LinkIcon/>
                                    </a>
                                  </span>
                            </div>
                          </div>
                          <div className={messageStyles.modalIcon}>
                            <ExpandIcon  onClick={() => setMessageModal({message: message, isOpen: true, narrartiveMessage: null})}/> 
                          </div>
                        </div>

                        <div className={messageStyles.messageContainer}>
                          <div className={messageStyles.messageMetricsWrapper}>
                            <div className={messageStyles.messageMetrics}>
                              {message.sentiment_score !== null ?
                                <Tooltip content={t(getSentimentName(message.sentiment_score))} position='bottom'>
                                  {getSentimentIcon(message.sentiment_score)}
                                </Tooltip> : ''}
                              <span className={messageStyles.anchor}>
                                    <Tooltip content={t('Views')} position='bottom'>
                                      <ViewsIcon/>
                                    </Tooltip>
                                {formatNumberSignificant(message.impressions)}
                                  </span>
                              <span className={messageStyles.anchor}>
                                    <Tooltip content={t('Reactions')} position='bottom'>
                                      <EngagementIcon1/>
                                    </Tooltip>
                                {formatNumberSignificant(message.engagement)}
                                  </span>
                              <span className={messageStyles.anchor}>
                                    <Tooltip content={t('Manipulation')} position='bottom'>
                                      <ManipulationIcon/>
                                    </Tooltip>

                                {message.manipulation_index ? message.manipulation_index.toFixed(2) : '0'}
                                    </span>
                              {message.similarity_score ?
                                <span className={messageStyles.anchor}>
                                    <Tooltip content={t('Similarity1')} position='bottom'>
                                      <SimilarityIcon/>
                                    </Tooltip>
                                  {(message.similarity_score + "").slice(0, 4)}
                                    </span>
                                : ''}
                            </div>
                            <div className={styles.searchIconWrapper}>
                              <Tooltip content={t('Find similar messages')} position='bottom'>
                                    <span className={messageStyles.cutText}>
                                      <Link to={`/search?similar_to=${encodeURIComponent(message.composite_id)}`}
                                            onClick={() => window.location.href = `/search?similar_to=${encodeURIComponent(message.composite_id)}`}><SearchIcon/></Link>
                                    </span>
                              </Tooltip>
                            </div>
                          </div>

                          {message.content_type === 'COMMENT' &&
                          message.parent_message ? (
                            <div className={styles.originalTextBlock}>
                              {message.isTranslation === true &&
                              !message.parent_message?.translated ? (
                                <div
                                  className={styles.loaderContainer}
                                >
                                  <LoaderSmall />
                                </div>
                              ) : (
                                <>
                                  <Tooltip
                                    content={decodeSourceName(
                                      message.source?.name,
                                    )}
                                    position="bottom"
                                  >
                                    <span
                                      className={messageStyles.sourceLink}
                                    >
                                      {message.source ? (
                                        <Link
                                          to={`/sources/${message.source?.id}`}
                                          onClick={() => {
                                            ampli.track({
                                              event_type: 'Open source profile',
                                              event_properties: {
                                                user_id: currentUser?.id,
                                                workspace_id:
                                                  currentUser?.workspace_id,
                                                source_id: message.source?.id,
                                              },
                                            });
                                          }}
                                        >
                                          {decodeSourceName(
                                            message.source.name,
                                          )}
                                        </Link>
                                      ) : (
                                        t('N/A')
                                      )}
                                    </span>
                                  </Tooltip>

                                  {message?.parent_message?.images && message?.parent_message?.images?.length > 0 ? <ImagesContainer images={message?.parent_message?.images} showCount={3} /> : ''}

                                  {message.parent_message?.text ? (
                                    <ExpandableText
                                      length={100}
                                      text={
                                        message.isTranslation
                                          ? message.parent_message?.translated
                                          : message.parent_message?.text
                                      }
                                      textClassName={textClassParent}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            ''
                          )}

                          {message?.images && message?.images?.length > 0 ? <ImagesContainer images={message?.images} showCount={3} className={styles.imagesContainer} /> : ''}
                          
                          <div className={`${messageStyles.textBlock} ${styles.textBlock}`}>
                            {message.isTranslation === true && !message.translated ?
                              <div className={messageStyles.loaderContainer}><LoaderSmall/></div> :
                              <ExpandableText
                                length={100}
                                highlights={highlights}
                                text={message.isTranslation ? message.translated : message.highlighted_text || message.text}
                                textClassName={textClass}
                              />}
                          </div>
                        </div>
                      </td>
                      <td className='message-controlls'>
                        <div className={messageStyles.translationWrapper}>
                              <span
                                className={message?.isTranslation ? '' : messageStyles.active}
                                onClick={() => handleTranslation(message.id, textForTranslation, false)}
                              >
                                <OriginalTextIcon/>
                              </span>
                          <span
                            className={message?.isTranslation ? messageStyles.active : ''}
                            onClick={() => handleTranslation(message.id, textForTranslation, true)}
                          >
                                <TranslationIcon/>
                              </span>

                        </div>

                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            {/* </div> */}
          </div>
          <div className={styles.findMoreContainer}>
            <Link
              to={messagesLink}
              className={`button new-button`}
            >
              {t('See all messages')}
            </Link>
          </div>
        </div> : <div className={styles.header}>
          <div className={styles.noData}>{t('There is no data to display')}</div>
        </div>}
      </div>

      {messagesModal.isOpen ? <MessagesModal
        infoModal={messagesModal.isOpen}
        sourceId={sourceId}
        narrativeId={messagesModal.narrativeId}
        onChange={(bool) => setMessagesModal({isOpen: bool, narrativeId: null})}
      /> : ''}

      {messageModal.isOpen ? <MessageModal 
        message={messageModal.message}
        isOpen={messageModal.isOpen}
        onChange={(bool, selected) => {
          setMessageModal({message: null, isOpen: bool, narrartiveMessage: null})
        }}
        selected={null}
        narrativeMessage={messageModal.narrartiveMessage}
      /> : ''}
    </div>
  );
};
